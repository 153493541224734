var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import IconArrowDownRounded from "@assets/icon_arrow_down_rounded.svg";
import IconPlus from "@assets/icon_plus.svg";
import { addRevision, deleteRevision, fetchCV, getCV, markDefault, removeRevision, renamePreviewRevision, renameRevision, saveCV, setCV, setSelectedRevision, showNotification, } from "@store";
import { useAppDispatch, useAppSelector } from "@hooks";
import { Button, ConfirmModal, DropDownWrap } from "@components";
import { dictionary } from "@constants";
import { CreateModal, RevisionItem } from "./components";
import { fields } from "./localization";
import { DeleteModal } from "./components/DeleteModal";
import styles from "./RevisionSelector.module.scss";
const allowedSymbolsRegExp = /^[a-zA-Z0-9-_ \][(){}.,:;~!@$%^&*><]+$/m;
export var Modals;
(function (Modals) {
    Modals[Modals["revisionCreate"] = 0] = "revisionCreate";
    Modals[Modals["revisionDelete"] = 1] = "revisionDelete";
    Modals[Modals["saveUnsavedBeforeSwitch"] = 2] = "saveUnsavedBeforeSwitch";
    Modals[Modals["saveUnsavedBeforeCreation"] = 3] = "saveUnsavedBeforeCreation";
})(Modals || (Modals = {}));
export function RevisionSelector({ setIsCVScrollable, setSelectedRevisionUrlParam, onDiscardChanges, onSaveChanges, }) {
    const [isSelectorOpen, setIsSelectorOpen] = useState(false);
    // TODO (1/11/2024): reuse "targetRevision"
    const [renamingRevision, setRenamingRevision] = useState(null);
    const [targetRevision, setTargetRevision] = useState(null);
    const [switchInProgressRevisionName, setSwitchInProgressRevisionName] = useState(null);
    const [activeModalType, setActiveModalType] = useState(null);
    const dispatch = useAppDispatch();
    const { revisions, cvPreview, selectedRevision } = useAppSelector((state) => state.revisions);
    const { language, isViewOnly } = useAppSelector((state) => state.ui);
    const { targetUser } = useAppSelector((state) => state.users);
    const { isCVChanged } = useAppSelector((state) => state.cv);
    const location = useLocation();
    const field = fields(language);
    const { notAllowedSymbols, save, discardChanges, saveChanges, saveChangesSubtitle } = dictionary(language);
    useEffect(() => {
        if (revisions.length) {
            const urlParams = new URLSearchParams(location.search);
            const revisionFromUrl = urlParams.get("revision");
            const revision = revisions.find((item) => item.name === revisionFromUrl);
            if (revision) {
                dispatch(setSelectedRevision(revision));
            }
        }
    }, [revisions]);
    useEffect(() => {
        if (!isSelectorOpen) {
            setRenamingRevision(null);
        }
        setIsCVScrollable(!isSelectorOpen);
    }, [isSelectorOpen]);
    useEffect(() => {
        if (activeModalType) {
            setIsSelectorOpen(false);
        }
    }, [activeModalType]);
    const handleMarkAsDefault = (revision) => __awaiter(this, void 0, void 0, function* () {
        if (switchInProgressRevisionName) {
            return;
        }
        const currentDefaultRevision = revisions.find(({ isDefault }) => isDefault);
        if (!(currentDefaultRevision && currentDefaultRevision.name === revision.name)) {
            setSwitchInProgressRevisionName(revision.name);
            yield dispatch(markDefault({
                prevName: currentDefaultRevision.name,
                targetName: revision.name,
                userId: targetUser.email,
            }));
            setSwitchInProgressRevisionName(null);
        }
    });
    const getRevisionNameValidationError = (name, revisionToExclude) => {
        const trimmedName = name.trim();
        const symbolsErrorMessage = allowedSymbolsRegExp.test(trimmedName) ? null : notAllowedSymbols;
        const lengthErrorMessage = trimmedName.length ? null : field.minLength;
        const alreadyExistentRevision = revisionToExclude
            ? revisions.filter((rev) => rev.name !== revisionToExclude.name)
            : revisions;
        const alreadyExistErrorMessage = alreadyExistentRevision.some((revision) => revision.name === trimmedName)
            ? field.alreadyExist
            : null;
        return alreadyExistErrorMessage || lengthErrorMessage || symbolsErrorMessage;
    };
    const handleRevisionRename = (oldName, newName) => __awaiter(this, void 0, void 0, function* () {
        const newNameTrim = newName.trim();
        if (oldName === newNameTrim) {
            return;
        }
        const oldRevision = revisions.find((revision) => revision.name === oldName);
        if (oldRevision.isPreview) {
            yield dispatch(renamePreviewRevision({ oldName, newName: newNameTrim }));
        }
        else {
            yield dispatch(renameRevision({ email: targetUser.email, oldName, newName: newNameTrim }));
        }
        if (oldName === selectedRevision.name) {
            setSelectedRevisionUrlParam(newNameTrim);
            dispatch(setSelectedRevision({ name: newNameTrim, isPreview: selectedRevision.isPreview }));
        }
    });
    const handleRevisionSelect = (revision) => __awaiter(this, void 0, void 0, function* () {
        if (revision.name !== selectedRevision.name) {
            dispatch(setSelectedRevision(revision));
            setSelectedRevisionUrlParam(revision.name);
            if (selectedRevision.isPreview) {
                dispatch(removeRevision(selectedRevision));
            }
            if (revision.isPreview) {
                dispatch(setCV(cvPreview));
            }
            else {
                const cvDataResponse = yield dispatch(getCV({ email: targetUser.email, revisionName: revision.name })).unwrap();
                dispatch(setCV(cvDataResponse));
            }
        }
    });
    const captureHandleRevisionSelect = (revision) => __awaiter(this, void 0, void 0, function* () {
        if (isCVChanged && revision.name !== selectedRevision.name) {
            setTargetRevision(revision);
            setActiveModalType(Modals.saveUnsavedBeforeSwitch);
        }
        else {
            yield handleRevisionSelect(revision);
        }
    });
    const handleRevisionDelete = (revision, defaultRevision) => __awaiter(this, void 0, void 0, function* () {
        if (revision.isDefault) {
            yield handleMarkAsDefault(defaultRevision);
        }
        if (revision.isPreview) {
            yield dispatch(removeRevision(revision));
        }
        else {
            yield dispatch(deleteRevision({ email: targetUser.email, revisionName: revision.name }));
        }
        dispatch(showNotification({
            message: field.deleted.replace("$REVISION", revision.name),
            isSuccess: true,
        }));
        setActiveModalType(null);
        setTargetRevision(null);
        if (revision.name === selectedRevision.name) {
            yield handleRevisionSelect(defaultRevision || revisions.find(({ isDefault }) => isDefault));
        }
    });
    const handleRevisionCreate = (newName, sourceRevision) => __awaiter(this, void 0, void 0, function* () {
        const { email, imageUrl } = targetUser;
        const revisionName = newName.trim();
        const sourceCVData = yield fetchCV(email, sourceRevision.name, false);
        yield dispatch(saveCV({
            email,
            revisionName,
            data: Object.assign(Object.assign({}, sourceCVData), { imageUrl }),
            isCurrent: true,
        }));
        dispatch(showNotification({
            message: field.created.replace("$REVISION", revisionName),
            isSuccess: true,
        }));
        setSelectedRevisionUrlParam(revisionName);
        dispatch(addRevision({ name: revisionName }));
        dispatch(setSelectedRevision({ name: revisionName, isPreview: false }));
        setActiveModalType(null);
    });
    const captureHandleDelete = (revision) => {
        setTargetRevision(revision);
        setActiveModalType(Modals.revisionDelete);
    };
    const handleRevisionCreateCancel = () => {
        setActiveModalType(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DropDownWrap, { isOpen: isSelectorOpen, toggleOpen: () => setIsSelectorOpen(!isSelectorOpen), primaryDirection: "right", anchor: React.createElement("p", { className: styles.cvFileName },
                React.createElement("span", { className: styles.revisionLabel },
                    field.revision,
                    ":"),
                React.createElement("span", { className: styles.cvFileNameText }, selectedRevision.name),
                selectedRevision.isPreview && React.createElement("span", { className: styles.revisionNameIcon }, "*"),
                React.createElement(IconArrowDownRounded, { className: clsx(isSelectorOpen && styles.cvSelectArrowDownOpen) })) }, isSelectorOpen && (React.createElement("div", { className: styles.container },
            React.createElement("ul", { className: styles.revisionsList }, revisions.map((revision) => (React.createElement(RevisionItem, { key: revision.name, isEditMode: renamingRevision === revision.name && !isViewOnly, revision: revision, isSelected: revision.name === selectedRevision.name, isRemoveDisabled: revisions.length === 1, isEditForbidden: isViewOnly, handleRevisionSelect: () => captureHandleRevisionSelect(revision), handleRevisionDelete: () => captureHandleDelete(revision), setRenamingRevision: () => setRenamingRevision(revision.name), handleClose: () => setRenamingRevision(null), handleRename: handleRevisionRename, handleMarkAsDefault: handleMarkAsDefault, switchInProgressRevisionName: switchInProgressRevisionName, getRevisionNameValidationError: getRevisionNameValidationError })))),
            !isViewOnly && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.selectRevisionLine }),
                React.createElement(Button, { color: "tertiary", variant: "outlined", onClick: () => {
                        setRenamingRevision(null);
                        setActiveModalType(isCVChanged ? Modals.saveUnsavedBeforeCreation : Modals.revisionCreate);
                    }, leftIcon: React.createElement(IconPlus, null), className: styles.button }, field.newRevision)))))),
        activeModalType === Modals.revisionCreate && (React.createElement(CreateModal, { selectedRevision: selectedRevision, onClose: handleRevisionCreateCancel, handleRevisionCreate: handleRevisionCreate, getRevisionNameValidationError: getRevisionNameValidationError })),
        activeModalType === Modals.revisionDelete && (React.createElement(DeleteModal, { onClose: () => setActiveModalType(null), handleRevisionDelete: handleRevisionDelete, revision: targetRevision })),
        activeModalType === Modals.saveUnsavedBeforeCreation && (React.createElement(ConfirmModal, { isOpen: true, title: saveChanges, message: saveChangesSubtitle.replace("$REVISION", selectedRevision.name), saveBtnText: save, cancelBtnText: discardChanges, onSave: () => __awaiter(this, void 0, void 0, function* () {
                setActiveModalType(null);
                yield onSaveChanges();
                setActiveModalType(Modals.revisionCreate);
            }), onClose: () => {
                setActiveModalType(null);
            }, onCancel: () => {
                setActiveModalType(null);
                onDiscardChanges();
                setActiveModalType(Modals.revisionCreate);
            } })),
        activeModalType === Modals.saveUnsavedBeforeSwitch && (React.createElement(ConfirmModal, { isOpen: true, title: saveChanges, message: saveChangesSubtitle.replace("$REVISION", selectedRevision.name), saveBtnText: save, cancelBtnText: discardChanges, onSave: () => __awaiter(this, void 0, void 0, function* () {
                setActiveModalType(null);
                yield onSaveChanges();
                yield handleRevisionSelect(targetRevision);
                setTargetRevision(null);
            }), onClose: () => __awaiter(this, void 0, void 0, function* () {
                setActiveModalType(null);
                setTargetRevision(null);
            }), onCancel: () => __awaiter(this, void 0, void 0, function* () {
                setActiveModalType(null);
                yield handleRevisionSelect(targetRevision);
                setTargetRevision(null);
            }) }))));
}
