export var SortOrder;
(function (SortOrder) {
    SortOrder["Custom"] = "custom";
    SortOrder["StartDescending"] = "startDescending";
    SortOrder["StartAscending"] = "startAscending";
    SortOrder["EndDescending"] = "endDescending";
    SortOrder["EndAscending"] = "endAscending";
})(SortOrder || (SortOrder = {}));
export var Language;
(function (Language) {
    Language["EN"] = "EN";
    Language["RU"] = "RU";
    Language["DE"] = "DE";
    Language["PL"] = "PL";
})(Language || (Language = {}));
export function isUILanguage(x) {
    return x === Language.EN || x === Language.RU;
}
export const FileTypeMap = {
    ["pdf" /* CVFileTypes.pdf */]: {
        loading: "isFileLinkPDFLoading",
        link: "",
        error: "",
        expiration: "fileLinkPDFExpirationTime",
    },
    ["docx" /* CVFileTypes.docx */]: {
        loading: "isFileLinkDOCXLoading",
        link: "",
        error: "",
        expiration: "fileLinkDOCXExpirationTime",
    },
    ["drive" /* CVFileTypes.drive */]: {
        loading: "isFileLinkDriveLoading",
        link: "",
        error: "",
        expiration: "fileLinkDriveExpirationTime",
    },
};
