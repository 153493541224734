import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useOverlay } from "@hooks";
import { Portal } from "@components";
import styles from "./Tooltip.module.scss";
const isMouseOverElement = (element, mouseX, mouseY) => {
    const rect = element.getBoundingClientRect();
    return mouseX >= rect.left && mouseX <= rect.right && mouseY >= rect.top && mouseY <= rect.bottom;
};
export function Tooltip({ anchorRef, children, primaryPositionX = "center", primaryPositionY = "bottom", }) {
    const contentRef = useRef(null);
    const [isPointerOverTarget, setIsPointerOverTarget] = useState(true);
    const timerRef = useRef(null);
    const [isPositionCalculated, position] = useOverlay(true, contentRef, anchorRef, primaryPositionX, primaryPositionY, { minViewPortOffset: 4, offsetY: 6 });
    const handleWheel = (event) => {
        timerRef.current = setTimeout(() => {
            setIsPointerOverTarget(isMouseOverElement(anchorRef.current, event.clientX, event.clientY));
        }, 100);
    };
    // NOTE: workaround to handle wheel event while tooltip is visible
    // mouseleave event is not dispatched (https://groups.google.com/a/chromium.org/g/blink-dev/c/KIoVljZw5fc/m/vPhe21btQmgJ?pli=1)
    // and in order to track "leave" event after scroll we could manually check if element is hovered after some delay
    useEffect(() => {
        document.addEventListener("wheel", handleWheel);
        return () => {
            document.removeEventListener("wheel", handleWheel);
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);
    return (isPointerOverTarget && (React.createElement(Portal, null,
        React.createElement("span", { className: clsx(styles.content, isPositionCalculated && styles.open), style: {
                top: position.top,
                left: position.left,
            }, ref: contentRef }, children))));
}
