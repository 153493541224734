var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { usePrevious } from "@hooks";
import styles from "./CommonModal.module.scss";
const modalRoot = document.getElementById("modal-root");
const app = document.getElementById("app");
export function CommonModal(_a) {
    var { children, open, onRequestClose, wrapperClassName, className, forwardedRef, size = "small" } = _a, props = __rest(_a, ["children", "open", "onRequestClose", "wrapperClassName", "className", "forwardedRef", "size"]);
    const prevWrapperClassName = usePrevious(wrapperClassName);
    const element = useRef(document.createElement("div")).current;
    const handleResize = () => {
        if (element.classList.contains(styles.modalContainer)) {
            element.style.height = `${window.innerHeight}px`;
        }
    };
    const handleKeyDown = ({ key }) => {
        if (key === "Escape") {
            onRequestClose();
        }
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    useEffect(() => {
        modalRoot.appendChild(element);
        return () => {
            modalRoot.removeChild(element);
        };
    }, [modalRoot]);
    useEffect(() => {
        if (!element.classList.contains(styles.modalContainer)) {
            element.classList.add(styles.modalContainer);
            element.style.height = `${window.innerHeight}px`;
        }
        const toRemove = prevWrapperClassName === null || prevWrapperClassName === void 0 ? void 0 : prevWrapperClassName.split(" ");
        if (toRemove) {
            element.classList.remove(...toRemove);
        }
        const toAdd = wrapperClassName === null || wrapperClassName === void 0 ? void 0 : wrapperClassName.split(" ");
        if (toAdd) {
            element.classList.add(...toAdd);
        }
    }, [element, wrapperClassName]);
    useEffect(() => {
        if (open) {
            element.classList.add(styles.modalContainerOpen);
            app.classList.add(styles.blurredBackground);
        }
        else {
            element.classList.remove(styles.modalContainerOpen);
            app.classList.remove(styles.blurredBackground);
        }
    }, [open]);
    useEffect(() => {
        return () => {
            element.classList.remove(styles.modalContainerOpen);
            app.classList.remove(styles.blurredBackground);
        };
    }, []);
    useEffect(() => {
        if (onRequestClose) {
            const click = (e) => {
                if (e.target === element) {
                    onRequestClose(e);
                }
            };
            element.addEventListener("mousedown", click);
            return () => {
                element.removeEventListener("mousedown", click);
            };
        }
    }, [element, onRequestClose]);
    const child = useMemo(() => (React.createElement("div", Object.assign({}, props, { className: clsx(styles.modal, styles[size]), ref: forwardedRef }), children)), [children, className, props]);
    return open && createPortal(child, element);
}
