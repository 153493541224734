import { format } from "date-fns";
import { SortOrder } from "@types";
const THREE_LETTER_MONTH_FORMAT = "MMM";
const convertToDate = (dateString) => {
    const isPresentDate = dateString === null;
    const presentDate = isPresentDate ? new Date() : null;
    return dateString ? new Date(dateString) : presentDate;
};
export const compareDates = (isSortAscending, prevDate, nextDate) => {
    const sortOrder = isSortAscending ? 1 : -1;
    if (prevDate && nextDate) {
        return sortOrder * (prevDate.getTime() - nextDate.getTime());
    }
    if (prevDate && !nextDate) {
        return sortOrder * -1;
    }
    if (nextDate && !prevDate) {
        return sortOrder;
    }
    return 0;
};
const getMappedDate = ({ startDate, endDate }, compareByStartDate) => {
    const dateStr = compareByStartDate ? startDate : endDate;
    return convertToDate(dateStr);
};
const getSortConfig = (option) => {
    const isSortAscending = option === SortOrder.StartAscending || option === SortOrder.EndAscending;
    const compareByStartDate = option === SortOrder.StartAscending || option === SortOrder.StartDescending;
    return { isSortAscending, compareByStartDate };
};
export const getDateComparator = (option) => (prev, next) => {
    const { compareByStartDate, isSortAscending } = getSortConfig(option);
    const prevDateMapped = getMappedDate(prev, compareByStartDate);
    const nextDateMapped = getMappedDate(next, compareByStartDate);
    return compareDates(isSortAscending, prevDateMapped, nextDateMapped);
};
/**
 * Formats three letter month to CameCase
 * @param formattedDate
 */
export const monthFormat = (formattedDate) => {
    const parts = formattedDate.split(" ");
    if (parts.length === 2) {
        parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].substring(1, 3).toLowerCase();
        return parts.join(" ");
    }
    return formattedDate;
};
export const formatStartDate = (date, placeholder, dateFormatOptions, locale) => {
    const startDate = new Date(date);
    if (!date) {
        return placeholder;
    }
    let formattedDate = format(startDate, dateFormatOptions, { locale });
    if (dateFormatOptions.includes(THREE_LETTER_MONTH_FORMAT)) {
        formattedDate = monthFormat(formattedDate);
    }
    return formattedDate;
};
export const formatEndDate = (date, placeholder, isPresent, dateFormatOptions, locale) => {
    const endDate = isPresent ? new Date() : new Date(date);
    if (date === undefined) {
        return placeholder;
    }
    if (date === null && !isPresent) {
        return placeholder;
    }
    let formattedDate = format(endDate, dateFormatOptions, { locale });
    if (dateFormatOptions.includes(THREE_LETTER_MONTH_FORMAT)) {
        formattedDate = monthFormat(formattedDate);
    }
    return formattedDate;
};
