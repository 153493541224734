import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { CustomSectionContentType } from "@types";
import { useAppSelector, useMobile, useTabletDuo } from "@hooks";
import { CloseButton, Button } from "@components";
import { buildMenu } from "@utils";
import AddIcon from "@assets/icon-add.svg";
import { getHeadingsDictionary, HeadingIds, sectionHeadings, dictionary } from "@constants";
import { handleScroll } from "./handleScroll";
import styles from "./Navigation.module.scss";
const DELAY_AFTER_CLICK = 1000;
export function Navigation({ wrapperRef, hasCloseBtn = false, navigationRef, onSectionAdd, hideNavigation, }) {
    const [active, setActive] = useState(HeadingIds.GeneralInfo);
    const [isFirstNavigation, setIsFirstNavigation] = useState(true);
    const [isScrollHandlerActive, setIsScrollHandlerActive] = useState(true);
    const { featureFlags, language } = useAppSelector((state) => state.ui);
    const { cv } = useAppSelector((state) => state.cv);
    const profExp = (cv === null || cv === void 0 ? void 0 : cv.professionalExperienceDisplay) || [];
    const customSections = (cv === null || cv === void 0 ? void 0 : cv.customSectionsDisplay) || [];
    const isMobile = useMobile();
    const isTabletDuo = useTabletDuo();
    const location = useLocation();
    const headingsDictionary = getHeadingsDictionary(language);
    const { addBulletSection, addTextSection } = dictionary(language);
    const menu = useMemo(() => buildMenu(sectionHeadings, customSections, !!(featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.hideCustomSection), headingsDictionary, profExp, language, isMobile || isTabletDuo), [customSections, profExp, language]);
    const scrollHandler = useCallback(() => {
        const container = wrapperRef === null || wrapperRef === void 0 ? void 0 : wrapperRef.current;
        if (container) {
            const activeHeadingId = handleScroll(container, active, menu);
            if (isScrollHandlerActive) {
                setActive(activeHeadingId);
            }
        }
    }, [wrapperRef, active, profExp, customSections, isScrollHandlerActive]);
    useEffect(() => {
        const container = wrapperRef === null || wrapperRef === void 0 ? void 0 : wrapperRef.current;
        if (container) {
            container.addEventListener("scroll", scrollHandler);
            return () => {
                container.removeEventListener("scroll", scrollHandler);
            };
        }
    }, [wrapperRef, scrollHandler]);
    useEffect(() => {
        scrollHandler();
    }, []);
    useEffect(() => {
        if (location.hash && !isFirstNavigation) {
            setActive(location.hash.slice(1));
            setIsFirstNavigation(false);
        }
    }, [location.hash]);
    const handleHeadingClick = (headingId) => {
        const heading = document.getElementById(headingId);
        setIsScrollHandlerActive(false);
        if (heading) {
            heading.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        setActive(headingId);
        if (hasCloseBtn) {
            hideNavigation();
        }
        else {
            setTimeout(() => {
                setIsScrollHandlerActive(true);
            }, DELAY_AFTER_CLICK);
        }
    };
    return (React.createElement("nav", { ref: navigationRef, className: styles.list },
        hasCloseBtn && React.createElement(CloseButton, { className: styles.closeBtn, onRequestClose: hideNavigation }),
        React.createElement("div", { className: styles.linksContainer }, menu.map(({ title, id, icon, isSubmenu }) => {
            const Icon = icon || null;
            return (React.createElement("div", { role: "button", tabIndex: 0, onClick: () => handleHeadingClick(id), onKeyDown: ({ key }) => key === "Enter" && handleHeadingClick(id), className: clsx(styles.item, isSubmenu && styles.subListItem, id === active && styles.active), key: id },
                icon && React.createElement(Icon, { className: styles.icon }),
                title));
        })),
        React.createElement("div", { className: styles.buttonsContainer },
            React.createElement(Button, { type: "button", color: "tertiary", className: clsx(styles.item, styles.button), onClick: () => onSectionAdd(CustomSectionContentType.BulletPoints), leftIcon: React.createElement(AddIcon, { className: styles.icon }) }, addBulletSection),
            React.createElement(Button, { color: "tertiary", type: "button", className: clsx(styles.item, styles.button), onClick: () => onSectionAdd(CustomSectionContentType.Text), leftIcon: React.createElement(AddIcon, { className: styles.icon }) }, addTextSection))));
}
