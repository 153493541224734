var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useAppDispatch, useAppSelector } from "@hooks";
import { setCV, translateSubstring } from "@store";
import { CustomSectionContentType } from "@types";
import { Button } from "@components";
import { createDisplayPoint } from "@utils";
import IconPlus from "@assets/icon_plus.svg";
import { dictionary } from "@constants";
import { CustomSection } from "./components";
import styles from "./CustomSections.module.scss";
export function CustomSections({ onSectionAdd }) {
    const { cv } = useAppSelector((state) => state.cv);
    const { featureFlags, language } = useAppSelector((state) => state.ui);
    const dispatch = useAppDispatch();
    const { addBulletSection, addTextSection } = dictionary(language);
    const customSections = (cv === null || cv === void 0 ? void 0 : cv.customSectionsDisplay) || [];
    const setValue = (customSectionsDisplay) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { customSectionsDisplay })));
    };
    const handleEditTitle = (index, value) => {
        setValue(customSections.map((s, i) => (i === index ? Object.assign(Object.assign({}, s), { header: value }) : s)));
    };
    const handleEditContent = (index, value, key) => {
        if (key === CustomSectionContentType.Text && typeof value === "string") {
            setValue(customSections.map((s, i) => (i === index ? Object.assign(Object.assign({}, s), { content: { [key]: value } }) : s)));
        }
        else {
            const safeValue = value.length ? value : [createDisplayPoint("")];
            setValue(customSections.map((s, i) => (i === index ? Object.assign(Object.assign({}, s), { content: { [key]: safeValue } }) : s)));
        }
    };
    const handleBulletAdd = (sectionIndex, bullets) => {
        handleEditContent(sectionIndex, [...bullets, createDisplayPoint()], CustomSectionContentType.BulletPoints);
    };
    const onValueRemove = (index) => {
        const newValue = [...customSections];
        newValue.splice(index, 1);
        setValue(newValue);
    };
    const handleSectionHide = (index) => {
        const customSectionsDisplay = customSections.map((section, i) => {
            if (i === index) {
                return Object.assign(Object.assign({}, section), { isHidden: !section.isHidden });
            }
            return section;
        });
        dispatch(setCV(Object.assign(Object.assign({}, cv), { customSectionsDisplay })));
    };
    const updateText = (value, start, end, pos) => __awaiter(this, void 0, void 0, function* () {
        const translatedResult = yield dispatch(translateSubstring({ text: value, start, end })).unwrap();
        handleEditContent(pos, translatedResult, CustomSectionContentType.Text);
    });
    const updatePoint = (sectionIndex, itemIndex, value, start, end) => __awaiter(this, void 0, void 0, function* () {
        const translatedResult = yield dispatch(translateSubstring({ text: value, start, end })).unwrap();
        const updatedPoints = customSections[sectionIndex].content.bulletPoints.map((point, index) => {
            if (index === itemIndex) {
                return Object.assign(Object.assign({}, point), { value: translatedResult });
            }
            return point;
        });
        handleEditContent(sectionIndex, updatedPoints, CustomSectionContentType.BulletPoints);
    });
    return (!(featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.hideCustomSection) && (React.createElement(React.Fragment, null,
        customSections.map((section, index) => (React.createElement(CustomSection, { section: section, index: index, key: section.displayId, handleEditTitle: handleEditTitle, onValueRemove: onValueRemove, handleBulletAdd: handleBulletAdd, handleSectionHide: handleSectionHide, handleEditContent: handleEditContent, updateText: updateText, updatePoint: updatePoint }))),
        React.createElement("section", { className: styles.btnContainer },
            React.createElement(Button, { onClick: () => onSectionAdd(CustomSectionContentType.BulletPoints), color: "secondary", leftIcon: React.createElement(IconPlus, null) }, addBulletSection),
            React.createElement(Button, { onClick: () => onSectionAdd(CustomSectionContentType.Text), color: "secondary", leftIcon: React.createElement(IconPlus, null) }, addTextSection)))));
}
