export const scrollHiddenIntoView = (elem, scrollOptions = {
    behavior: "smooth",
    block: "center",
    inline: "nearest",
}) => {
    const { top, bottom } = elem.getBoundingClientRect();
    const isElementHidden = bottom >= window.innerHeight || top < 0;
    if (isElementHidden) {
        elem.scrollIntoView(scrollOptions);
    }
};
export const scrollElemToBottom = (elem, behavior = "smooth") => {
    elem.scrollTo({
        top: elem.scrollHeight,
        left: 0,
        behavior,
    });
};
export const copyElementStyle = (sourceElement, targetElement) => {
    const computedStyle = window.getComputedStyle(sourceElement);
    Array.from(computedStyle).forEach((key) => targetElement.style.setProperty(key, computedStyle.getPropertyValue(key), computedStyle.getPropertyPriority(key)));
};
export const openLinkInNewTab = (href) => {
    Object.assign(document.createElement("a"), {
        target: "_blank",
        rel: "noopener noreferrer",
        href,
    }).click();
};
export const downloadFileByBlob = (fileBlob, fileName) => {
    const objectUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(objectUrl);
};
