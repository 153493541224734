var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Api, FEATURE_FLAGS } from "@services";
export const getFeatureFlags = createAsyncThunk("ui/getFeatureFlags", (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data: response } = yield Api.get(FEATURE_FLAGS);
        return response;
    }
    catch (_a) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const setLanguage = createAction("ui/setLanguage");
export const setIsViewOnly = createAction("ui/setIsViewOnly");
export const showNotification = createAction("ui/showNotification");
export const hideNotification = createAction("ui/hideNotification");
export const setIsDatesWarningHidden = createAction("ui/setIsDatesWarningVisible");
