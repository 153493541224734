import React, { useEffect, useRef, useState } from "react";
import IconPlus from "@assets/icon_plus.svg";
import { Button, IconButton, TextField } from "@components";
import IconEye from "@assets/icon_eye.svg";
import IconEyeCrossed from "@assets/icon_eye_crossed.svg";
import IconPen from "@assets/pen.svg";
import IconTrash from "@assets/icon_trash.svg";
import IconCheck from "@assets/icon_checked.svg";
import { useAppSelector } from "@hooks";
import { dictionary } from "@constants";
import { fields } from "./localization";
import styles from "./SectionTitle.module.scss";
export function SectionTitle({ title, handleAdd, isSectionHidden, handleSectionHide, additionalComponent, handleRename, disabled, addButtonVariant = "text", addButtonLabel, handleSectionRemove, }) {
    const { language, isViewOnly } = useAppSelector((state) => state.ui);
    const { isTriedToSave } = useAppSelector((state) => state.cv);
    const inputRef = useRef(null);
    const [isTitleEditing, setIsTitleEditing] = useState(false);
    useEffect(() => {
        if (isTriedToSave && (title === null || title === void 0 ? void 0 : title.trim())) {
            setIsTitleEditing(false);
        }
    }, [isTriedToSave]);
    useEffect(() => {
        if (isTitleEditing) {
            inputRef.current.focus();
        }
    }, [isTitleEditing]);
    const { done, edit, add, tooltip } = fields(language);
    const { remove } = dictionary(language);
    return (React.createElement("header", { className: styles.sectionWrap },
        React.createElement("div", { className: styles.titleWithButton },
            isTitleEditing ? (React.createElement(TextField, { innerRef: inputRef, className: styles.titleInput, value: title, onChange: ({ target: { value } }) => handleRename(value), maxLength: 50, required: true, hasError: isTriedToSave && !(title === null || title === void 0 ? void 0 : title.trim()) })) : (React.createElement("p", { className: styles.sectionTitle }, title)),
            handleRename && (React.createElement(IconButton, { icon: isTitleEditing ? React.createElement(IconCheck, null) : React.createElement(IconPen, null), color: "secondary", disabled: !(title === null || title === void 0 ? void 0 : title.trim()) || disabled, onClick: () => setIsTitleEditing(!isTitleEditing), title: isTitleEditing ? done : edit }))),
        React.createElement("div", { className: styles.rightPart },
            additionalComponent,
            handleAdd && (React.createElement(Button, { variant: addButtonVariant === "text" ? "text" : "contained", color: addButtonVariant === "text" ? "primary" : "secondary", onClick: handleAdd, disabled: isViewOnly || isSectionHidden, leftIcon: React.createElement(IconPlus, null) }, addButtonLabel || add)),
            handleSectionHide && (React.createElement(IconButton, { onClick: handleSectionHide, title: tooltip(title, isSectionHidden), disabled: isViewOnly || isTitleEditing, icon: isSectionHidden ? React.createElement(IconEyeCrossed, null) : React.createElement(IconEye, null), color: "secondary" })),
            handleSectionRemove && (React.createElement(IconButton, { onClick: handleSectionRemove, disabled: disabled, icon: React.createElement(IconTrash, null), color: "secondary", title: remove })))));
}
