var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PASSWORD_AUTH, TOKEN_AUTH, Api } from "@services";
import { getCurrentUser, setAuthToken, store } from "@store";
import { decode, LocalStorageKeys, safeLocalStorageGet, safeLocalStorageRemove, safeLocalStorageSet, } from "@utils";
import { updateAxiosToken } from "./axios";
const TOKEN_LIFE_TIME_DELAY = 5 * 60 * 1000; // 5 min
export const setToken = (token) => {
    updateAxiosToken(token);
    store.dispatch(setAuthToken(token));
    if (token === null) {
        safeLocalStorageRemove(LocalStorageKeys.authToken);
    }
    else {
        safeLocalStorageSet(LocalStorageKeys.authToken, token);
    }
};
export const signOut = () => __awaiter(void 0, void 0, void 0, function* () {
    setToken(null);
});
export const breakToken = () => {
    setToken("invalid_token");
};
const refreshToken = () => __awaiter(void 0, void 0, void 0, function* () {
    const token = store.getState().users.authToken;
    const { data: response } = yield Api.post(TOKEN_AUTH, {
        idToken: token,
    });
    setToken(response.idToken);
});
const scheduleTokenRefresh = (token) => {
    const { exp: expirationTime, iat: issuedAt } = decode(token);
    const tokenLifeTime = (expirationTime - issuedAt) * 1000;
    setInterval(refreshToken, tokenLifeTime - TOKEN_LIFE_TIME_DELAY);
};
export const reSignIn = (token) => __awaiter(void 0, void 0, void 0, function* () {
    const { email: currentUserEmail, exp: expirationTime, iat: issuedAt, } = decode(token);
    setToken(token);
    yield store.dispatch(getCurrentUser(currentUserEmail));
    const timeUntilExpiration = expirationTime * 1000 - Date.now();
    const tokenLifeTime = (expirationTime - issuedAt) * 1000;
    setTimeout(() => {
        refreshToken();
        setInterval(refreshToken, tokenLifeTime - TOKEN_LIFE_TIME_DELAY);
    }, timeUntilExpiration - TOKEN_LIFE_TIME_DELAY);
});
export const handleSignInWithPassword = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
    if (!email || !password)
        return;
    // Get inner auth_token
    const { data: response } = yield Api.post(PASSWORD_AUTH, {
        email,
        password,
    });
    setToken(response.idToken);
    yield store.dispatch(getCurrentUser(email)).unwrap();
    scheduleTokenRefresh(response.idToken);
});
export const handleSignInWithGoogle = (token) => __awaiter(void 0, void 0, void 0, function* () {
    // Exchange google id_token for inner auth_token
    updateAxiosToken(token);
    const { data: response } = yield Api.post(TOKEN_AUTH, {
        idToken: token,
    });
    setToken(response.idToken);
    const currentUserEmail = decode(response.idToken).email;
    yield store.dispatch(getCurrentUser(currentUserEmail)).unwrap();
    scheduleTokenRefresh(response.idToken);
});
const initialize = () => __awaiter(void 0, void 0, void 0, function* () {
    window.google.accounts.id.initialize({
        client_id: process.env.CLIENT_ID,
        callback: (e) => {
            handleSignInWithGoogle(e.credential);
        },
    });
});
export const extractActiveToken = () => {
    const idToken = safeLocalStorageGet(LocalStorageKeys.authToken);
    const token = idToken && decode(idToken);
    if (token) {
        const timeUntilExpiration = token.exp * 1000 - Date.now();
        // Check if stored token will live longer then 5 min
        if (timeUntilExpiration > TOKEN_LIFE_TIME_DELAY) {
            return idToken;
        }
        return null;
    }
    return null;
};
export const initializeGoogleAuth = () => __awaiter(void 0, void 0, void 0, function* () {
    const storedToken = extractActiveToken();
    if (storedToken) {
        yield reSignIn(storedToken);
        return false;
    }
    yield initialize();
    return true;
});
export const initializeDevAuth = () => __awaiter(void 0, void 0, void 0, function* () {
    const storedToken = extractActiveToken();
    if (storedToken) {
        yield reSignIn(storedToken);
    }
});
