import { SortOrder } from "@types";
import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        title: ["Professional Experience", "Профессиональный опыт"][index],
        projectCopied: ["Project successfully copied", "Проект успешно скопирован"][index],
        emptyDate: [
            "Date fields are empty or have an incorrect format",
            "Поля даты пусты или имеют неверный формат",
        ][index],
        wrongDate: [
            "Start date should be before end date",
            "Начальная дата должна быть раньше конечной",
        ][index],
        sort: ["Sort", "Порядок"][index],
        add: ["Add Project", "Добавить Проект"][index],
    };
};
export const getSortOptionsTranslation = (language) => {
    const index = languageIndex(language);
    return {
        [SortOrder.Custom]: [{ target: "Custom" }, { target: "Пользовательская" }][index],
        [SortOrder.StartDescending]: [
            { target: "Start Date", order: "Latest first" },
            {
                target: "Начало",
                order: "с поздних",
            },
        ][index],
        [SortOrder.StartAscending]: [
            { target: "Start Date", order: "Earliest first" },
            {
                target: "Начало",
                order: "с ранних",
            },
        ][index],
        [SortOrder.EndDescending]: [
            { target: "End Date", order: "Latest first" },
            {
                target: "Конец",
                order: "с поздних",
            },
        ][index],
        [SortOrder.EndAscending]: [
            { target: "End Date", order: "Earliest first" },
            {
                target: "Конец",
                order: "с ранних",
            },
        ][index],
    };
};
