export var CustomSectionContentTypeDTO;
(function (CustomSectionContentTypeDTO) {
    CustomSectionContentTypeDTO[CustomSectionContentTypeDTO["Text"] = 0] = "Text";
    CustomSectionContentTypeDTO[CustomSectionContentTypeDTO["BulletPoints"] = 1] = "BulletPoints";
})(CustomSectionContentTypeDTO || (CustomSectionContentTypeDTO = {}));
export var CustomSectionContentType;
(function (CustomSectionContentType) {
    CustomSectionContentType["Text"] = "text";
    CustomSectionContentType["BulletPoints"] = "bulletPoints";
})(CustomSectionContentType || (CustomSectionContentType = {}));
