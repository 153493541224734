import { useEffect } from "react";
export const useOutsideEvent = (ref, isEnabled, callback, anchorRef, excludedRefs) => {
    const handleScroll = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            callback();
        }
    };
    const handleMouseDown = (event) => {
        var _a, _b;
        if (isEnabled &&
            (ref === null || ref === void 0 ? void 0 : ref.current) &&
            !((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) &&
            !((_b = anchorRef === null || anchorRef === void 0 ? void 0 : anchorRef.current) === null || _b === void 0 ? void 0 : _b.contains(event.target)) &&
            !(excludedRefs === null || excludedRefs === void 0 ? void 0 : excludedRefs.find((r) => { var _a; return (_a = r.current) === null || _a === void 0 ? void 0 : _a.contains(event.target); }))) {
            callback();
        }
    };
    const handleKeyDown = ({ key }) => {
        if (key === "Escape") {
            callback();
        }
    };
    useEffect(() => {
        if (isEnabled) {
            window.addEventListener("wheel", handleScroll);
            window.addEventListener("touchmove", handleScroll);
            document.addEventListener("mousedown", handleMouseDown);
            window.addEventListener("keydown", handleKeyDown);
        }
        return () => {
            window.removeEventListener("wheel", handleScroll);
            window.removeEventListener("touchmove", handleScroll);
            document.removeEventListener("mousedown", handleMouseDown);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isEnabled]);
};
