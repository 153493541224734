import React from "react";
import { useAppSelector } from "@hooks";
import { safeLocalStorageGet } from "@utils";
import { CLOSED, Notification } from "./Notification";
import styles from "./Notifications.module.scss";
export function Notifications() {
    const { notifications } = useAppSelector((state) => state.ui);
    return (React.createElement("div", { className: styles.notificationsBox }, notifications
        .filter(({ localStorageKey }) => safeLocalStorageGet(localStorageKey) !== CLOSED)
        .map((notification) => (React.createElement(Notification, Object.assign({ key: notification.id }, notification))))));
}
