var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { TextField } from "@components";
import { SelectionToolProvider } from "../SelectionToolProvider";
export function InputWithSelectionTool(_a) {
    var { hasError, innerRef, readOnly, disabled, 
    // SelectionToolProvider props
    value, icon, tooltip, handleValueChange, handleSelectionAction, handleSelectionActionError, preset, isSelectionFeatureEnabled, autocompleteList, autocompleteComponent, label } = _a, props = __rest(_a, ["hasError", "innerRef", "readOnly", "disabled", "value", "icon", "tooltip", "handleValueChange", "handleSelectionAction", "handleSelectionActionError", "preset", "isSelectionFeatureEnabled", "autocompleteList", "autocompleteComponent", "label"]);
    return (React.createElement(SelectionToolProvider, { type: "input", value: value, icon: icon, tooltip: tooltip, handleValueChange: handleValueChange, handleSelectionAction: handleSelectionAction, handleSelectionActionError: handleSelectionActionError, preset: preset, disabled: disabled || readOnly, isSelectionFeatureEnabled: isSelectionFeatureEnabled }, ({ containerRef, value: innerValue, assistButton, onSelect, onBlur, onMouseDown }) => (React.createElement(TextField, Object.assign({}, props, { label: label, autocompleteList: autocompleteList, autocompleteComponent: autocompleteComponent, hasError: hasError, readOnly: readOnly, disabled: disabled, containerRef: containerRef, innerRef: innerRef, value: innerValue, assistButton: assistButton, onChange: ({ target: { value: val } }) => handleValueChange(val), onSelect: onSelect, onBlur: onBlur, onMouseDown: onMouseDown, isLimitVisible: true })))));
}
