var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import clsx from "clsx";
import { beautifyString, setCV, translateSubstring } from "@store";
import { addId } from "@utils";
import { useAppDispatch, useAppSelector, useAsyncAction, useRevert } from "@hooks";
import { AssistButton, TextAreaWithSelectionTool, IconButton, ListField, SectionTitle, } from "@components";
import { HeadingIds, dictionary } from "@constants";
import UndoIcon from "@assets/undo.svg";
import { fields } from "./localization";
import commonStyles from "../../CV.common.scss";
import styles from "./Overview.module.scss";
const EMPTY_OVERVIEW = {
    text: "",
    bulletpointsDisplay: [addId("")],
};
export function OverviewComponent() {
    const { language, isViewOnly } = useAppSelector((state) => state.ui);
    const { cv } = useAppSelector((state) => state.cv);
    const { featureFlags } = useAppSelector((state) => state.ui);
    const dispatch = useAppDispatch();
    const { overviewDisplay } = cv || { overviewDisplay: EMPTY_OVERVIEW };
    const { text, bulletpointsDisplay } = overviewDisplay;
    const setOverview = (newOverview) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { overviewDisplay: newOverview })));
    };
    const handleOverviewEdit = (value) => {
        setOverview(Object.assign(Object.assign({}, overviewDisplay), { text: value }));
    };
    const handleEditOverviewPoints = (newPoints) => {
        setOverview(Object.assign(Object.assign({}, overviewDisplay), { bulletpointsDisplay: [...newPoints] }));
    };
    const [popFromBackup, saveToBackup, isBackUpEmpty] = useRevert(handleOverviewEdit);
    const handleItemSelection = (itemIndex, selectionText, selectionStart, selectionEnd) => __awaiter(this, void 0, void 0, function* () {
        const result = yield dispatch(translateSubstring({
            text: selectionText,
            start: selectionStart,
            end: selectionEnd,
        })).unwrap();
        handleEditOverviewPoints(bulletpointsDisplay.map((point, index) => {
            if (index === itemIndex) {
                return Object.assign(Object.assign({}, point), { value: result });
            }
            return point;
        }));
    });
    const beautifyText = (value) => __awaiter(this, void 0, void 0, function* () {
        const result = yield dispatch(beautifyString({ text: value })).unwrap();
        saveToBackup(text);
        handleOverviewEdit(result);
    });
    const handleOverviewSelectAction = (value, start, end) => __awaiter(this, void 0, void 0, function* () {
        const result = yield dispatch(translateSubstring({ text: value, start, end })).unwrap();
        handleOverviewEdit(result);
    });
    const [performAction, isActionInProgress] = useAsyncAction(() => __awaiter(this, void 0, void 0, function* () {
        yield beautifyText(text);
    }));
    const field = fields(language);
    const { undo } = dictionary(language);
    return (React.createElement("section", { id: HeadingIds.Overview, className: commonStyles.section },
        React.createElement(SectionTitle, { title: field.title }),
        React.createElement("div", { className: clsx(styles.overviewSection, !(featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.enableInlineTranslationAndBeautification) && styles.withoutAssistTool) },
            React.createElement("header", { className: styles.header },
                !isBackUpEmpty && (React.createElement(IconButton, { title: undo, className: styles.undo, icon: React.createElement(UndoIcon, null), color: "secondary", onClick: popFromBackup })),
                React.createElement(AssistButton, { disabled: !text || isViewOnly, className: commonStyles.hoverButton, performAction: performAction, isActionInProgress: isActionInProgress })),
            React.createElement(TextAreaWithSelectionTool, { wrapClassName: commonStyles.input, placeholder: field.title, rows: 7, maxLength: 1400, readOnly: isViewOnly, value: text, handleValueChange: (value) => handleOverviewEdit(value), handleSelectionAction: handleOverviewSelectAction, description: field.description, isAutoGrowing: true })),
        React.createElement(ListField, { title: field.skills, itemTitle: field.skill, points: bulletpointsDisplay, onEdit: handleEditOverviewPoints, onItemSelection: handleItemSelection, readOnly: isViewOnly, maxLength: 150, selectionActionPreset: "translate", addButtonLabel: field.addSkill })));
}
