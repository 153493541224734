import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        firstName: ["First name", "Имя"][index],
        lastName: ["Last name", "Фамилия"][index],
        jobTitle: ["Job Title", "Должность"][index],
        heading: ["General Info", "Общая информация"][index],
    };
};
