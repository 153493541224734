import { createSlice } from "@reduxjs/toolkit";
import { addRevision, deleteRevision, getRevisions, markDefault, removeRevision, renamePreviewRevision, renameRevision, setCVPreview, setSelectedRevision, translateRevision, uploadCV, } from "@store/revisions";
export const DEFAULT_CV_REVISION = {
    name: "default",
    isPreview: false,
    isDefault: true,
};
const initialState = {
    revisions: [],
    cvPreview: null,
    isCreateRevisionLoading: false,
    isGetRevisionsLoading: false,
    createRevisionError: "",
    isDeleteRevisionLoading: false,
    isRenameRevisionLoading: false,
    isTranslateRevisionLoading: false,
    translateRevisionError: "",
    deleteRevisionError: "",
    getRevisionsError: "",
    createError: "",
    renameRevisionError: "",
    isUploadCvLoading: false,
    uploadCvError: "",
    selectedRevision: DEFAULT_CV_REVISION,
};
export const revisionsSlice = createSlice({
    name: "revisions",
    initialState,
    reducers: {},
    extraReducers: {
        [getRevisions.pending.type]: (state) => {
            state.isGetRevisionsLoading = true;
        },
        [getRevisions.fulfilled.type]: (state, action) => {
            if (action.payload.length) {
                const defaultRevisions = action.payload.filter((revision) => revision.isDefault);
                const notDefaultRevisions = action.payload.filter((revision) => !revision.isDefault);
                notDefaultRevisions.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
                state.revisions = [...defaultRevisions, ...notDefaultRevisions];
            }
            else {
                state.revisions = [DEFAULT_CV_REVISION];
            }
            state.isGetRevisionsLoading = false;
        },
        [getRevisions.rejected.type]: (state, action) => {
            state.getRevisionsError = action.payload;
            state.isGetRevisionsLoading = false;
        },
        [deleteRevision.pending.type]: (state) => {
            state.isDeleteRevisionLoading = true;
        },
        [deleteRevision.fulfilled.type]: (state, action) => {
            state.revisions = state.revisions.filter((revision) => revision.name !== action.payload);
            state.isDeleteRevisionLoading = false;
        },
        [deleteRevision.rejected.type]: (state, action) => {
            state.createRevisionError = action.payload;
            state.isDeleteRevisionLoading = false;
        },
        [renameRevision.pending.type]: (state) => {
            state.isRenameRevisionLoading = true;
        },
        [renameRevision.fulfilled.type]: (state, action) => {
            const { oldName, newName } = action.meta.arg;
            state.revisions = state.revisions.map((revision) => {
                if (revision.name === oldName) {
                    return Object.assign(Object.assign({}, revision), { name: newName });
                }
                return revision;
            });
            state.renameRevisionError = "";
            state.isRenameRevisionLoading = false;
        },
        [renameRevision.rejected.type]: (state, action) => {
            state.renameRevisionError = action.payload;
            state.isRenameRevisionLoading = false;
        },
        [translateRevision.pending.type]: (state) => {
            state.isTranslateRevisionLoading = true;
        },
        [translateRevision.fulfilled.type]: (state, action) => {
            state.cvPreview = action.payload;
            state.translateRevisionError = "";
            state.isTranslateRevisionLoading = false;
        },
        [translateRevision.rejected.type]: (state, action) => {
            state.translateRevisionError = action.payload;
            state.isTranslateRevisionLoading = false;
        },
        [addRevision.type]: (state, action) => {
            state.revisions.push(action.payload);
        },
        [renamePreviewRevision.type]: (state, action) => {
            const { isPreview } = state.revisions.find((revision) => revision.name === action.payload.oldName);
            if (isPreview) {
                const allWithoutOne = state.revisions.filter((revision) => revision.name !== action.payload.oldName);
                allWithoutOne.push({ name: action.payload.newName, isPreview: true });
                state.revisions = allWithoutOne;
            }
        },
        [removeRevision.type]: (state, action) => {
            state.revisions = state.revisions.filter((revision) => revision.name !== action.payload.name || revision.isPreview !== action.payload.isPreview);
            if (action.payload.isPreview) {
                state.cvPreview = null;
            }
        },
        [uploadCV.pending.type]: (state) => {
            state.isUploadCvLoading = true;
        },
        [uploadCV.fulfilled.type]: (state, action) => {
            state.cvPreview = action.payload;
            state.uploadCvError = "";
            state.isUploadCvLoading = false;
        },
        [uploadCV.rejected.type]: (state, action) => {
            state.uploadCvError = action.payload;
            state.isUploadCvLoading = false;
        },
        [setSelectedRevision.type]: (state, action) => {
            state.selectedRevision = action.payload;
        },
        [setCVPreview.type]: (state, action) => {
            state.cvPreview = action.payload;
        },
        [markDefault.fulfilled.type]: (state, action) => {
            const { targetName } = action.meta.arg;
            state.revisions = state.revisions.map((revision) => (Object.assign(Object.assign({}, revision), { isDefault: revision.name === targetName })));
            state.renameRevisionError = "";
            state.isRenameRevisionLoading = false;
        },
    },
});
export default revisionsSlice.reducer;
