import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        sharingDisabled: ["Sharing disabled", "Получение ссылки заблокировано"][index],
        sharingActive: [
            "Get link to download PDF or DOCX",
            "Получить ссылку для скачивания PDF или DOCX",
        ][index],
    };
};
