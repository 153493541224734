import { createSlice } from "@reduxjs/toolkit";
import { Language, isUILanguage, } from "@types";
import { getFeatureFlags, hideNotification, setIsDatesWarningHidden, setIsViewOnly, setLanguage, showNotification, } from "@store/ui/uiActions";
import { uniqueId } from "@utils";
const initialState = {
    language: Language.EN,
    isViewOnly: false,
    notifications: [],
    featureFlags: null,
    isGetFlagsLoading: false,
    getFlagsError: "",
    isDatesIntersectionWarningHidden: false,
};
export const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {},
    extraReducers: {
        [getFeatureFlags.pending.type]: (state) => {
            state.isGetFlagsLoading = true;
        },
        [getFeatureFlags.fulfilled.type]: (state, action) => {
            state.featureFlags = action.payload;
            state.isGetFlagsLoading = false;
        },
        [getFeatureFlags.rejected.type]: (state, action) => {
            state.getFlagsError = action.payload;
            state.isGetFlagsLoading = false;
        },
        [setLanguage.type]: (state, action) => {
            if (isUILanguage(action.payload)) {
                state.language = action.payload;
            }
        },
        [setIsViewOnly.type]: (state, action) => {
            state.isViewOnly = action.payload;
        },
        [showNotification.type]: (state, action) => {
            state.notifications.push(Object.assign({ id: uniqueId() }, action.payload));
        },
        [hideNotification.type]: (state, action) => {
            state.notifications = state.notifications.filter((n) => n.id !== action.payload);
        },
        [setIsDatesWarningHidden.type]: (state, action) => {
            state.isDatesIntersectionWarningHidden = action.payload;
        },
    },
});
export default uiSlice.reducer;
