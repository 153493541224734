import React, { useEffect, useRef, useState } from "react";
import { MenuItem, Overlay } from "@components";
import ArrowDown from "@assets/icon_arrow_down_rounded.svg";
import clsx from "clsx";
import styles from "./Select.module.scss";
export function Select({ options, isSelectable, onOptionClick, value, disabled, label, supportingText, hasError, placeholder, parentWidth = true, hasInput = false, }) {
    const [isOpen, setIsOpen] = useState(false);
    const [filterText, setFilterText] = useState(value);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const anchorRef = useRef(null);
    const inputRef = useRef(null);
    const matchIndex = filterText
        ? options.findIndex((option) => option.label.toLowerCase().startsWith(filterText.toLowerCase()))
        : -1;
    const handleButtonClick = () => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.select();
        setIsOpen(!isOpen);
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter" && isOpen && matchIndex !== -1) {
            event.preventDefault();
            onOptionClick(matchIndex, options[matchIndex]);
            setIsOpen(false);
        }
    };
    useEffect(() => {
        setHighlightedIndex(matchIndex);
    }, [filterText, options]);
    useEffect(() => {
        if (!isOpen) {
            setFilterText(value);
            if (inputRef.current) {
                inputRef.current.blur();
            }
        }
    }, [isOpen]);
    useEffect(() => {
        if (isOpen && highlightedIndex !== -1) {
            const itemToScroll = document.querySelector(`[data-highlighted-index="${highlightedIndex}"]`);
            if (itemToScroll) {
                itemToScroll.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }
    }, [isOpen, highlightedIndex]);
    return (React.createElement("div", { className: clsx(styles.container, hasError && styles.error) },
        label && React.createElement("span", { className: clsx("label-medium", styles.label) }, label),
        React.createElement("div", { role: "listbox", className: styles.overlayContainer },
            React.createElement("button", { type: "button", onClick: handleButtonClick, onKeyDown: handleKeyDown, disabled: disabled, className: clsx(styles.select, hasError && styles.error, isOpen && styles.focus), ref: anchorRef },
                hasInput ? (React.createElement("input", { ref: inputRef, type: "text", value: filterText, placeholder: placeholder, className: clsx(styles.value, styles.input), onChange: (e) => {
                        setFilterText(e.target.value);
                    } })) : (React.createElement("span", { className: clsx(styles.value, !value && styles.placeholder) }, value || placeholder)),
                React.createElement(ArrowDown, { className: clsx(styles.icon, isOpen && styles.iconOpen) })),
            React.createElement(Overlay, { isOpen: isOpen, hideOverlay: () => setIsOpen(false), primaryPositionX: "right", parentWidth: parentWidth, anchorRef: anchorRef },
                React.createElement("div", { className: styles.items }, options.map((option, index) => (React.createElement(MenuItem, { key: option.id, "data-highlighted-index": index, selectable: isSelectable, isSelected: option.isSelected, isHighlighted: index === matchIndex, onClick: () => {
                        setIsOpen(false);
                        onOptionClick(index, option);
                    } }, option.label)))))),
        supportingText && (React.createElement("span", { className: clsx("body-small", styles.message) }, supportingText))));
}
