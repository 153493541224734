import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "@hooks";
import IconLink from "@assets/icon_link.svg";
import { ShareLinkDropDown } from "../ShareLinkDropDown";
import { fields } from "./localization";
export function ShareLink({ activateLinks, isSharePDFLinkActive, isShareDOCXLinkActive, isShareDriveLinkActive, handleGetFileLinkClick, setIsCVScrollable, disabled, }) {
    const [isShareLinkOpen, setIsShareLinkOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const shareLinkRef = useRef(null);
    const { cvPreview, selectedRevision } = useAppSelector((state) => state.revisions);
    const { language } = useAppSelector((state) => state.ui);
    useEffect(() => {
        setIsDisabled(selectedRevision.isPreview || !!cvPreview || disabled);
    }, [selectedRevision, cvPreview, disabled]);
    const field = fields(language);
    return (React.createElement(ShareLinkDropDown, { isShareLinkOpen: isShareLinkOpen, setIsShareLinkOpen: setIsShareLinkOpen, shareLinkRef: shareLinkRef, isSharePDFLinkActive: isSharePDFLinkActive, isShareDOCXLinkActive: isShareDOCXLinkActive, isShareDriveLinkActive: isShareDriveLinkActive, handleGetFileLinkClick: handleGetFileLinkClick, setIsCVScrollable: setIsCVScrollable, toggleOpen: () => {
            activateLinks();
            setIsShareLinkOpen(!isShareLinkOpen);
        }, disabled: isDisabled, title: isDisabled ? field.sharingDisabled : field.sharingActive, anchor: React.createElement(IconLink, { title: isDisabled ? field.sharingDisabled : field.sharingActive }) }));
}
