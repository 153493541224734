import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@hooks";
import { setCV, showNotification } from "@store";
import { HeadingIds } from "@constants";
import { Language as LanguageTypes } from "@types";
import { createLanguagePoint } from "@utils";
import { SectionTitle } from "@components";
import { OptionsField } from "@pages/CvPage/components/CV/components";
import { findEqualLanguages } from "./validators";
import { fields } from "./localization";
import commonStyles from "../../CV.common.scss";
export function Language() {
    var _a;
    const dispatch = useAppDispatch();
    const { cv } = useAppSelector((state) => state.cv);
    const { language: languageUI } = useAppSelector((state) => state.ui);
    const { cvPreview } = useAppSelector((state) => state.revisions);
    const [intersectionIds, setIntersectionIds] = useState([]);
    const field = fields(languageUI);
    const secondField = fields(languageUI === LanguageTypes.EN ? LanguageTypes.RU : LanguageTypes.EN);
    const language = ((_a = cv === null || cv === void 0 ? void 0 : cv.languageDisplay) === null || _a === void 0 ? void 0 : _a.length)
        ? cv.languageDisplay
        : [createLanguagePoint()];
    useEffect(() => {
        const equalLanguages = findEqualLanguages(language);
        if (!(equalLanguages.length === 0 && intersectionIds.length === 0)) {
            setIntersectionIds(equalLanguages);
        }
        if (language && language.length > 0 && cvPreview) {
            const emptyLevel = language.find((lang) => !lang.level && lang.language);
            if (emptyLevel) {
                dispatch(showNotification({ message: field.emptyLevelField, isSuccess: false }));
            }
        }
    }, [language, cvPreview]);
    const setLanguage = (languageDisplay) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { languageDisplay })));
    };
    const handleAdd = () => {
        if (language.length === 0) {
            setLanguage([createLanguagePoint()]);
        }
        else {
            setLanguage([...language, createLanguagePoint()]);
        }
    };
    const onEditLanguage = (index, lang) => {
        const newPoints = [...language];
        const { displayId, level } = language[index];
        newPoints[index] = { language: lang, displayId, level };
        setLanguage(newPoints);
    };
    const onEditLevel = (index, level) => {
        const newPoints = [...language];
        const { displayId } = language[index];
        const lang = language[index].language;
        newPoints[index] = { language: lang, displayId, level };
        setLanguage(newPoints);
    };
    const languageOptions = [
        { code: "en", name: field.englishLanguage, secondName: secondField.englishLanguage },
        { code: "pl", name: field.polishLanguage, secondName: secondField.polishLanguage },
        { code: "de", name: field.germanLanguage, secondName: secondField.germanLanguage },
        { code: "it", name: field.italianLanguage, secondName: secondField.italianLanguage },
        { code: "es", name: field.spanishLanguage, secondName: secondField.spanishLanguage },
        { code: "fr", name: field.frenchLanguage, secondName: secondField.frenchLanguage },
        { code: "lt", name: field.lithuanianLanguage, secondName: secondField.lithuanianLanguage },
        { code: "ru", name: field.russianLanguage, secondName: secondField.russianLanguage },
        { code: "zh", name: field.chineseLanguage, secondName: secondField.chineseLanguage },
        { code: "uz", name: field.uzbekLanguage, secondName: secondField.uzbekLanguage },
        { code: "kz", name: field.kazakhLanguage, secondName: secondField.kazakhLanguage },
        { code: "bl", name: field.bulgarianLanguage, secondName: secondField.bulgarianLanguage },
        { code: "ar", name: field.arabicLanguage, secondName: secondField.arabicLanguage },
        { code: "by", name: field.belarusianLanguage, secondName: secondField.belarusianLanguage },
        { code: "az", name: field.azerbaijaniLanguage, secondName: secondField.azerbaijaniLanguage },
        { code: "tr", name: field.turkishLanguage, secondName: secondField.turkishLanguage },
        { code: "ua", name: field.ukrainianLanguage, secondName: secondField.ukrainianLanguage },
    ];
    const levelOptions = [
        { code: "A1", name: field.beginnerLevel, secondName: secondField.beginnerLevel },
        { code: "A2", name: field.preIntermediateLevel, secondName: secondField.preIntermediateLevel },
        { code: "B1", name: field.intermediateLevel, secondName: secondField.intermediateLevel },
        {
            code: "B2",
            name: field.upperIntermediateLevel,
            secondName: secondField.upperIntermediateLevel,
        },
        { code: "C1", name: field.advancedLevel, secondName: secondField.advancedLevel },
        { code: "C2", name: field.masteryLevel, secondName: secondField.masteryLevel },
        { code: "native", name: field.nativeLevel, secondName: secondField.nativeLevel },
    ];
    const translateLanguage = (langArray) => {
        return langArray.map((item) => {
            const selectedLanguage = languageOptions.find((i) => i.name === item.language || i.secondName === item.language);
            const selectedLevel = levelOptions.find((i) => i.name === item.level || i.secondName === item.level);
            return Object.assign(Object.assign({}, item), { language: (selectedLanguage === null || selectedLanguage === void 0 ? void 0 : selectedLanguage.name) || "", level: (selectedLevel === null || selectedLevel === void 0 ? void 0 : selectedLevel.name) || "" });
        });
    };
    const translatedLanguage = translateLanguage(language);
    const optionsList = [
        {
            value: "language",
            options: languageOptions
                .sort((a, b) => a.name.localeCompare(b.name))
                .filter((opt) => {
                const languageMap = language.map((lang) => lang.language);
                return !languageMap.includes(opt.name) && !languageMap.includes(opt.secondName);
            }),
            onEditPoint: onEditLanguage,
            placeholder: field.placeholderLang,
        },
        {
            value: "level",
            options: levelOptions,
            onEditPoint: onEditLevel,
            placeholder: field.placeholderLevel,
        },
    ];
    return (React.createElement("section", { id: HeadingIds.LanguageSkills, className: commonStyles.section },
        React.createElement(SectionTitle, { title: field.title, handleAdd: handleAdd, addButtonLabel: field.add }),
        React.createElement(OptionsField, { title: field.title, points: translatedLanguage, options: optionsList, onEdit: setLanguage, errorIds: intersectionIds })));
}
