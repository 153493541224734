import React, { useState } from "react";
import { MenuItem } from "@components";
import { useTablet } from "@hooks";
import IconArrowDownRounded from "@assets/icon_arrow_down_rounded.svg";
import IconSort from "@assets/icon-sort.svg";
import { DropDownWrap } from "../DropDownWrap";
import styles from "./SortSelector.module.scss";
export function SortSelector({ options, selectedOption, onOptionSelect, showIcon, label }) {
    const [isOpen, setIsOpen] = useState(false);
    const isTablet = useTablet();
    const selectedOptionItem = options.find(({ displayId }) => displayId === selectedOption);
    return (React.createElement(DropDownWrap, { isOpen: isOpen, toggleOpen: () => {
            setIsOpen(!isOpen);
        }, color: isTablet ? "secondary" : "tertiary", title: isTablet ? label : null, anchor: isTablet ? (React.createElement(IconSort, { className: styles.icon })) : (React.createElement(React.Fragment, null,
            React.createElement("span", { className: styles.anchor },
                React.createElement("span", { className: styles.labelStaticPart }, label && `${label}: `),
                React.createElement("span", { className: styles.labelVariablePart }, `${selectedOptionItem.target}${selectedOptionItem.order ? ` | ${selectedOptionItem.order}` : ""}`)),
            showIcon && React.createElement(IconArrowDownRounded, { className: styles.icon }))), variant: "text", iconOnly: isTablet }, options.map((option) => (React.createElement(MenuItem, { onClick: () => {
            setIsOpen(false);
            onOptionSelect(option.displayId);
        }, key: option.displayId, isSelected: option.displayId === selectedOption },
        React.createElement("span", { className: styles.sortTarget }, option.target),
        option.order && React.createElement("span", { className: styles.sortOrder }, ` | ${option.order}`))))));
}
