import React, { useEffect, useState } from "react";
import { IconButton, DropDownWrap } from "@components";
import IconPDF from "@assets/icon_pdf.svg";
import IconDOC from "@assets/icon_doc.svg";
import IconDownload from "@assets/icon_download.svg";
import { useAppSelector } from "@hooks";
import { fields } from "./localization";
import styles from "./CvFileDownloadFormatDropdown.module.scss";
export function CvFileDownloadFormatDropdown({ isFileDownloadPDFLoading, isFileDownloadDOCXLoading, handleDownload, setIsCVScrollable, }) {
    const [isCvDownloadFormatOpen, setIsCvDownloadFormatOpen] = useState(false);
    const { language } = useAppSelector((state) => state.ui);
    const { isCVChanged } = useAppSelector((state) => state.cv);
    const { selectedTemplate } = useAppSelector((state) => state.templates);
    useEffect(() => {
        setIsCVScrollable(!isCvDownloadFormatOpen);
    }, [isCvDownloadFormatOpen]);
    const field = fields(language);
    return (React.createElement(DropDownWrap, { isOpen: isCvDownloadFormatOpen, toggleOpen: () => setIsCvDownloadFormatOpen(!isCvDownloadFormatOpen), disabled: isCVChanged, iconOnly: true, title: isCVChanged ? field.downloadDisabled : field.download, anchor: React.createElement(IconDownload, null) },
        React.createElement("p", { className: "body-small text-center" }, field.selectDownloadFormat),
        React.createElement("div", { className: styles.downloadButtons },
            React.createElement(IconButton, { icon: React.createElement(IconPDF, null), iconCustomColor: true, variant: "outlined", onClick: () => handleDownload(selectedTemplate.formatName, "pdf" /* CVFileTypes.pdf */), title: field.downloadCvFilePDF, isLoading: isFileDownloadPDFLoading }),
            React.createElement(IconButton, { icon: React.createElement(IconDOC, null), iconCustomColor: true, variant: "outlined", onClick: () => handleDownload(selectedTemplate.formatName, "docx" /* CVFileTypes.docx */), title: field.downloadCvFileDOCX, isLoading: isFileDownloadDOCXLoading }))));
}
