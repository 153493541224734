import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        selectRevision: [
            "Revision to copy content from",
            "Ревизия из которой нужно скопировать содержимое",
        ][index],
        label: ["Name", "Название"][index],
        modalTitle: ["Create Revision", "Создать ревизию"][index],
        cancel: ["Cancel", "Отменить"][index],
        create: ["Create", "Создать"][index],
    };
};
