import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        linkExpires: ["Link expires", "Ссылка истекает"][index],
        copyPDFLink: ["Copy PDF CV link to clipboard", "Скопировать ссылку на PDF CV в буфер обмена"][index],
        copyDOCXLink: [
            "Copy DOCX CV link to clipboard",
            "Скопировать ссылку на DOCX CV в буфер обмена",
        ][index],
        copyDriveLink: [
            "Copy Google Drive CV link to clipboard",
            "Скопировать ссылку на Drive CV в буфер обмена",
        ][index],
        linkLifetime: ["Links lifetime is limited", "Время жизни ссылок ограничено"][index],
        selectLink: ["Select format to share", "Выберите формат чтобы поделиться"][index],
    };
};
