import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        tooltip: (title, isSectionHidden) => {
            return isSectionHidden
                ? [`Show ${title}`, `Показать ${title}`][index]
                : [`Hide ${title}`, `Скрыть ${title}`][index];
        },
        add: ["Add", "Добавить"][index],
        edit: ["Edit", "Изменить"][index],
        done: ["Done", "Готово"][index],
    };
};
