var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { CommonModal, Loader } from "@components";
import { Footer, Header } from "./components";
import styles from "./Modal.module.scss";
export function Modal(_a) {
    var { open, title, loaderClassName, isContentLoading, content, headerContent, footerContent, onRequestClose, forwardedRef, footerSupportingText, size = "small" } = _a, props = __rest(_a, ["open", "title", "loaderClassName", "isContentLoading", "content", "headerContent", "footerContent", "onRequestClose", "forwardedRef", "footerSupportingText", "size"]);
    return (open && (React.createElement(CommonModal, Object.assign({ open: open, onRequestClose: onRequestClose, forwardedRef: forwardedRef, size: size }, props), isContentLoading ? (React.createElement(Loader, { classStyle: loaderClassName })) : (React.createElement(React.Fragment, null,
        React.createElement(Header, { title: title, headerContent: headerContent, onCloseClick: onRequestClose }),
        React.createElement("main", { className: styles.content }, content),
        footerContent && (React.createElement(Footer, { supportingText: footerSupportingText }, footerContent)))))));
}
