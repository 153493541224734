import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        translate: ["Translate", "Перевести"][index],
        translateDisabled: ["Translate disabled", "Перевод отключён"][index],
        translateActive: ["Translate revision", "Перевести ревизию"][index],
        selectLang: ["Select language to translate", "Выберите язык для перевода"][index],
        revision: ["revision:", "ревизии:"][index],
        from: ["From", "С"][index],
        to: ["To", "На"][index],
        swap: ["Swap", "Поменять местами"][index],
        notification: [
            "Revision translated. Upload and Translate buttons disabled while you have unsaved translated revision.",
            "Ревизия переведена. Кнопки «Загрузить» и «Перевести» отключены пока у вас есть несохраненная переведенная ревизия.",
        ][index],
    };
};
