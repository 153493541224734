var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import clsx from "clsx";
import ActiveIcon from "@assets/icon-check.svg";
import styles from "./MenuItem.module.scss";
export function MenuItem(_a) {
    var { onClick, children, externalRef, leftIcon, isSelected, isHighlighted, selectable = true, className } = _a, props = __rest(_a, ["onClick", "children", "externalRef", "leftIcon", "isSelected", "isHighlighted", "selectable", "className"]);
    return (React.createElement("button", Object.assign({ onClick: onClick, className: clsx(styles.container, isSelected && styles.selected, isHighlighted && styles.highlighted, selectable && styles.selectable, leftIcon && styles.withLeftIcon), ref: externalRef, type: "button" }, props),
        leftIcon,
        React.createElement("span", { className: clsx("body-medium", styles.title, className) }, children),
        isSelected && React.createElement(ActiveIcon, { className: styles.iconSelected })));
}
