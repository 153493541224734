import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        newRevision: ["Create Revision", "Создать ревизию"][index],
        deleted: ["Revision “$REVISION” deleted.", "Ревизия “$REVISION” удалена."][index],
        created: ["Revision “$REVISION” created.", "Ревизия “$REVISION” создана."][index],
        alreadyExist: ["Revision name has to be unique", "Имя ревизии должно быть уникальным"][index],
        minLength: ["Min 1 character", "Минимум 1 символ"][index],
        revision: ["Revision", "Ревизия"][index],
    };
};
