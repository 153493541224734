var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { Button, Modal, Select } from "@components";
import { fetchCV, saveCV, setCVPreview, showNotification } from "@store";
import { uniqueId, getDateComparator } from "@utils";
import { useAppDispatch, useAppSelector } from "@hooks";
import { fields } from "./localization";
import styles from "./CopyModal.module.scss";
export function CopyModal({ prof, isOpen, onClose }) {
    const [targetRevision, setTargetRevision] = useState(null);
    const [allowedRevisions, setAllowedRevisions] = useState([]);
    const { targetUser } = useAppSelector((state) => state.users);
    const { revisions, selectedRevision, cvPreview } = useAppSelector((state) => state.revisions);
    const { language } = useAppSelector((state) => state.ui);
    const dispatch = useAppDispatch();
    const field = fields(language);
    useEffect(() => {
        const allowed = revisions.filter((revision) => revision.name !== selectedRevision.name);
        if (allowed.length === 0) {
            setTargetRevision(null);
            setAllowedRevisions([]);
        }
        else {
            setAllowedRevisions(allowed);
            setTargetRevision(allowed[0]);
        }
    }, [revisions, selectedRevision]);
    const handleCopyToRevision = () => __awaiter(this, void 0, void 0, function* () {
        const newProfExp = Object.assign(Object.assign({}, prof), { displayId: uniqueId() });
        try {
            if (targetRevision === null || targetRevision === void 0 ? void 0 : targetRevision.isPreview) {
                const targetCV = Object.assign({}, cvPreview);
                const comparator = getDateComparator(targetCV.sortOrder);
                const professionalExperienceDisplay = [
                    ...targetCV.professionalExperienceDisplay,
                    newProfExp,
                ].sort(comparator);
                dispatch(setCVPreview(Object.assign(Object.assign({}, targetCV), { professionalExperienceDisplay })));
            }
            else {
                const targetCV = yield fetchCV(targetUser.email, targetRevision.name, false);
                const comparator = getDateComparator(targetCV.sortOrder);
                const professionalExperienceDisplay = [
                    ...targetCV.professionalExperienceDisplay,
                    newProfExp,
                ].sort(comparator);
                yield dispatch(saveCV({
                    email: targetUser.email,
                    revisionName: targetRevision.name,
                    data: Object.assign(Object.assign({}, targetCV), { professionalExperienceDisplay, imageUrl: targetUser.imageUrl }),
                    isSilent: true,
                })).unwrap();
            }
            dispatch(showNotification({ isSuccess: true, message: field.successCopyMessage }));
        }
        catch (_a) {
            dispatch(showNotification({ isSuccess: false, message: field.errorCopyMessage }));
        }
        onClose();
    });
    return (React.createElement(Modal, { open: isOpen, onRequestClose: () => {
            onClose();
        }, title: field.modalTitle, content: React.createElement("div", { className: styles.content }, allowedRevisions.length === 0 ? (React.createElement("span", null, field.noRevisions)) : (React.createElement(Select, { value: (targetRevision === null || targetRevision === void 0 ? void 0 : targetRevision.name) || "", onOptionClick: (_, option) => {
                setTargetRevision(allowedRevisions.find((revision) => revision.name === option.label));
            }, label: field.selectRevision, options: allowedRevisions.map(({ name }) => ({
                id: name,
                label: name,
            })) }))), footerContent: React.createElement(Button, { onClick: handleCopyToRevision, disabled: !targetRevision }, "Copy") }));
}
