import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { authPagePath } from "@utils";
import { useAppSelector } from "@hooks";
export function ProtectedRoute() {
    const location = useLocation();
    const { authToken } = useAppSelector((state) => state.users);
    if (!authToken) {
        return React.createElement(Navigate, { to: authPagePath.pattern, state: { from: location }, replace: true });
    }
    return React.createElement(Outlet, null);
}
