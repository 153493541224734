import React, { useEffect, useRef } from "react";
import { SectionTitle, ListField, TextAreaWithSelectionTool } from "@components";
import { CustomSectionContentType } from "@types";
import { useAppSelector } from "@hooks";
import { fields } from "./localization";
import commonStyles from "../../../../CV.common.scss";
export function CustomSection({ section, index, handleEditTitle, onValueRemove, handleBulletAdd, handleSectionHide, handleEditContent, updateText, updatePoint, }) {
    var _a;
    const { isTriedToSave } = useAppSelector((state) => state.cv);
    const { language } = useAppSelector((state) => state.ui);
    const field = fields(language);
    const focusRef = useRef(null);
    useEffect(() => {
        if (focusRef.current && section.isSelected) {
            focusRef.current.scrollIntoView({ behavior: "smooth" });
            focusRef.current.focus();
        }
    }, [section.isSelected]);
    const handleAdd = section.bodyContentType === CustomSectionContentType.BulletPoints
        ? () => handleBulletAdd(index, section.content.bulletPoints)
        : undefined;
    return (React.createElement("section", { id: section.displayId, className: commonStyles.section, ref: focusRef },
        React.createElement(SectionTitle, { handleRename: (value) => handleEditTitle(index, value), disabled: section.isHidden, title: section.header, handleSectionRemove: () => onValueRemove(index), handleAdd: handleAdd, addButtonLabel: field.addLabel, isSectionHidden: section.isHidden, handleSectionHide: () => handleSectionHide(index) }),
        section.bodyContentType === CustomSectionContentType.Text && (React.createElement(TextAreaWithSelectionTool, { wrapClassName: commonStyles.input, placeholder: "Text", rows: 6, maxLength: 1400, value: section.content.text, hasError: !section.isHidden && isTriedToSave && !((_a = section.content.text) === null || _a === void 0 ? void 0 : _a.trim()), disabled: section.isHidden, handleValueChange: (value) => handleEditContent(index, value, CustomSectionContentType.Text), handleSelectionAction: (value, start, end) => updateText(value, start, end, index), isAutoGrowing: true })),
        section.bodyContentType === CustomSectionContentType.BulletPoints && (React.createElement(ListField, { itemTitle: field.itemPlaceholder, points: section.content.bulletPoints, onEdit: (value) => handleEditContent(index, value, CustomSectionContentType.BulletPoints), readOnly: section.isHidden, showAddBtn: false, highlightEmpty: !section.isHidden && isTriedToSave, onItemSelection: (itemIndex, text, selectionStart, selectionEnd) => updatePoint(index, itemIndex, text, selectionStart, selectionEnd) }))));
}
