import { SupportedSettings } from "@types";
import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        downloadFileLError: ["File download error", "Ошибка загрузки файла"][index],
        getFileLink: ["Copied to clipboard", "Скопировано в буфер обмена"][index],
        getFileLinkError: ["Failed to get file link", "Не удалось получить ссылку на файл"][index],
        title: ["Share & Download Settings", "Настройки обмена и скачивания файлов"][index],
        template: ["Template", "Шаблон"][index],
        fileExt: ["File Extension", "Расширение файла"][index],
        lifeTimeHint: ["Link lifetime is 24 hours", "Срок действия ссылки - 24 часа"][index],
        getLink: ["Get Link", "Получить ссылку"][index],
        download: ["Download", "Скачать"][index],
    };
};
export const getSettingsDictionary = (language) => {
    const index = languageIndex(language);
    return {
        [SupportedSettings.Anonymize]: ["Anonymize", "Анонимизировать"][index],
        [SupportedSettings.HidePhoto]: ["Hide employee photo", "Скрыть фото"][index],
        [SupportedSettings.RemovePageBreak]: [
            "Remove page break after Overview",
            `Убрать перенос страницы после секции "Общие сведения"`,
        ][index],
        [SupportedSettings.HideCustomers]: ["Hide Customers", "Скрыть заказчиков"][index],
        [SupportedSettings.HideTrainings]: ["Hide Trainings", "Скрыть тренинги"][index],
        [SupportedSettings.HidePersonalStrength]: ["Hide Personal Strength", "Скрыть сильные стороны"][index],
        [SupportedSettings.RemoveAllPageBreaks]: [
            "Remove all page breaks",
            "Убрать все переносы страниц",
        ][index],
        [SupportedSettings.HideCertificates]: ["Hide Certifications", "Скрыть сертификаты"][index],
        [SupportedSettings.HideProjectNumber]: ["Hide project number", "Скрыть номер проекта"][index],
        [SupportedSettings.AnonymizeCustomers]: ["Anonymize сustomers", "Анонимизировать клиентов"][index],
    };
};
