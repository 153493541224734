import React, { useRef } from "react";
import { Button, IconButton, Overlay } from "@components";
import styles from "./DropDownWrap.module.scss";
export function DropDownWrap({ isOpen, anchor, children, toggleOpen, externalAnchorRef, excludedRefs, disabled, variant = "text", color = "primary", iconOnly, title, primaryDirection = "left", parentWidth = false, withBadge = false, }) {
    let anchorRef = useRef(null);
    if (externalAnchorRef)
        anchorRef = externalAnchorRef;
    return (React.createElement("div", { className: styles.wrap },
        iconOnly ? (React.createElement(IconButton, { onClick: toggleOpen, externalRef: anchorRef, tabIndex: 0, variant: variant === "text" ? "contained" : variant, color: color, icon: anchor, disabled: disabled, title: isOpen ? null : title, withBadge: withBadge })) : (React.createElement(Button, { onClick: toggleOpen, externalRef: anchorRef, tabIndex: 0, variant: variant, color: color, disabled: disabled, title: isOpen ? null : title }, anchor)),
        React.createElement(Overlay, { isOpen: isOpen, hideOverlay: () => isOpen && toggleOpen(), anchorRef: anchorRef, excludedRefs: excludedRefs, parentWidth: parentWidth, primaryPositionX: primaryDirection }, children)));
}
