var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { Button, Modal, Select } from "@components";
import { useAppSelector } from "@hooks";
import { fields } from "./localization";
import styles from "./DeleteModal.module.scss";
export function DeleteModal({ onClose, revision, handleRevisionDelete }) {
    const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
    const { revisions } = useAppSelector((state) => state.revisions);
    const { language } = useAppSelector((state) => state.ui);
    const filteredRevisions = revisions.filter((r) => r.name !== revision.name);
    const [defaultRevision, setDefaultRevision] = useState(filteredRevisions.length === 1 ? filteredRevisions[0] : null);
    const { modalTitle, remove, cancel, subtitle, chooseDefault, placeholder } = fields(language);
    const handleRevisionDeleteClick = () => __awaiter(this, void 0, void 0, function* () {
        setIsDeleteInProgress(true);
        yield handleRevisionDelete(revision, defaultRevision);
    });
    return (React.createElement(Modal, { open: true, onRequestClose: onClose, title: modalTitle, content: React.createElement("div", { className: styles.content },
            React.createElement("span", null, subtitle.replace("$REVISION", revision.name)),
            revision.isDefault && (React.createElement(React.Fragment, null,
                React.createElement("span", null, chooseDefault),
                React.createElement(Select, { placeholder: placeholder, value: (defaultRevision === null || defaultRevision === void 0 ? void 0 : defaultRevision.name) || "", onOptionClick: (_, option) => {
                        setDefaultRevision(revisions.find(({ name }) => name === option.label));
                    }, options: filteredRevisions.map(({ name: label }) => ({
                        label,
                        id: label,
                    })) })))), footerContent: React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: onClose, color: "tertiary" }, cancel),
            React.createElement(Button, { onClick: handleRevisionDeleteClick, disabled: revision.isDefault && !defaultRevision, isLoading: isDeleteInProgress }, remove)) }));
}
