import { useEffect, useState } from "react";
const TABLET_VIEW = "(max-width: 1024px)";
const TABLET_DUO_VIEW = "(max-width: 620px)";
const MOBILE_VIEW = "(max-width: 500px)";
export const useMediaQuery = (maxWidth) => {
    const mql = window.matchMedia(maxWidth);
    const [isMatch, setIsMatch] = useState(mql.matches);
    useEffect(() => {
        const resizeHandler = (e) => setIsMatch(e.matches);
        mql.addEventListener("change", resizeHandler);
        return () => mql.removeEventListener("change", resizeHandler);
    }, []);
    return isMatch;
};
export const useTablet = () => useMediaQuery(TABLET_VIEW);
export const useTabletDuo = () => useMediaQuery(TABLET_DUO_VIEW);
export const useMobile = () => useMediaQuery(MOBILE_VIEW);
