import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        feedback: ["Send feedback", "Оставить отзыв"][index],
        title: ["send feedback", "оставить отзыв"][index],
        inputTitle: ["Message", "Сообщение"][index],
        openButton: ["Feedback", "Отзыв"][index],
        sendButton: ["Send", "Отправить"][index],
        sendSuccess: ["Feedback successfully sent", "Отзыв успешно отправлен"][index],
        sendFailure: ["Feedback sending error", "Ошибка отправки отзыва"][index],
    };
};
