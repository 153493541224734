var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import clsx from "clsx";
import { useAppDispatch, useAppSelector, useMobile } from "@hooks";
import { Modal, TextArea, Button } from "@components";
import Logo from "@assets/logo_without_frame.svg";
import { showNotification } from "@store";
import { Api, FEEDBACK } from "@services";
import { fields } from "./localization";
import commonStyles from "@pages/CvPage/components/CV/CV.common.scss";
import styles from "./FeedbackIsland.module.scss";
export function FeedbackIsland() {
    const [isWindowOpen, setIsWindowOpen] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const { language } = useAppSelector((state) => state.ui);
    const isMobile = useMobile();
    const dispatch = useAppDispatch();
    const field = fields(language);
    const handleSend = () => __awaiter(this, void 0, void 0, function* () {
        setIsWindowOpen(false);
        setFeedbackMessage("");
        try {
            yield Api.post(FEEDBACK, { feedbackText: feedbackMessage });
            dispatch(showNotification({ message: field.sendSuccess, isSuccess: true }));
        }
        catch (_a) {
            dispatch(showNotification({ message: field.sendFailure, isSuccess: false }));
        }
    });
    return !isMobile ? (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: isWindowOpen, onRequestClose: () => setIsWindowOpen(false), title: field.feedback, content: React.createElement(TextArea, { wrapClassName: clsx(commonStyles.input, styles.messageInput), placeholder: field.inputTitle, rows: 6, maxLength: 1e6, value: feedbackMessage, onChange: ({ target: { value } }) => setFeedbackMessage(value) }), headerContent: React.createElement(Logo, { className: styles.logo }), footerContent: React.createElement(Button, { type: "submit", onClick: handleSend, disabled: feedbackMessage.length === 0 }, field.sendButton) }),
        React.createElement("button", { type: "button", className: styles.redButton, title: field.title, onClick: () => setIsWindowOpen(true) }, field.openButton))) : null;
}
