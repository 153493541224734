import { clearDate } from "@utils";
export const isValidRoleField = (payload, allowedRoles) => {
    return !allowedRoles.some((allowedRole) => allowedRole.toLowerCase() === payload.toLowerCase().trim());
};
export const isValidCustomerField = (payload, allowedCustomer) => {
    return payload.toLowerCase().trim() !== allowedCustomer.toLowerCase();
};
export const validateOnlyDates = (dates) => {
    const intersectedIndexes = [];
    const datesWithNull = dates.map((date) => ({
        startDate: date.startDate,
        endDate: date.endDate === null ? new Date().toISOString() : date.endDate,
    }));
    const clearDateProjects = datesWithNull.map((date) => ({
        startDate: clearDate(date.startDate),
        endDate: clearDate(date.endDate),
    }));
    if (clearDateProjects.length > 1) {
        clearDateProjects.forEach((project, index, allProjects) => {
            const startDate = new Date(project.startDate);
            const endDate = new Date(project.endDate);
            allProjects.forEach((innerProject, innerIndex) => {
                if (index !== innerIndex) {
                    const innerStartDate = new Date(innerProject.startDate);
                    const innerEndDate = new Date(innerProject.endDate);
                    const isLater = innerStartDate > endDate && innerEndDate > endDate;
                    const isEarlier = innerStartDate < startDate && innerEndDate < startDate;
                    if (!isEarlier &&
                        !isLater &&
                        !intersectedIndexes.includes(index) &&
                        datesWithNull[index].startDate &&
                        datesWithNull[index].endDate &&
                        datesWithNull[innerIndex].startDate &&
                        datesWithNull[innerIndex].endDate) {
                        intersectedIndexes.push(index);
                    }
                }
            });
        });
    }
    return intersectedIndexes;
};
