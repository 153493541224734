import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import Lens from "@assets/icon_search.svg";
import { filterOptions } from "@utils";
import { Button, Input } from "@components";
import { DropDownOption } from "./DropDownOption";
import styles from "./DropDownMenu.module.scss";
const HEIGHT_OF_DROPDOWN_OPTION = 40;
export function DropDown({ isOpen, multiple, dropdownClassName, triangleClassName, className, selectedOptions, children, options, clearFilter, toggleOpen, onSelect, hasSearch = true, isCloseOnScroll = false, amountOfItems = 4, isReversible = false, pixelsFromTop, }) {
    const [searchValue, setSearchValue] = useState("");
    const [isOpenFlag, setIsOpenFlag] = useState(false);
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);
    const boxRef = useRef(null);
    const [isOpensOnTop, setIsOpensOnTop] = useState(isReversible);
    const dropDownHeight = buttonRef.current
        ? amountOfItems * HEIGHT_OF_DROPDOWN_OPTION +
            buttonRef.current.clientHeight +
            (hasSearch ? HEIGHT_OF_DROPDOWN_OPTION : 0)
        : 0;
    useEffect(() => {
        if (isOpen && isReversible && buttonRef.current) {
            const topInterval = buttonRef.current.getBoundingClientRect().y - pixelsFromTop;
            setIsOpensOnTop(topInterval > dropDownHeight);
        }
        setIsOpenFlag(isOpen);
    }, [isOpen]);
    const controlDropDown = (event) => {
        if (isOpenFlag &&
            !dropdownRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)) {
            toggleOpen();
        }
    };
    useEffect(() => {
        if (!isOpenFlag) {
            setSearchValue("");
        }
        document.addEventListener("mousedown", controlDropDown);
        return () => {
            document.removeEventListener("mousedown", controlDropDown);
        };
    }, [isOpenFlag]);
    const filteredOptions = useMemo(() => filterOptions(options, searchValue), [options, searchValue]);
    const onItemSelect = (id) => {
        if (onSelect)
            onSelect(id);
        if (!multiple)
            toggleOpen();
    };
    const resultList = filteredOptions.map((e) => (React.createElement(DropDownOption, { id: e.id, icon: e.icon, name: e.name, multiple: multiple, checked: selectedOptions.includes(e.id), onChange: onItemSelect, key: e.id })));
    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };
    const handleScroll = (event) => {
        if (isCloseOnScroll &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)) {
            setIsOpenFlag(false);
        }
    };
    useEffect(() => {
        window.addEventListener("wheel", handleScroll);
        window.addEventListener("touchmove", handleScroll);
        return () => {
            window.removeEventListener("wheel", handleScroll);
            window.removeEventListener("touchmove", handleScroll);
        };
    }, []);
    return (React.createElement("div", { className: clsx(styles.wrap, className, isOpenFlag && styles.activeCategorySelect) },
        React.createElement("div", { role: "button", tabIndex: 0, className: styles.content, ref: buttonRef, onClick: toggleOpen, onKeyDown: ({ key }) => key === "Enter" && toggleOpen() }, children),
        React.createElement("div", { className: clsx(isOpenFlag ? styles.dropdown : styles.hidden, dropdownClassName), ref: boxRef, style: isOpenFlag && isOpensOnTop
                ? {
                    top: `-${dropDownHeight}px`,
                }
                : {} },
            !isOpensOnTop && React.createElement("div", { className: clsx(styles.triangle, triangleClassName) }),
            React.createElement("div", { className: styles.body, ref: dropdownRef },
                hasSearch ? (React.createElement("div", { className: styles.searchBox },
                    React.createElement(Input, { leftIcon: React.createElement(Lens, { width: "18px" }), placeholder: "Search", onChange: handleInputChange, value: searchValue }))) : (React.createElement("div", { className: styles.emptySearchBox })),
                React.createElement("div", { className: styles.contentBox, style: { height: `${amountOfItems * HEIGHT_OF_DROPDOWN_OPTION}px` } }, resultList),
                multiple ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.line }),
                    React.createElement(Button, { onClick: clearFilter, variant: "text", className: styles.resetButton }, "Clear filters"))) : (React.createElement("div", { className: styles.emptySearchBox }))),
            isOpensOnTop && React.createElement("div", { className: clsx(styles.triangleOnBottom, triangleClassName) }))));
}
