import { CustomSectionContentType } from "@types";
// TODO add tests
export const isCustomSectionEmpty = (cv) => cv.customSectionsDisplay.some(({ header, bodyContentType, content: { bulletPoints, text }, isHidden }) => {
    if (isHidden) {
        return false;
    }
    const isTextAreaSection = bodyContentType === CustomSectionContentType.Text;
    const isBulletsSection = bodyContentType === CustomSectionContentType.BulletPoints;
    const isHeaderEmpty = !header.trim();
    const isBodyEmpty = isTextAreaSection ? !(text === null || text === void 0 ? void 0 : text.trim()) : false;
    const areBulletsEmpty = isBulletsSection
        ? !!(bulletPoints === null || bulletPoints === void 0 ? void 0 : bulletPoints.some(({ value }) => !value.trim()))
        : false;
    const isContentEmpty = isBodyEmpty || areBulletsEmpty;
    return isHeaderEmpty || isContentEmpty;
});
// TODO add tests
export const isEmptyDate = (clearData) => {
    return Boolean(clearData.professionalExperienceDisplay.find((p) => p.startDate === null || p.startDate === undefined || p.endDate === undefined));
};
// TODO add tests
export const isWrongDate = (clearData) => {
    return Boolean(clearData.professionalExperienceDisplay.find((p) => p.endDate !== null && new Date(p.endDate) < new Date(p.startDate)));
};
