var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, IconButton, Modal, SegmentedButton, Select, Switch, TextField } from "@components";
import React, { useMemo, useState } from "react";
import IconLink from "@assets/icon_link.svg";
import IconDownload from "@assets/icon_download.svg";
import IconCopy from "@assets/icon_copy.svg";
import { useAppDispatch, useAppSelector, useTabletDuo } from "@hooks";
import { getFileLink, setCV, showNotification } from "@store";
import { axiosInstance, CV_DOWNLOAD_URL } from "@services";
import { downloadFileByBlob } from "@utils";
import { AnalyticsId } from "@constants";
import { SupportedSettings } from "@types";
import { fields, getSettingsDictionary } from "./localization";
import styles from "./ShareDownloadModal.module.scss";
const supportedFileExtensions = ["pdf" /* CVFileTypes.pdf */, "docx" /* CVFileTypes.docx */];
const settingsConfig = Object.values(SupportedSettings).map((k) => ({
    key: k,
    isEnabled: false,
}));
export function ShareDownloadModal({ onClose, handleSave }) {
    const { cv, isCVChanged, isFileLinkPDFLoading, isFileLinkDOCXLoading, isFileLinkDriveLoading } = useAppSelector((state) => state.cv);
    const { language } = useAppSelector((state) => state.ui);
    const { templates, selectedTemplate: template } = useAppSelector((state) => state.templates);
    const { targetUser } = useAppSelector((state) => state.users);
    const { selectedRevision } = useAppSelector((state) => state.revisions);
    const [selectedTemplate, setSelectedTemplate] = useState(template);
    const [selectedFileExtension, setSelectedFileExtension] = useState(supportedFileExtensions[0]);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [fetchedLink, setFetchedLink] = useState("");
    const isTabletDuo = useTabletDuo();
    const dispatch = useAppDispatch();
    const dictionary = fields(language);
    const settingsDictionary = getSettingsDictionary(language);
    const isGetLinkLoading = isFileLinkPDFLoading || isFileLinkDOCXLoading || isFileLinkDriveLoading;
    const getFile = (format, fileFormat) => __awaiter(this, void 0, void 0, function* () {
        const fileUrl = `${CV_DOWNLOAD_URL}?userId=${targetUser.email}&firstName=${targetUser.firstName}&lastName=${targetUser.lastName}`;
        const res = yield axiosInstance.get(`${fileUrl}&format=${format}&revisionName=${encodeURIComponent(selectedRevision.name)}&fileFormat=${fileFormat}`, {
            responseType: "blob",
        });
        const name = decodeURIComponent(res.headers["cv-filename"]);
        return { fileBlob: res.data, fileName: name };
    });
    const handleDownload = () => __awaiter(this, void 0, void 0, function* () {
        if (isDownloadLoading) {
            return;
        }
        try {
            setIsDownloadLoading(true);
            if (isCVChanged) {
                yield handleSave();
            }
            const { fileBlob, fileName } = yield getFile(selectedTemplate.formatName, selectedFileExtension);
            downloadFileByBlob(fileBlob, fileName);
        }
        catch (error) {
            dispatch(showNotification({ isSuccess: false, message: dictionary.downloadFileLError }));
        }
        finally {
            setIsDownloadLoading(false);
        }
    });
    const handleGetFileLink = () => __awaiter(this, void 0, void 0, function* () {
        if (isGetLinkLoading) {
            return;
        }
        if (isCVChanged) {
            yield handleSave();
        }
        const { email } = targetUser;
        const fileFormat = selectedFileExtension;
        const format = selectedTemplate.formatName;
        yield dispatch(getFileLink({
            email,
            fileFormat,
            format,
            revisionName: selectedRevision.name,
        }))
            .unwrap()
            .then((link) => {
            setFetchedLink(link.link);
        })
            .catch(() => {
            dispatch(showNotification({ message: dictionary.getFileLinkError, isSuccess: false }));
        });
    });
    const handleCopyFileLink = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield navigator.clipboard.writeText(fetchedLink);
            dispatch(showNotification({ isSuccess: true, message: dictionary.getFileLink }));
        }
        catch (error) {
            dispatch(showNotification({ isSuccess: false, message: dictionary.getFileLinkError }));
        }
    });
    const handleSettingSwitch = (setting) => {
        if (setting.key === SupportedSettings.Anonymize && !setting.isEnabled) {
            dispatch(setCV(Object.assign(Object.assign({}, cv), { [SupportedSettings.Anonymize]: true, [SupportedSettings.HidePhoto]: true })));
        }
        else if (setting.key === SupportedSettings.HidePhoto && setting.isEnabled) {
            dispatch(setCV(Object.assign(Object.assign({}, cv), { [SupportedSettings.Anonymize]: false, [SupportedSettings.HidePhoto]: false })));
        }
        else if (setting.key === SupportedSettings.RemoveAllPageBreaks && !setting.isEnabled) {
            dispatch(setCV(Object.assign(Object.assign({}, cv), { [SupportedSettings.RemoveAllPageBreaks]: true, [SupportedSettings.RemovePageBreak]: true })));
        }
        else if (setting.key === SupportedSettings.RemovePageBreak && setting.isEnabled) {
            dispatch(setCV(Object.assign(Object.assign({}, cv), { [SupportedSettings.RemoveAllPageBreaks]: false, [SupportedSettings.RemovePageBreak]: false })));
        }
        else {
            dispatch(setCV(Object.assign(Object.assign({}, cv), { [setting.key]: !setting.isEnabled })));
        }
    };
    const translatedOptions = useMemo(() => settingsConfig.map(({ key }) => ({
        key,
        label: settingsDictionary[key],
    })), [language]);
    const settingsOptions = translatedOptions.map((setting) => (Object.assign(Object.assign({}, setting), { isEnabled: !!(cv === null || cv === void 0 ? void 0 : cv[setting.key]) })));
    const templateOptions = templates.map(({ formatName, displayName, language: lang }) => ({
        text: `${displayName} [${lang}]`,
        isActive: formatName === (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.formatName),
    }));
    const fileExtensionOptions = supportedFileExtensions.map((ext) => ({
        text: `.${ext}`,
        isActive: selectedFileExtension === ext,
    }));
    return (React.createElement(Modal, { onRequestClose: onClose, open: true, size: "medium", title: dictionary.title, content: React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.top },
                isTabletDuo || templateOptions.length > 3 ? (React.createElement("div", { className: styles.selectContainer },
                    React.createElement(Select, { value: `${selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.displayName} [${selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.language}]`, options: templateOptions.map(({ isActive, text }) => ({
                            id: text,
                            label: text,
                            isSelected: isActive,
                        })), onOptionClick: (index) => {
                            setSelectedTemplate(templates[index]);
                        }, isSelectable: true, label: dictionary.template }))) : (React.createElement(SegmentedButton, { options: templateOptions, onButtonClick: (index) => {
                        setSelectedTemplate(templates[index]);
                    }, label: dictionary.template })),
                React.createElement(SegmentedButton, { options: fileExtensionOptions, onButtonClick: (index) => {
                        setSelectedFileExtension(supportedFileExtensions[index]);
                    }, label: dictionary.fileExt })),
            React.createElement("div", { className: styles.settings }, settingsOptions.map((setting) => (React.createElement(Switch, { onSwitch: () => handleSettingSwitch(setting), isChecked: setting.isEnabled, text: setting.label, key: setting.key }))))), footerSupportingText: dictionary.lifeTimeHint, footerContent: React.createElement(React.Fragment, null,
            React.createElement(TextField, { value: fetchedLink, readOnly: true }),
            React.createElement(IconButton, { icon: React.createElement(IconCopy, null), disabled: fetchedLink === "", onClick: handleCopyFileLink }),
            React.createElement(Button, { onClick: handleGetFileLink, isLoading: isGetLinkLoading, color: "secondary", leftIcon: React.createElement(IconLink, null), id: AnalyticsId.getLinkButton }, dictionary.getLink),
            React.createElement(Button, { onClick: handleDownload, isLoading: isDownloadLoading, color: "secondary", leftIcon: React.createElement(IconDownload, null), id: AnalyticsId.downloadButton }, dictionary.download)) }));
}
