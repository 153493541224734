var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import clsx from "clsx";
import React, { useState } from "react";
import { uniqueId } from "@utils";
import styles from "./Input.module.scss";
export function Input(_a) {
    var { hasError, className, type = "text", leftIcon, leftIconClassName, rightIcon, rightIconClassName, innerRef, onClick, onChange, autocompleteList, disabled, containerRef, onSelect, onBlur, onMouseDown, assistButton } = _a, props = __rest(_a, ["hasError", "className", "type", "leftIcon", "leftIconClassName", "rightIcon", "rightIconClassName", "innerRef", "onClick", "onChange", "autocompleteList", "disabled", "containerRef", "onSelect", "onBlur", "onMouseDown", "assistButton"]);
    const [id] = useState(uniqueId());
    return (React.createElement("div", { className: clsx(styles.wrap, hasError && styles.error, disabled && styles.disabled), ref: containerRef || null },
        leftIcon && React.createElement("div", { className: clsx(leftIconClassName, styles.leftIcon) }, leftIcon),
        React.createElement("input", Object.assign({ ref: innerRef }, props, { type: type, className: clsx(styles.input, className), onClick: onClick, onChange: onChange, spellCheck: true, list: id, disabled: disabled, onSelect: onSelect, onBlur: onBlur, onMouseDown: onMouseDown })),
        autocompleteList && (React.createElement("datalist", { id: id }, autocompleteList.map((s) => (React.createElement("option", { value: s, key: s }))))),
        rightIcon && React.createElement("div", { className: clsx(rightIconClassName, styles.rightIcon) }, rightIcon),
        assistButton));
}
