import { configureStore } from "@reduxjs/toolkit";
import { isDevelopmentMode } from "@constants";
import usersReducer from "./users/usersSlice";
import cvReducer from "./cv/cvSlice";
import revisionsReducer from "./revisions/revisionsSlice";
import templatesReducer from "./templates/templatesSlice";
import uiReducer from "./ui/uiSlice";
export const store = configureStore({
    reducer: {
        cv: cvReducer,
        users: usersReducer,
        revisions: revisionsReducer,
        templates: templatesReducer,
        ui: uiReducer,
    },
    devTools: isDevelopmentMode,
});
