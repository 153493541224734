var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { SectionTitle, ListField } from "@components";
import { useAppDispatch, useAppSelector } from "@hooks";
import { setCV, translateSubstring } from "@store";
import { createDisplayPoint } from "@utils";
import { HeadingIds } from "@constants";
import { fields } from "./localization";
import commonStyles from "../../CV.common.scss";
export function Trainings() {
    var _a;
    const dispatch = useAppDispatch();
    const { cv } = useAppSelector((state) => state.cv);
    const { language } = useAppSelector((state) => state.ui);
    const { title, placeholder, addLabel } = fields(language);
    const trainings = ((_a = cv === null || cv === void 0 ? void 0 : cv.trainingsDisplay) === null || _a === void 0 ? void 0 : _a.length) ? cv.trainingsDisplay : [createDisplayPoint()];
    const hideTrainings = (cv === null || cv === void 0 ? void 0 : cv.hideTrainings) || false;
    const setValue = (trainingsDisplay) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { trainingsDisplay })));
    };
    const handleAdd = () => {
        if (trainings.length === 0) {
            setValue([createDisplayPoint(), createDisplayPoint()]);
        }
        else {
            setValue([...trainings, createDisplayPoint()]);
        }
    };
    const handleItemTranslation = (itemIndex, text, start, end) => __awaiter(this, void 0, void 0, function* () {
        const translatedResult = yield dispatch(translateSubstring({ text, start, end })).unwrap();
        setValue(trainings.map((point, i) => {
            if (i === itemIndex) {
                return Object.assign(Object.assign({}, point), { value: translatedResult });
            }
            return point;
        }));
    });
    return (React.createElement("section", { id: HeadingIds.Trainings, className: commonStyles.section },
        React.createElement(SectionTitle, { title: title, handleAdd: handleAdd, isSectionHidden: hideTrainings, addButtonLabel: addLabel, handleSectionHide: () => {
                dispatch(setCV(Object.assign(Object.assign({}, cv), { hideTrainings: !hideTrainings })));
            } }),
        React.createElement(ListField, { title: title, itemTitle: placeholder, points: trainings, onEdit: setValue, readOnly: hideTrainings, showAddBtn: false, onItemSelection: handleItemTranslation })));
}
