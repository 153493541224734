var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Api, AssistService, CV_DOWNLOAD_URL, CV_URL, DRIVE_LINK_URL, FILE_LINK_URL, } from "@services";
import { clearArrays, convertToCVDTO, fetchCV } from "@store/cv/utils";
import { showNotification } from "@store/ui";
import { store } from "@store";
import { dictionary } from "@constants";
import { AssistToolError, clearUnicodeLiterals, translateCustomSectionsHeaders } from "@utils";
export const getCV = createAsyncThunk("cv/getCV", (_a, thunkApi_1) => __awaiter(void 0, [_a, thunkApi_1], void 0, function* ({ email, revisionName }, thunkApi) {
    try {
        return fetchCV(email, revisionName, true);
    }
    catch (_b) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const saveCV = createAsyncThunk("cv/saveCV", (_c, thunkApi_2) => __awaiter(void 0, [_c, thunkApi_2], void 0, function* ({ email, revisionName, data, isSilent = false, isCurrent = false, }, thunkApi) {
    try {
        const clearedArrays = convertToCVDTO(clearArrays(data));
        // Force hide work exp.
        clearedArrays.hideWorkExperience = true;
        yield Api.post(`${CV_URL}?userId=${email}&revisionName=${revisionName}`, clearUnicodeLiterals(clearedArrays));
        return { isSilent, isCurrent, data };
    }
    catch (_d) {
        return thunkApi.rejectWithValue("something went wrong with saving CV");
    }
}));
export const getFileLink = createAsyncThunk("cv/getFileLink", (_e, thunkApi_3) => __awaiter(void 0, [_e, thunkApi_3], void 0, function* ({ email, fileFormat, format, revisionName, }, thunkApi) {
    try {
        const linkUrl = fileFormat === "drive" /* CVFileTypes.drive */ ? DRIVE_LINK_URL : FILE_LINK_URL;
        const downloadFormat = fileFormat === "drive" /* CVFileTypes.drive */ ? "pdf" /* CVFileTypes.pdf */ : fileFormat;
        const { data: response } = yield Api.get(`${CV_DOWNLOAD_URL}${linkUrl}/${downloadFormat}?userId=${email}&format=${format}&revisionName=${revisionName}`);
        return response;
    }
    catch (_f) {
        return thunkApi.rejectWithValue("something went wrong with getting file link");
    }
}));
export const translateSubstring = createAsyncThunk("cv/translateSubstring", (_g, thunkApi_4) => __awaiter(void 0, [_g, thunkApi_4], void 0, function* ({ text, start, end }, thunkApi) {
    const { language } = thunkApi.getState().ui;
    const { translateSuccess, translateError } = dictionary(language);
    try {
        const translation = yield AssistService.translateSubstring(text, start, end);
        thunkApi.dispatch(showNotification({ message: translateSuccess, isSuccess: true }));
        return translation;
    }
    catch (_h) {
        thunkApi.dispatch(showNotification({ message: translateError, isSuccess: false }));
        return thunkApi.rejectWithValue("Something went wrong with translation");
    }
}));
export const translateHeaders = createAsyncThunk("revisions/translateHeaders", (_j, thunkApi_5) => __awaiter(void 0, [_j, thunkApi_5], void 0, function* ({ email, revisionName, from, to, }, thunkApi) {
    try {
        const { cv } = store.getState().cv;
        const translatedSection = yield translateCustomSectionsHeaders(email, revisionName, from, to);
        const translatedCustomSections = translatedSection.map((s, index) => {
            return Object.assign(Object.assign({}, cv.customSectionsDisplay[index]), { header: s.header });
        });
        return Object.assign(Object.assign({}, cv), { customSectionsDisplay: translatedCustomSections });
    }
    catch (_k) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const beautifyString = createAsyncThunk("cv/beautifyString", (_l, thunkApi_6) => __awaiter(void 0, [_l, thunkApi_6], void 0, function* ({ text }, thunkApi) {
    const { language } = thunkApi.getState().ui;
    const { beautifySuccess, beautifyError, beautifyContentError } = dictionary(language);
    try {
        const translation = yield AssistService.beautifyString(text);
        thunkApi.dispatch(showNotification({ message: beautifySuccess, isSuccess: true }));
        return translation;
    }
    catch (e) {
        if (e instanceof AssistToolError) {
            thunkApi.dispatch(showNotification({ message: beautifyContentError, isSuccess: false }));
            return thunkApi.rejectWithValue(e.message);
        }
        thunkApi.dispatch(showNotification({ message: beautifyError, isSuccess: false }));
        return thunkApi.rejectWithValue("Something went wrong with beautification");
    }
}));
export const setIsTriedToSave = createAction("cv/setIsTriedToSave");
export const setCV = createAction("cv/setCV");
