var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BEAUTIFY_TOOL, TRANSLATE_TOOL, Api } from "@services";
import { HttpStatusCode } from "axios";
import { AssistToolError, assistToolErrorMessages } from "@utils";
export class AssistService {
    static translateString(value) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!value.trim()) {
                return "";
            }
            const request = yield Api.postBody(TRANSLATE_TOOL, value);
            return request.data;
        });
    }
    static beautifyString(value) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!value.trim()) {
                return "";
            }
            const request = yield Api.postBody(BEAUTIFY_TOOL, value);
            if (request.status === HttpStatusCode.NoContent) {
                throw new AssistToolError(assistToolErrorMessages.notEnoughContext);
            }
            return request.data;
        });
    }
    static translateSubstring(text, start, end) {
        return __awaiter(this, void 0, void 0, function* () {
            const valueToTranslate = text.substring(start, end);
            const translatedValue = yield AssistService.translateString(valueToTranslate);
            return `${text.substring(0, start)}${translatedValue}${text.substring(end)}`;
        });
    }
}
