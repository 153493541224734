import { Language } from "@types";
import { languageIndex } from "@utils";
export const convertTime = (milliseconds, interfaceLanguage = Language.EN) => {
    const index = languageIndex(interfaceLanguage);
    const field = {
        lessThenHour: ["in less than 1 hour", "меньше чем через 1 час"][index],
        hour: ["in 1 hour", "в течение 1 часа"][index],
        hours: (num) => [`in ${num} hours`, `через ${num} часов`][index],
    };
    const hours = Math.floor(milliseconds / 3600000);
    if (hours < 1)
        return field.lessThenHour;
    if (hours === 1)
        return field.hour;
    if (hours > 1)
        return field.hours(hours);
};
export const clearDate = (date) => {
    if (date === null || date === undefined) {
        return date;
    }
    const clearedDate = new Date(date);
    clearedDate.setHours(0, 0, 0, 0);
    clearedDate.setDate(2);
    return clearedDate.toISOString();
};
