import React from "react";
import clsx from "clsx";
import styles from "./Switch.module.scss";
export function Switch({ onSwitch, isChecked, disabled, text, className }) {
    return (React.createElement("span", { className: clsx(className, styles.switchBox) },
        React.createElement("label", { className: styles.switch },
            React.createElement("input", { type: "checkbox", checked: isChecked, disabled: disabled, onChange: onSwitch, onKeyDown: ({ key }) => key === "Enter" && onSwitch() }),
            React.createElement("span", { className: styles.slider })),
        React.createElement("span", { className: "body-medium" }, text)));
}
