var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef, useState } from "react";
import clsx from "clsx";
import SpinnerIcon from "@assets/icon-spinner.svg";
import { Tooltip } from "@components";
import styles from "./IconButton.module.scss";
export function IconButton(_a) {
    var { type = "button", variant = "contained", color = "primary", disabled = false, isLoading, className, icon, onClick, externalRef, title, iconCustomColor = false, theme = "light", withBadge = false, elevation, size = "large" } = _a, props = __rest(_a, ["type", "variant", "color", "disabled", "isLoading", "className", "icon", "onClick", "externalRef", "title", "iconCustomColor", "theme", "withBadge", "elevation", "size"]);
    const fallbackRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    /* eslint-disable react/button-has-type */
    return (React.createElement(React.Fragment, null,
        React.createElement("button", Object.assign({ onPointerEnter: () => {
                setIsHovered(true);
            }, onPointerLeave: () => {
                setIsHovered(false);
            }, type: type, className: clsx(styles.button, styles[color], styles[variant], styles[theme], styles[size], iconCustomColor && styles.customColor, withBadge && styles.withBadge, elevation && styles[`elevation${elevation}`], className), onClick: onClick, disabled: disabled, ref: externalRef || fallbackRef, tabIndex: 0 }, props), isLoading ? React.createElement(SpinnerIcon, { className: styles.loaderIcon }) : icon),
        !!title && isHovered && React.createElement(Tooltip, { anchorRef: externalRef || fallbackRef }, title)));
}
