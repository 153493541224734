import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        downloadCvFilePDF: ["Download CV file in PDF format", "Загрузить CV file в формате PDF"][index],
        downloadCvFileDOCX: ["Download CV file in DOCX format", "Загрузить CV file в формате DOCX"][index],
        selectDownloadFormat: ["Select format to download CV", "Выберите формат чтобы скачать CV"][index],
        download: ["Download CV file", "Скачать CV"][index],
        downloadDisabled: ["Download disabled", "Скачивание заблокировано"][index],
    };
};
