/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import clsx from "clsx";
import IconTrash from "@assets/icon_trash.svg";
import { InputWithSelectionTool, TextAreaWithSelectionTool, IconButton } from "@components";
import { useAppSelector } from "@hooks";
import { fields } from "./localization";
import styles from "./ExpandableInputContainer.module.scss";
import commonStyles from "../../CV.common.scss";
// TODO join placeholder and warning props to single language.
export function ExpandableInputContainer({ values, onEdit, onDelete, russianPlaceholder, englishPlaceholder, inputType = "input", russianWarning, englishWarning, isValidFunction, disabled, maxLength, maxBulletPointsNumber, hasError = false, highlightEmpty = false, onEnterKey, onItemSelection, selectionActionPreset, }) {
    const [shouldBeFocused, setShouldBeFocused] = useState();
    const { language, isViewOnly } = useAppSelector((state) => state.ui);
    const field = fields(language, englishPlaceholder, russianPlaceholder, englishWarning, russianWarning);
    const guaranteedValue = values.length === 0 ? [""] : values;
    const onKeyDown = (index, event) => {
        if (event.key === "Enter") {
            onEnterKey(index);
            setShouldBeFocused(index + 1);
        }
    };
    return (React.createElement("section", null, guaranteedValue &&
        guaranteedValue.map((str, index) => {
            const isRedundant = maxBulletPointsNumber && index + 1 > maxBulletPointsNumber;
            return (React.createElement("div", { className: russianWarning || englishWarning ? styles.tinyField : styles.field, key: index },
                React.createElement("div", { className: styles.line },
                    inputType === "input" && (React.createElement(InputWithSelectionTool, { placeholder: field.placeholder, maxLength: maxLength || 100, readOnly: isViewOnly, disabled: disabled || isRedundant, value: str, onKeyDown: (e) => onKeyDown(index, e), innerRef: (input) => {
                            if (input && shouldBeFocused === index) {
                                input.focus();
                                setShouldBeFocused(null);
                            }
                        }, hasError: hasError || (highlightEmpty && !(str === null || str === void 0 ? void 0 : str.trim())), handleValueChange: (value) => onEdit(index, value), handleSelectionAction: (text, start, end) => onItemSelection(index, text, start, end), isSelectionFeatureEnabled: !!onItemSelection })),
                    inputType === "textarea" && (React.createElement(TextAreaWithSelectionTool, { wrapClassName: commonStyles.input, placeholder: field.placeholder, rows: 6, maxLength: maxLength || 300, readOnly: isViewOnly, disabled: disabled || isRedundant, value: str, hasError: hasError || (highlightEmpty && !(str === null || str === void 0 ? void 0 : str.trim())), handleValueChange: (value) => onEdit(index, value), preset: selectionActionPreset, handleSelectionAction: (text, start, end) => onItemSelection(index, text, start, end), isSelectionFeatureEnabled: !!onItemSelection })),
                    React.createElement(IconButton, { onClick: () => onDelete(index), icon: React.createElement(IconTrash, null), color: "secondary", disabled: isViewOnly || disabled || isRedundant })),
                (russianWarning || englishWarning) && (React.createElement("div", { className: clsx(commonStyles.warningMessage, !isValidFunction(str) && commonStyles.warningMessageVisible) }, field.warning))));
        })));
}
