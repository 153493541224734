import { enUS, ru } from "date-fns/locale";
import { Language } from "@types";
import { fields } from "@pages/CvPage/components/Navigation/localization";
import { HeadingIds, IconMap } from "@constants";
import { formatEndDate, formatStartDate } from "./date";
const mapProfessionalExperienceToMenuItems = (exps, language, isShortFormat) => {
    const locale = language === Language.EN ? enUS : ru;
    const dateFormatOptions = isShortFormat ? "MM/yy" : "yyyy MMM";
    const field = fields(language);
    return exps.map(({ startDate, endDate, isPresent, displayId }) => {
        const formattedStartDate = formatStartDate(startDate, field.startDate, dateFormatOptions, locale);
        const formattedEndDate = isPresent
            ? field.present
            : formatEndDate(endDate, field.endDate, isPresent, dateFormatOptions, locale);
        return {
            id: displayId,
            title: `${formattedStartDate} - ${formattedEndDate}`,
            isSubmenu: true,
        };
    });
};
const mapCustomSectionsToMenuItems = (sections) => sections.map(({ displayId, header, bodyContentType }) => ({
    title: header,
    id: displayId,
    icon: IconMap.get(bodyContentType),
}));
const getSubmenu = (id, profExp, language, isShortDateFormat) => {
    if (id === HeadingIds.ProfessionalExperience) {
        return mapProfessionalExperienceToMenuItems(profExp, language, isShortDateFormat);
    }
};
const buildStaticMenu = (staticHeadings, dictionary, profExp, language, isShortDateFormat) => staticHeadings.map((id) => ({
    id,
    icon: IconMap.get(id),
    title: dictionary[id],
    submenu: getSubmenu(id, profExp, language, isShortDateFormat),
}));
export const buildMenu = (staticHeadings, customSections, showCustomSection, dictionary, profExp, language, isShortDateFormat) => {
    return [
        ...buildStaticMenu(staticHeadings, dictionary, profExp, language, isShortDateFormat),
        ...(showCustomSection ? [] : mapCustomSectionsToMenuItems(customSections)),
    ].reduce((acc, item) => [...acc, item, ...(item.submenu ? item.submenu : [])], []);
};
