var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, usePrevious, useAsyncAction } from "@hooks";
import { copyElementStyle } from "@utils";
import { AssistButton } from "../AssistButton";
import styles from "./SelectionToolProvider.module.scss";
const offset = {
    containerX: 20,
    containerY: 5,
    elementX: 5,
    textAreaY: 18,
    inputY: 20,
};
const getCursorXY = (inputElement, selectionPoint) => {
    const { offsetLeft: inputX, offsetTop: inputY } = inputElement;
    const isTextArea = inputElement.tagName === "TEXTAREA";
    const tempContainer = document.createElement("div");
    const tempCursorReplacement = document.createElement("span");
    copyElementStyle(inputElement, tempContainer);
    const { top, left, bottom, right, height } = inputElement.getBoundingClientRect();
    const scrollLeft = inputElement.scrollLeft || 0;
    const scrollTop = inputElement.scrollTop || 0;
    tempContainer.style.top = `${top}px`;
    tempContainer.style.left = `${left}px`;
    tempContainer.style.position = "fixed";
    tempContainer.style.overflow = "auto";
    tempContainer.style.maxHeight = `${height}px`;
    const containerLimitY = bottom - top - offset.containerY;
    const containerLimitX = right - left - offset.containerX;
    const swap = ".";
    const inputValue = isTextArea ? inputElement.value : inputElement.value.replace(/ /g, swap);
    tempContainer.textContent = inputValue.substring(0, selectionPoint);
    if (isTextArea) {
        tempContainer.style.height = "auto";
    }
    else {
        tempContainer.style.width = "auto";
    }
    tempContainer.appendChild(tempCursorReplacement);
    tempContainer.appendChild(document.createTextNode(inputValue.substring(selectionPoint)));
    document.body.appendChild(tempContainer);
    const { offsetLeft: cursorX, offsetTop: cursorY } = tempCursorReplacement;
    document.body.removeChild(tempContainer);
    const yOffset = isTextArea ? offset.textAreaY : offset.inputY;
    const xOffset = offset.elementX;
    const x = Math.min(inputX + cursorX - scrollLeft, containerLimitX) + xOffset;
    const y = Math.min(inputY + cursorY - scrollTop, containerLimitY) + yOffset;
    return { x, y };
};
export function SelectionToolProvider({ children, type, value, icon, tooltip, handleValueChange, handleSelectionAction, handleSelectionActionError, preset = "translate", disabled, isSelectionFeatureEnabled = true, }) {
    const { featureFlags } = useAppSelector((state) => state.ui);
    const isFeatureEnabled = isSelectionFeatureEnabled && (featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.enableInlineTranslationAndBeautification);
    const [buttonPosition, setButtonPosition] = useState(null);
    const [selectionRange, setSelectionRange] = useState(null);
    const prevValue = usePrevious(value);
    const [performAction, isActionInProgress] = useAsyncAction((val, start, end) => handleSelectionAction(val, start, end), handleSelectionActionError);
    const containerRef = useRef(null);
    const buttonRef = useRef(null);
    useEffect(() => {
        if (value !== prevValue) {
            setButtonPosition(null);
        }
    }, [isActionInProgress, value, prevValue]);
    const handleSelection = (event) => {
        if (disabled || !isFeatureEnabled) {
            return;
        }
        setSelectionRange(null);
        if (event.target instanceof (type === "textarea" ? HTMLTextAreaElement : HTMLInputElement)) {
            const { selectionStart, selectionEnd } = event.target;
            if (selectionStart !== selectionEnd) {
                setButtonPosition(getCursorXY(event.target, selectionEnd));
                setSelectionRange({ selectionStart, selectionEnd });
            }
            else {
                setButtonPosition(null);
                setSelectionRange(null);
            }
        }
    };
    const clearSelection = () => {
        setButtonPosition(null);
        setSelectionRange(null);
    };
    const handleSelectionClear = (event) => {
        if (!(event.relatedTarget && event.relatedTarget === (buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current))) {
            clearSelection();
        }
    };
    const handleAssistToolClick = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (isActionInProgress) {
            return;
        }
        if (!selectionRange || selectionRange.selectionStart === selectionRange.selectionEnd) {
            setButtonPosition(null);
            return;
        }
        const input = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(type);
        if (input) {
            input === null || input === void 0 ? void 0 : input.focus();
            yield performAction(value, selectionRange.selectionStart, selectionRange.selectionEnd);
        }
    });
    return (React.createElement(React.Fragment, null, children({
        onSelect: handleSelection,
        onBlur: handleSelectionClear,
        onMouseDown: clearSelection,
        handleValueChange,
        containerRef,
        value,
        assistButton: buttonPosition ? (React.createElement(AssistButton, { isActionInProgress: isActionInProgress, performAction: handleAssistToolClick, className: styles.assistButton, tooltip: tooltip, icon: icon, externalRef: buttonRef, preset: preset, style: {
                top: `${buttonPosition.y}px`,
                left: `${buttonPosition.x}px`,
                position: "absolute",
            } })) : null,
    })));
}
