import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useAppDispatch } from "@hooks";
import { hideNotification } from "@store";
import { Button, IconButton } from "@components";
import IconCross from "@assets/icon_cross_small.svg";
import { safeLocalStorageSet } from "@utils";
import styles from "./Notification.module.scss";
export const CLOSED = "closed";
const DEFAULT_TIMEOUT = 5000;
const HIDE_ANIMATION_TIMEOUT = 300;
export function Notification({ id, message, isSuccess, timeout, localStorageKey, action, showCloseButton = true, }) {
    const dispatch = useAppDispatch();
    const [isHide, setIsHide] = useState(false);
    const handleClose = () => {
        dispatch(hideNotification(id));
    };
    const handleManualClose = () => {
        if (localStorageKey) {
            safeLocalStorageSet(localStorageKey, CLOSED);
        }
        handleClose();
    };
    useEffect(() => {
        let animationTimer;
        const handleHide = () => {
            setIsHide(true);
            animationTimer = setTimeout(handleClose, HIDE_ANIMATION_TIMEOUT);
        };
        const hideTimer = setTimeout(handleHide, timeout || DEFAULT_TIMEOUT);
        return () => {
            clearTimeout(hideTimer);
            clearTimeout(animationTimer);
        };
    }, [timeout]);
    return (React.createElement("div", { className: clsx(styles.container, isSuccess ? styles.message : styles.error, isHide && styles.hide) },
        React.createElement("p", { className: clsx(styles.text, "body-medium") }, message),
        action && (React.createElement(React.Fragment, null,
            action.type === "redirect" && (React.createElement("a", { className: styles.action, href: action.url, target: "_blank", rel: "noreferrer" }, action.label)),
            action.type === "callback" && (React.createElement(Button, { onClick: action.callback, theme: isSuccess ? "dark" : "light", color: "tertiary" }, action.label)))),
        showCloseButton && (React.createElement(IconButton, { icon: React.createElement(IconCross, null), theme: isSuccess ? "dark" : "light", variant: "outlined", color: "secondary", onClick: handleManualClose }))));
}
