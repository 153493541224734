import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        Anonymize: ["Anonymize", "Анонимизировать"][index],
        photo: ["Show user photo", "Показать фотографию пользователя"][index],
        customers: ["Show customers", "Показать клиентов"][index],
        strengths: ["Show personal strengths", "Показать сильные стороны"][index],
        trainings: ["Show trainings", "Показать тренинги"][index],
        datesIntersection: [
            "Show dates intersection warning",
            "Показать предупреждение о пересечении дат",
        ][index],
        RemovePageBreakAfterOverview: [
            "Remove page break after 'Overview'",
            "Удалить разрыв страницы после 'Общие сведения'",
        ][index],
    };
};
