import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { SortOrder } from "@types";
import { SectionTitle, SortSelector } from "@components";
import { setCV, setIsDatesWarningHidden, setIsTriedToSave, showNotification, } from "@store";
import { getDateComparator } from "@utils/date";
import { HeadingIds } from "@constants";
import { useAppDispatch, useAppSelector } from "@hooks";
import { LocalStorageKeys, safeLocalStorageGet, uniqueId } from "@utils";
import { validateOnlyDates } from "./validators";
import { CopyModal, EMPTY_PROF_EXP, ProfessionalExperienceItem } from "./components";
import { fields, getSortOptionsTranslation } from "./localization";
import commonStyles from "../../CV.common.scss";
import styles from "./ProfessionalExperience.module.scss";
const sortOptions = [
    SortOrder.Custom,
    SortOrder.StartDescending,
    SortOrder.StartAscending,
    SortOrder.EndDescending,
    SortOrder.EndAscending,
];
const clearedCopy = (array) => {
    return array.map((prof) => (Object.assign(Object.assign({}, prof), { isSelected: false })));
};
export function ProfessionalExperience() {
    const [dateWarningIndexes, setDateWarningIndexes] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProfExp, setSelectedProfExp] = useState(null);
    const { language, isViewOnly, featureFlags } = useAppSelector((state) => state.ui);
    const { cv } = useAppSelector((state) => state.cv);
    const profExp = (cv === null || cv === void 0 ? void 0 : cv.professionalExperienceDisplay) || [];
    const selectedSortOption = (cv === null || cv === void 0 ? void 0 : cv.sortOrder) || SortOrder.Custom;
    const dispatch = useAppDispatch();
    useEffect(() => {
        setDateWarningIndexes(validateOnlyDates(profExp.map((prof) => (Object.assign({}, prof)))));
    }, [profExp]);
    useEffect(() => {
        dispatch(setIsDatesWarningHidden(safeLocalStorageGet(LocalStorageKeys.DATES_INTERSECTION_WARNING) === "true"));
    }, []);
    const field = fields(language);
    const sortOptionsTranslation = getSortOptionsTranslation(language);
    const handleProfExpEdit = (professionalExperienceDisplay) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { professionalExperienceDisplay })));
    };
    const handleSortOrderSelect = (sortOrder) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { sortOrder })));
    };
    const handleListChange = (professionalExperienceDisplay, sortOrder) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { professionalExperienceDisplay, sortOrder })));
    };
    // TODO add tests to this function
    const handleSort = () => {
        const comparator = getDateComparator(selectedSortOption);
        handleProfExpEdit([...profExp].sort(comparator));
    };
    useEffect(() => {
        if (selectedSortOption !== SortOrder.Custom) {
            handleSort();
        }
    }, [selectedSortOption]);
    const move = (id, direction) => {
        const index = profExp.findIndex((prof) => prof.displayId === id);
        const offset = direction === "up" ? -1 : 1;
        if ((direction === "up" && index > 0) || (direction === "down" && index < profExp.length - 1)) {
            const copy = clearedCopy(profExp);
            const selected = Object.assign(Object.assign({}, copy[index]), { isSelected: true });
            [copy[index], copy[index + offset]] = [copy[index + offset], selected];
            handleListChange(copy, SortOrder.Custom);
        }
    };
    const handleProfExpAdd = () => {
        handleListChange([Object.assign(Object.assign({}, EMPTY_PROF_EXP), { displayId: uniqueId(), isSelected: true }), ...clearedCopy(profExp)], SortOrder.Custom);
    };
    const handleEdit = (index, fieldName, payload) => {
        const newProf = [...profExp];
        newProf[index] = Object.assign(Object.assign({}, profExp[index]), { [fieldName]: payload });
        const shouldResetSortOrder = fieldName === "startDate" || fieldName === "endDate";
        handleListChange(newProf, shouldResetSortOrder ? SortOrder.Custom : selectedSortOption);
    };
    const handleEditArray = (index, updates) => {
        const newProf = [...profExp];
        updates.forEach((update) => {
            newProf[index] = Object.assign(Object.assign({}, newProf[index]), { [update.field]: update.payload });
        });
        handleListChange(newProf, SortOrder.Custom);
    };
    const handleDelete = (index) => {
        if (profExp.length < 2)
            return;
        const newValue = [...profExp];
        newValue.splice(index, 1);
        handleProfExpEdit(newValue);
    };
    const handleCopy = (index) => {
        dispatch(showNotification({ message: field.projectCopied, isSuccess: true }));
        const prof = Object.assign({}, profExp[index]);
        prof.responsibilitiesDisplay = prof.responsibilitiesDisplay.map((r) => (Object.assign(Object.assign({}, r), { displayId: uniqueId() })));
        const newProfExp = Object.assign(Object.assign({}, prof), { displayId: uniqueId(), isSelected: true, isPresent: true, startDate: null, endDate: null });
        handleListChange([newProfExp, ...clearedCopy(profExp)], SortOrder.Custom);
    };
    const handleCopyToRevision = (prof) => {
        if (prof.startDate === null || prof.startDate === undefined || prof.endDate === undefined) {
            dispatch(setIsTriedToSave(true));
            dispatch(showNotification({ message: field.emptyDate, isSuccess: false }));
        }
        else if (prof.endDate !== null && new Date(prof.endDate) < new Date(prof.startDate)) {
            dispatch(setIsTriedToSave(true));
            dispatch(showNotification({ message: field.wrongDate, isSuccess: false }));
        }
        else {
            dispatch(setIsTriedToSave(false));
            setSelectedProfExp(prof);
            setIsModalOpen(true);
        }
    };
    const sortSelector = !!(featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.showProfessionalExperienceSortControls) && (React.createElement(SortSelector, { options: sortOptions.map((displayId) => (Object.assign({ displayId }, sortOptionsTranslation[displayId]))), selectedOption: selectedSortOption, onOptionSelect: (option) => handleSortOrderSelect(option), showIcon: true, label: field.sort }));
    return (React.createElement("section", { className: clsx(commonStyles.section, styles.profExpSection), id: HeadingIds.ProfessionalExperience },
        React.createElement(CopyModal, { isOpen: isModalOpen, prof: selectedProfExp, onClose: () => {
                setIsModalOpen(false);
            } }),
        React.createElement(SectionTitle, { title: field.title, handleAdd: handleProfExpAdd, addButtonVariant: "button", addButtonLabel: field.add, additionalComponent: sortSelector }),
        profExp.map((prof, index, array) => (React.createElement(ProfessionalExperienceItem, { prof: prof, index: index, dateWarningIndexes: dateWarningIndexes, onEdit: handleEdit, onEditArray: handleEditArray, onCopy: handleCopy, onCopyToRevision: handleCopyToRevision, onDelete: handleDelete, moveUp: (id) => move(id, "up"), moveDown: (id) => move(id, "down"), totalLength: array.length, readOnly: isViewOnly, key: prof.displayId })))));
}
