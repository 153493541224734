var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Suspense, useState } from "react";
import clsx from "clsx";
import { uniqueId } from "@utils";
import styles from "./TextField.module.scss";
export function TextField(_a) {
    var _b;
    var { label, errorMessage, description, hasError, innerRef, rightIcon, isLimitVisible, value, autocompleteList, autocompleteComponent, onSelect, onBlur, onKeyDown, assistButton, containerRef, isCompact = false, type = "text", required } = _a, props = __rest(_a, ["label", "errorMessage", "description", "hasError", "innerRef", "rightIcon", "isLimitVisible", "value", "autocompleteList", "autocompleteComponent", "onSelect", "onBlur", "onKeyDown", "assistButton", "containerRef", "isCompact", "type", "required"]);
    const [inputId] = useState(uniqueId());
    const [touched, setTouched] = useState(false);
    const descriptionText = touched && hasError ? errorMessage || null : description || null;
    const lengthText = `${(value === null || value === void 0 ? void 0 : value.length) || 0}/${(_b = props.maxLength) === null || _b === void 0 ? void 0 : _b.toLocaleString()}`;
    const handleKeyDown = (event) => {
        onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(event);
        setTouched(true);
    };
    const autocomplete = (id) => {
        if (autocompleteComponent) {
            return (React.createElement(Suspense, { fallback: React.createElement("div", null) }, autocompleteComponent && autocompleteComponent(id)));
        }
        if (autocompleteList && !value) {
            return (React.createElement("datalist", { id: id }, autocompleteList.map((s) => (React.createElement("option", { value: s, key: s })))));
        }
        return null;
    };
    return (React.createElement("div", { className: clsx(styles.container, styles[type], isCompact && styles.compact, touched && hasError && styles.errorContainer) },
        label && (React.createElement("label", { className: clsx(styles.label, props.disabled && styles.labelDisabled, required && styles.required), htmlFor: inputId }, label)),
        React.createElement("div", { ref: containerRef || null, className: clsx(styles.inputContainer, props.disabled && styles.inputContainerDisabled) },
            React.createElement("input", Object.assign({ id: inputId, list: inputId + 1 }, props, { type: type, ref: innerRef, value: value, onSelect: onSelect, onBlur: onBlur, onKeyDown: handleKeyDown, className: styles.input, autoComplete: "off" })),
            autocomplete(inputId + 1),
            props.maxLength && isLimitVisible && React.createElement("span", { className: styles.limit }, lengthText),
            rightIcon && React.createElement("div", { className: styles.icon }, rightIcon),
            assistButton),
        descriptionText && React.createElement("p", { className: styles.description }, descriptionText)));
}
