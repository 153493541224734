import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUser, getTargetUser, registerNewCurrentUser, registerNewTargetUser, setAuthToken, setTargetUser, } from "@store/users/usersActions";
const initialState = {
    targetUser: null,
    currentUser: null,
    isTargetUserLoading: false,
    isCurrentUserLoading: false,
    targetUserNotFoundError: false,
    error: "",
    authToken: null,
};
export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        resetUserSlice: (state) => {
            Object.assign(state, initialState);
        },
    },
    extraReducers: {
        [getTargetUser.pending.type]: (state) => {
            state.isTargetUserLoading = true;
        },
        [getTargetUser.fulfilled.type]: (state, action) => {
            state.targetUser = action.payload;
            state.error = "";
            state.targetUserNotFoundError = false;
            state.isTargetUserLoading = false;
        },
        [getTargetUser.rejected.type]: (state, action) => {
            state.error = action.payload;
            state.targetUserNotFoundError = true;
            state.isTargetUserLoading = false;
        },
        [getCurrentUser.pending.type]: (state) => {
            state.isCurrentUserLoading = true;
        },
        [getCurrentUser.fulfilled.type]: (state, action) => {
            state.currentUser = action.payload;
            state.error = "";
            state.isCurrentUserLoading = false;
        },
        [getCurrentUser.rejected.type]: (state, action) => {
            state.error = action.payload;
            state.isCurrentUserLoading = false;
        },
        [registerNewCurrentUser.pending.type]: (state) => {
            state.isCurrentUserLoading = true;
        },
        [registerNewCurrentUser.fulfilled.type]: (state, action) => {
            state.currentUser = action.payload;
            state.error = "";
            state.isCurrentUserLoading = false;
        },
        [registerNewCurrentUser.rejected.type]: (state, action) => {
            state.error = action.payload;
            state.isCurrentUserLoading = false;
        },
        [registerNewTargetUser.pending.type]: (state) => {
            state.isTargetUserLoading = true;
        },
        [registerNewTargetUser.fulfilled.type]: (state, action) => {
            state.targetUser = action.payload;
            state.error = "";
            state.isTargetUserLoading = false;
        },
        [registerNewTargetUser.rejected.type]: (state, action) => {
            state.error = action.payload;
            state.isTargetUserLoading = false;
        },
        [setTargetUser.type]: (state, action) => {
            state.targetUser = action.payload;
        },
        [setAuthToken.type]: (state, action) => {
            state.authToken = action.payload;
        },
    },
});
export const { resetUserSlice } = usersSlice.actions;
export default usersSlice.reducer;
