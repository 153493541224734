import React from "react";
import clsx from "clsx";
import { IconButton } from "@components";
import IconAssist from "@assets/icon-assist.svg";
import Translate from "@assets/translate.svg";
import { useAppSelector } from "@hooks";
import { dictionary } from "@constants";
import styles from "./AssistButton.module.scss";
export function AssistButton({ icon, tooltip, preset = "beautify", disabled = false, className, externalRef, isActionInProgress, performAction, style, }) {
    const { language, featureFlags } = useAppSelector((state) => state.ui);
    const { translate, beautify } = dictionary(language);
    const iconToShow = icon || preset === "translate" ? React.createElement(Translate, null) : React.createElement(IconAssist, null);
    const tooltipToShow = tooltip || preset === "translate" ? translate : beautify;
    return (featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.enableInlineTranslationAndBeautification) ? (React.createElement(IconButton, { className: clsx(styles.assistButton, className), onClick: performAction, icon: iconToShow, color: preset === "translate" ? "primary" : "secondary", title: tooltipToShow, externalRef: externalRef, style: style, tabIndex: 0, isLoading: isActionInProgress, disabled: disabled })) : null;
}
