var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Api, CV_STATUS_URL, CV_UPDATE_DEFAULT_URL, CV_UPLOAD_URL, CV_URL } from "@services";
import { convertToCVDisplay } from "@store/cv/utils";
import { store } from "@store";
import { translateSingleRevision } from "@utils";
export const getRevisions = createAsyncThunk("revisions/getRevisions", (email, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data: response } = yield Api.get(`${CV_URL}${CV_STATUS_URL}?userId=${email}`);
        return response.map((revision) => (Object.assign(Object.assign({}, revision), { isPreview: false })));
    }
    catch (_a) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const deleteRevision = createAsyncThunk("revisions/deleteRevision", (_b, thunkApi_1) => __awaiter(void 0, [_b, thunkApi_1], void 0, function* ({ email, revisionName }, thunkApi) {
    try {
        yield Api.delete(`${CV_URL}?userId=${email}&revisionName=${revisionName}`);
        return revisionName;
    }
    catch (_c) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const renameRevision = createAsyncThunk("revisions/renameRevision", (_d, thunkApi_2) => __awaiter(void 0, [_d, thunkApi_2], void 0, function* ({ email, oldName, newName }, thunkApi) {
    try {
        yield Api.patch(`${CV_URL}?userId=${email}&oldName=${oldName}&newName=${newName}`);
        return newName;
    }
    catch (_e) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const translateRevision = createAsyncThunk("revisions/translateRevision", (_f, thunkApi_3) => __awaiter(void 0, [_f, thunkApi_3], void 0, function* ({ email, revisionName, from, to, }, thunkApi) {
    try {
        const { cv } = store.getState().cv;
        const response = yield translateSingleRevision(email, revisionName, from, to);
        const coolCustomSections = response.customSections.map((section, index) => (Object.assign(Object.assign({}, section), { header: cv.customSectionsDisplay[index].header })));
        const persistent = Object.assign(Object.assign({}, response), { customSections: coolCustomSections });
        return convertToCVDisplay(persistent, true);
    }
    catch (_g) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const uploadCV = createAsyncThunk("revisions/uploadCV", (_h, thunkApi_4) => __awaiter(void 0, [_h, thunkApi_4], void 0, function* ({ email, file }, thunkApi) {
    try {
        const { data: response } = yield Api.post(`${CV_UPLOAD_URL}?userId=${email}`, file, {
            "content-type": "application/vnd.openxmlformats-officedocument.wordprocessingml.document" /* MimeTypes.docx */,
        });
        return convertToCVDisplay(response, true);
    }
    catch (_j) {
        return thunkApi.rejectWithValue("Document doesn't match required format");
    }
}));
export const markDefault = createAsyncThunk("revisions/markDefault", (_k, thunkApi_5) => __awaiter(void 0, [_k, thunkApi_5], void 0, function* ({ prevName, targetName, userId }, thunkApi) {
    try {
        yield Api.patch(`${CV_URL}${CV_UPDATE_DEFAULT_URL}?userId=${userId}&previousDefaultRevisionName=${prevName}&targetDefaultRevisionName=${targetName}`);
        return targetName;
    }
    catch (_l) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const addRevision = createAction("revisions/addRevision");
export const setCVPreview = createAction("revisions/setCVPreview");
export const setSelectedRevision = createAction("revisions/setSelectedRevision");
export const removeRevision = createAction("revisions/removeRevision");
export const renamePreviewRevision = createAction("revisions/renamePreviewRevision");
