var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import Translate from "@assets/translate.svg";
import SpinnerIcon from "@assets/icon-spinner.svg";
import { Button, IconButton, Select, ConfirmModal, DropDownWrap } from "@components";
import { Language } from "@types";
import { useAppDispatch, useAppSelector } from "@hooks";
import { addRevision, setCV, setSelectedRevision, showNotification, translateRevision, } from "@store";
import IconSwap from "@assets/icon_swap.svg";
import { AnalyticsId, dictionary } from "@constants";
import { generateNewName } from "./generateNewName";
import { fields } from "./localization";
import styles from "./RevisionTranslate.module.scss";
export function RevisionTranslate({ setIsCVScrollable, setSelectedRevisionUrlParam, onSaveChanges, onDiscardChanges, }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isTranslateButtonDisabled, setIsTranslateButtonDisabled] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [fromLanguage, setFromLanguage] = useState(Object.keys(Language)[0]);
    const [toLanguage, setToLanguage] = useState(Object.keys(Language)[1]);
    const translateRef = useRef(null);
    const dispatch = useAppDispatch();
    const { revisions, selectedRevision, isTranslateRevisionLoading, cvPreview } = useAppSelector((state) => state.revisions);
    const { language, isViewOnly } = useAppSelector((state) => state.ui);
    const { isCVChanged } = useAppSelector((state) => state.cv);
    const { targetUser } = useAppSelector((state) => state.users);
    useEffect(() => {
        setIsDisabled(selectedRevision.isPreview || !!cvPreview);
    }, [selectedRevision, cvPreview]);
    useEffect(() => {
        setIsCVScrollable(!isOpen);
    }, [isOpen]);
    const field = fields(language);
    const { cancel, save, discardChanges, saveChanges, saveChangesSubtitle } = dictionary(language);
    const handleTranslate = () => __awaiter(this, void 0, void 0, function* () {
        setIsTranslateButtonDisabled(true);
        const res = yield dispatch(translateRevision({
            email: targetUser.email,
            revisionName: selectedRevision.name,
            to: toLanguage,
            from: fromLanguage,
        })).unwrap();
        dispatch(setCV(res));
        const newRevision = {
            name: generateNewName(revisions.map((r) => r.name), selectedRevision.name, toLanguage),
            isPreview: true,
        };
        setSelectedRevisionUrlParam(newRevision.name);
        dispatch(addRevision(newRevision));
        dispatch(setSelectedRevision(newRevision));
        setIsDisabled(true);
        setIsOpen(false);
        setIsTranslateButtonDisabled(false);
        dispatch(showNotification({ message: field.notification, isSuccess: true }));
    });
    const handleButtonClick = () => {
        if (isCVChanged) {
            setShowConfirmModal(true);
        }
        else {
            setIsOpen(!isOpen);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DropDownWrap, { isOpen: isOpen, toggleOpen: handleButtonClick, externalAnchorRef: translateRef, disabled: isDisabled || isViewOnly, iconOnly: true, title: isDisabled ? field.translateDisabled : field.translateActive, anchor: isTranslateRevisionLoading ? (React.createElement(SpinnerIcon, { className: styles.spinnerIcon })) : (React.createElement(Translate, null)), variant: "outlined", primaryDirection: "right" },
            React.createElement("div", { className: styles.container },
                React.createElement("p", { className: "title-small" }, field.translateActive),
                React.createElement("div", { className: styles.translateSelectorsBox },
                    React.createElement(Select, { value: fromLanguage, label: field.from, options: Object.entries(Language).map(([lang]) => ({
                            label: lang,
                            id: lang,
                            isSelected: lang === fromLanguage,
                        })), onOptionClick: (_, option) => setFromLanguage(option.label), parentWidth: false }),
                    React.createElement(IconButton, { color: "secondary", icon: React.createElement(IconSwap, null), onClick: () => {
                            setFromLanguage(toLanguage);
                            setToLanguage(fromLanguage);
                        }, title: field.swap }),
                    React.createElement(Select, { value: toLanguage, label: field.to, options: Object.entries(Language).map(([lang]) => ({
                            label: lang,
                            id: lang,
                            isSelected: lang === toLanguage,
                        })), onOptionClick: (_, option) => setToLanguage(option.label), parentWidth: false })),
                React.createElement("footer", { className: styles.buttons },
                    React.createElement(Button, { color: "tertiary", onClick: () => setIsOpen(false) }, cancel),
                    React.createElement(Button, { variant: "contained", onClick: handleTranslate, disabled: toLanguage === fromLanguage || isTranslateButtonDisabled, id: AnalyticsId.translate }, field.translate)))),
        showConfirmModal && (React.createElement(ConfirmModal, { isOpen: true, title: saveChanges, message: saveChangesSubtitle.replace("$REVISION", selectedRevision.name), saveBtnText: save, cancelBtnText: discardChanges, onSave: () => __awaiter(this, void 0, void 0, function* () {
                yield onSaveChanges();
                setShowConfirmModal(false);
                setIsOpen(true);
            }), onClose: () => {
                setShowConfirmModal(false);
            }, onCancel: () => {
                onDiscardChanges();
                setShowConfirmModal(false);
                setIsOpen(true);
            } }))));
}
