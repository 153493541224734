export var SupportedSettings;
(function (SupportedSettings) {
    SupportedSettings["Anonymize"] = "anonymize";
    SupportedSettings["HideCustomers"] = "hideCustomers";
    SupportedSettings["HidePhoto"] = "hideImage";
    SupportedSettings["HideTrainings"] = "hideTrainings";
    SupportedSettings["RemovePageBreak"] = "removePageBreakAfterOverview";
    SupportedSettings["HidePersonalStrength"] = "hidePersonalStrengths";
    SupportedSettings["RemoveAllPageBreaks"] = "removeAllPageBreaks";
    SupportedSettings["HideCertificates"] = "hideCertifications";
    SupportedSettings["HideProjectNumber"] = "hideProjectNumber";
    SupportedSettings["AnonymizeCustomers"] = "anonymizeCustomers";
})(SupportedSettings || (SupportedSettings = {}));
