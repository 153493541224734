import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { IconButton, Select } from "@components";
import IconTrash from "@assets/icon_trash.svg";
import IconDrag from "@assets/icon-drag.svg";
import { useAppSelector, usePrevious } from "@hooks";
import { dictionary } from "@constants";
import { createLanguagePoint, uniqueId, hasEqualValue } from "@utils";
import styles from "./OptionsField.module.scss";
export const LIST_FIELD_DROPPABLE_TYPE = "list_field";
export function OptionsField({ points, onEdit, readOnly = false, isNested, parentIndex, highlightEmpty = false, options, errorIds, }) {
    const [shouldBeFocused, setShouldBeFocused] = useState();
    const prevPoints = usePrevious(points);
    const { language } = useAppSelector((state) => state.ui);
    const { isTriedToSave } = useAppSelector((state) => state.cv);
    const { cvPreview } = useAppSelector((state) => state.revisions);
    useEffect(() => {
        if (!shouldBeFocused && points && prevPoints && prevPoints.length + 1 === points.length) {
            const hasEqualDisplayId = hasEqualValue(points.map(({ displayId }) => displayId), prevPoints.map(({ displayId }) => displayId));
            const newlyCreatedItem = !prevPoints.length;
            if (hasEqualDisplayId || newlyCreatedItem) {
                setShouldBeFocused(prevPoints.length);
            }
        }
    }, [points]);
    const { remove } = dictionary(language);
    const onRemove = (index) => {
        const newPoints = [...points];
        newPoints.splice(index, 1);
        if (newPoints.length === 0) {
            onEdit([createLanguagePoint()]);
        }
        else {
            onEdit(newPoints);
        }
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const newPoints = [...points];
        const [removed] = newPoints.splice(result.source.index, 1);
        newPoints.splice(result.destination.index, 0, removed);
        onEdit(newPoints);
    };
    const isEmpty = points.every((field) => field.language.trim() === "" && field.level.trim() === "");
    const content = (React.createElement(Droppable, { droppableId: parentIndex !== undefined ? `${parentIndex}` : "list_field_droppable", type: LIST_FIELD_DROPPABLE_TYPE }, (provided) => (React.createElement("div", Object.assign({}, provided.droppableProps, { ref: provided.innerRef }),
        points &&
            points.map((field, index) => (React.createElement(Draggable, { key: field.displayId, draggableId: parentIndex !== undefined
                    ? `${parentIndex}-${field.displayId}`
                    : `${field.displayId}`, index: index, isDragDisabled: readOnly || points.length < 2 }, (draggableProvided, snapshot) => (React.createElement("div", Object.assign({ className: clsx(styles.selectListItem, snapshot.isDragging && styles.isDragging), ref: draggableProvided.innerRef }, draggableProvided.draggableProps, draggableProvided.dragHandleProps),
                React.createElement("div", { className: styles.inputContainer },
                    React.createElement("div", { className: styles.dragIconContainer },
                        React.createElement(IconDrag, { className: clsx(styles.drag, (readOnly || points.length < 2) && styles.dragDisabled) })),
                    options &&
                        options.map((item) => {
                            const itemValue = field[item.value];
                            let correspondingValue = "";
                            if (item.value === "level") {
                                correspondingValue = field.language.trim();
                            }
                            return (React.createElement(Select, { key: itemValue || uniqueId(), onOptionClick: (_, option) => item.onEditPoint(index, option.label), options: item.options.map(({ code, name }) => ({
                                    id: code,
                                    label: name,
                                    isSelected: name === itemValue,
                                })), value: itemValue, hasError: (highlightEmpty && !itemValue.trim()) ||
                                    (errorIds && errorIds.includes(field.displayId)) ||
                                    (item.value === "level" &&
                                        isTriedToSave &&
                                        !itemValue.trim() &&
                                        !isEmpty &&
                                        correspondingValue !== "") ||
                                    (item.value === "level" &&
                                        cvPreview &&
                                        !itemValue.trim() &&
                                        correspondingValue.trim() !== ""), disabled: readOnly, isSelectable: true, hasInput: true, placeholder: item.placeholder }));
                        }),
                    React.createElement(IconButton, { color: "secondary", onClick: () => onRemove(index), icon: React.createElement(IconTrash, null), disabled: readOnly ||
                            (points.length === 1 &&
                                points[0].language === "" &&
                                points[0].level === ""), title: remove }))))))),
        provided.placeholder))));
    return (React.createElement("div", null, isNested ? content : React.createElement(DragDropContext, { onDragEnd: onDragEnd }, content)));
}
