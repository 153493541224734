var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, IconButton, InputWithSelectionTool, TextAreaWithSelectionTool } from "@components";
import IconPlus from "@assets/icon_plus.svg";
import IconTrash from "@assets/icon_trash.svg";
import IconDrag from "@assets/icon-drag.svg";
import { addId, createDisplayPoint, hasEqualValue, scrollHiddenIntoView } from "@utils";
import { useAppSelector, usePrevious } from "@hooks";
import { dictionary } from "@constants";
import commonStyles from "@pages/CvPage/components/CV/CV.common.scss";
import styles from "./ListField.module.scss";
export const LIST_FIELD_DROPPABLE_TYPE = "list_field";
export function ListField({ title, itemTitle = title, points, onEdit, onKeyDownHandler, readOnly = false, maxLength = 100, isNested, parentIndex, showAddBtn = true, highlightEmpty = false, maxLimit, onLimitAchieved, onItemSelection, selectionActionPreset, addButtonLabel, inputType = "input", isValidFunction, validationMessage, additionalComponent, isDragDisabled = false, autocompleteComponent, isAutoGrowing, }) {
    const [shouldBeFocused, setShouldBeFocused] = useState();
    const prevPoints = usePrevious(points);
    const { language } = useAppSelector((state) => state.ui);
    useEffect(() => {
        if (!shouldBeFocused && points && prevPoints && prevPoints.length + 1 === points.length) {
            const hasEqualDisplayId = hasEqualValue(points.map(({ displayId }) => displayId), prevPoints.map(({ displayId }) => displayId));
            const newlyCreatedItem = !prevPoints.length;
            if (hasEqualDisplayId || newlyCreatedItem) {
                setShouldBeFocused(prevPoints.length);
            }
        }
    }, [points]);
    const { remove } = dictionary(language);
    const onEditPoint = (index, value) => {
        const newPoints = [...points];
        newPoints[index] = Object.assign(Object.assign({}, points[index]), { value });
        onEdit(newPoints);
    };
    const onAdd = () => {
        const newPoints = [...points];
        newPoints.push(addId(""));
        onEdit(newPoints);
        setShouldBeFocused(points.length);
    };
    const onRemove = (index) => {
        const newPoints = [...points];
        newPoints.splice(index, 1);
        if (newPoints.length === 0) {
            onEdit([createDisplayPoint()]);
        }
        else {
            onEdit(newPoints);
        }
    };
    const onKeyDown = (index, event) => {
        const limitAchieved = maxLimit && maxLimit <= points.length;
        if (event.key === "Enter" && !limitAchieved) {
            const newPoints = [...points];
            newPoints.splice(index + 1, 0, addId(""));
            setShouldBeFocused(index + 1);
            onEdit(newPoints);
        }
        else if (event.key === "Enter" && limitAchieved) {
            onLimitAchieved();
        }
        if (onKeyDownHandler) {
            onKeyDownHandler();
        }
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const newPoints = [...points];
        const [removed] = newPoints.splice(result.source.index, 1);
        newPoints.splice(result.destination.index, 0, removed);
        onEdit(newPoints);
    };
    const content = (React.createElement(Droppable, { droppableId: parentIndex !== undefined ? `${parentIndex}` : "list_field_droppable", type: LIST_FIELD_DROPPABLE_TYPE }, (provided) => (React.createElement("div", Object.assign({}, provided.droppableProps, { ref: provided.innerRef }),
        points &&
            points.map((field, index) => (React.createElement(Draggable, { key: field.displayId, draggableId: parentIndex !== undefined
                    ? `${parentIndex}-${field.displayId}`
                    : `${field.displayId}`, index: index, isDragDisabled: readOnly || isDragDisabled || points.length < 2 }, (draggableProvided, snapshot) => (React.createElement("div", Object.assign({ className: clsx(styles.inputListItem, snapshot.isDragging && styles.isDragging), ref: draggableProvided.innerRef }, draggableProvided.draggableProps, draggableProvided.dragHandleProps),
                React.createElement("div", { className: clsx(styles.inputContainer, styles[inputType]) },
                    React.createElement("div", { className: styles.dragIconContainer },
                        React.createElement(IconDrag, { className: clsx(styles.drag, (readOnly || points.length < 2) && styles.dragDisabled) })),
                    inputType === "input" && (React.createElement(InputWithSelectionTool, { innerRef: (input) => {
                            if (input && shouldBeFocused === index) {
                                input.focus({ preventScroll: true });
                                scrollHiddenIntoView(input);
                                setShouldBeFocused(null);
                            }
                        }, onKeyDown: (e) => onKeyDown(index, e), placeholder: itemTitle, maxLength: maxLength, readOnly: readOnly, disabled: readOnly, value: field.value, hasError: highlightEmpty && !field.value.trim(), handleValueChange: (value) => onEditPoint(index, value), handleSelectionAction: (text, start, end) => __awaiter(this, void 0, void 0, function* () {
                            yield onItemSelection(index, text, start, end);
                        }), preset: selectionActionPreset, isSelectionFeatureEnabled: !!onItemSelection, autocompleteComponent: autocompleteComponent })),
                    inputType === "textarea" && (React.createElement(TextAreaWithSelectionTool, { forwardedRef: (input) => {
                            if (input && shouldBeFocused === index) {
                                input.focus({ preventScroll: true });
                                scrollHiddenIntoView(input);
                                setShouldBeFocused(null);
                            }
                        }, wrapClassName: clsx(styles.control), placeholder: itemTitle, maxLength: maxLength, readOnly: readOnly, disabled: readOnly, value: field.value, hasError: highlightEmpty && !field.value.trim(), handleValueChange: (value) => onEditPoint(index, value), handleSelectionAction: (text, start, end) => __awaiter(this, void 0, void 0, function* () {
                            yield onItemSelection(index, text, start, end);
                        }), preset: selectionActionPreset, isSelectionFeatureEnabled: !!onItemSelection, rows: 4, isAutoGrowing: isAutoGrowing })),
                    additionalComponent && additionalComponent({ rowIndex: index }),
                    React.createElement(IconButton, { color: "secondary", onClick: () => onRemove(index), icon: React.createElement(IconTrash, null), disabled: readOnly || (points.length === 1 && points[0].value === ""), title: remove })),
                isValidFunction && !isValidFunction(field.value) && (React.createElement("span", { className: clsx(styles.validationMessage, "body-small") }, validationMessage))))))),
        provided.placeholder))));
    return (React.createElement(React.Fragment, null,
        showAddBtn && (React.createElement("header", { className: commonStyles.sectionHeading },
            title,
            !addButtonLabel ? (React.createElement(IconButton, { onClick: onAdd, color: "secondary", variant: "contained", disabled: readOnly, icon: React.createElement(IconPlus, null) })) : (React.createElement(Button, { onClick: onAdd, color: "tertiary", variant: "text", disabled: readOnly, leftIcon: addButtonLabel && React.createElement(IconPlus, null) }, addButtonLabel)))),
        isNested ? content : React.createElement(DragDropContext, { onDragEnd: onDragEnd }, content)));
}
