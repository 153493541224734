var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Api, USERS_FULL_URL, USERS_FULL_URL_EMAIL } from "@services";
import { extractNames } from "@store/users/utils";
export const getTargetUser = createAsyncThunk("users/getTargetUser", (email, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data } = yield Api.get(`${USERS_FULL_URL_EMAIL}/${email}`);
        return extractNames(data);
    }
    catch (_a) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const getCurrentUser = createAsyncThunk("users/getCurrentUser", (email, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data } = yield Api.get(`${USERS_FULL_URL_EMAIL}/${email}`);
        return extractNames(data);
    }
    catch (_b) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const registerNewCurrentUser = createAsyncThunk("users/registerNewCurrentUser", (user, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data } = yield Api.post(USERS_FULL_URL, user);
        return extractNames(data);
    }
    catch (_c) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const registerNewTargetUser = createAsyncThunk("users/registerNewTargetUser", (email, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data } = yield Api.post(`${USERS_FULL_URL_EMAIL}/${email}`);
        return extractNames(data);
    }
    catch (_d) {
        return thunkApi.rejectWithValue("something went wrong");
    }
}));
export const setTargetUser = createAction("users/setTargetUser");
export const setAuthToken = createAction("users/setAuthToken");
