import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        revisionName: ["Revision Name", "Название Ревизии"][index],
        save: ["Save", "Сохранить"][index],
        cancel: ["Cancel", "Отменить"][index],
        rename: ["Rename", "Переименовать"][index],
        remove: ["Delete", "Удалить"][index],
        markAsDefault: ["Mark as default", "Сделать основной ревизией"][index],
    };
};
