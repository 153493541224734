var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from "react";
import { saveCV, showNotification, store } from "@store";
import { useAppDispatch } from "@hooks/useDispatch";
import { dictionary } from "@constants";
const AUTO_SAVE_TIMEOUT = 5 * 60 * 1000;
export const useAutoSave = () => {
    const dispatch = useAppDispatch();
    const handler = () => __awaiter(void 0, void 0, void 0, function* () {
        const { targetUser } = store.getState().users;
        const { selectedRevision } = store.getState().revisions;
        const { cv, isCVChanged } = store.getState().cv;
        const { language } = store.getState().ui;
        if (isCVChanged) {
            yield dispatch(saveCV({ data: cv, revisionName: selectedRevision.name, email: targetUser.email }));
            dispatch(showNotification({ message: dictionary(language).saved, isSuccess: true }));
        }
    });
    useEffect(() => {
        setInterval(handler, AUTO_SAVE_TIMEOUT);
    }, []);
};
