import { CustomSectionContentType, } from "@types";
import { parseLanguageString } from "./parser";
export function uniqueId() {
    return crypto.randomUUID();
}
export const addId = (input) => {
    return { value: input, displayId: uniqueId() };
};
export const addIdArray = (array) => {
    return array.map((resp) => addId(resp));
};
export const parseLanguageArray = (inputArray) => {
    return inputArray.map((item) => {
        const { language, level } = parseLanguageString(item);
        return {
            language,
            level,
            displayId: uniqueId(),
        };
    });
};
export const createDisplayPoint = (value = "") => ({
    value,
    displayId: uniqueId(),
});
export const createLanguagePoint = (language = "", level = "") => ({
    language,
    level,
    displayId: uniqueId(),
});
export const createCertificatePoint = () => ({
    displayId: uniqueId(),
    value: "",
    linkToCertificate: "",
});
export const createCustomSection = (bodyContentType) => ({
    header: "Custom section",
    displayId: uniqueId(),
    isSelected: true,
    isHidden: false,
    content: Object.assign({}, (bodyContentType === CustomSectionContentType.Text
        ? { text: "" }
        : { bulletPoints: [createDisplayPoint()] })),
    bodyContentType,
});
