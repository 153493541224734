import { languageIndex } from "@utils";
import { ALLOWED_CUSTOMER } from "./ProfessionalExperienceItem";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        timeline: ["Timeline", "Период"][index],
        start: ["Start Date", "Начало"][index],
        end: ["End Date", "Конец"][index],
        placeholder: ["MM/YYYY", "ММ/ГГГГ"][index],
        projectName: ["Project name", "Название проекта"][index],
        customer: ["Customer", "Заказчик"][index],
        role: ["Role", "Позиция"][index],
        roleDescription: [
            "You can specify several roles via comma",
            "Вы можете перечислить несколько ролей через запятую",
        ][index],
        projectDescription: ["Project description", "Описание проекта"][index],
        responsibilities: ["Responsibilities", "Обязанности"][index],
        responsibility: ["Responsibility", "Обязанность"][index],
        tools: ["Tools and technologies", "Инструменты и технологии"][index],
        companyWarning: [
            `Company is sensitive information and shouldn't have real name, please use "${ALLOWED_CUSTOMER}" instead`,
            'Название компании является конфиденциальной информацией, пожалуйста, используйте "Соглашение о неразглашении"',
        ][index],
        datesWarning: [
            "Dates ranges of different projects should not overlap",
            "Интервалы дат разных проектов не должны пересекаться",
        ][index],
        positionWarning: ["Example:", "Пример:"][index],
        duplicateProject: ["Duplicate project", "Копировать проект"][index],
        duplicateProjectToRevision: [
            "Copy project into another revision",
            "Копировать проект в другую ревизию",
        ][index],
        deleteProject: ["Delete project", "Удалить проект"][index],
        buttonUp: ["Move item up", "Переместить наверх"][index],
        buttonDown: ["Move item down", "Переместить вниз"][index],
        isPresentTime: ["Present Time", "Настоящее Время"][index],
        addResp: ["Add Responsibility", "Добавить Обязанность"][index],
        projectInfo: ["Project Information", "Информация о проекте"][index],
        copy: ["Copy", "Копировать"][index],
    };
};
