var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DEFAULT_CV_REVISION, getCV, getFeatureFlags, getRevisions, getTemplates, registerNewTargetUser, selectTemplate, setCV, setLanguage, setSelectedRevision, setTargetUser, showNotification, } from "@store";
import { createCustomSection, cvPath, LocalStorageKeys, notFoundPagePath } from "@utils";
import { useAppDispatch, useAppSelector, useAutoSave, useForceUpdate, useOutsideEvent, useTablet, } from "@hooks";
import { Api, CHECK_ACCESS } from "@services";
import { Language } from "@types";
import { forceHotReload, Techforward } from "@constants";
import { CV, CvHeader, FeedbackIsland, Navigation, Notifications, Subordinates, } from "./components";
import { fields } from "./localization";
import styles from "./CvPage.module.scss";
const modalRoot = document.getElementById("modal-root");
export function CvPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isTabletDuo = useTablet();
    useAutoSave();
    const { currentUser } = useAppSelector((state) => state.users);
    const { isCVChanged, cv } = useAppSelector((state) => state.cv);
    const { language, isViewOnly } = useAppSelector((state) => state.ui);
    const { templates } = useAppSelector((state) => state.templates);
    const [isLoading, setIsLoading] = useState(true);
    const [isProhibited, setIsProhibited] = useState(false);
    // This flag allows scroll CvPage content when mouse outside of CV component.
    const [isCVScrollable, setIsCVScrollable] = useState(true);
    const [isNavigationVisible, setIsNavigationVisible] = useState(false);
    const forceUpdate = useForceUpdate();
    const CVWrapperRef = useRef(null);
    const navigationRef = useRef(null);
    useOutsideEvent(navigationRef, isNavigationVisible, () => {
        setIsNavigationVisible(false);
    });
    const field = fields(language);
    useEffect(() => {
        setIsCVScrollable(!isNavigationVisible);
    }, [isNavigationVisible]);
    useEffect(() => {
        const UA = navigator.userAgent;
        const isChrome = /Chrome\//gm.test(UA);
        const isMobileChrome = /Mobile Safari\//gm.test(UA);
        const isChromium = /Chromium\//gm.test(UA);
        const isEdge = /Edg\//gm.test(UA);
        const isYandex = /YaBrowser\//gm.test(UA);
        if (isChrome && !isMobileChrome && !isChromium && !isEdge && !isYandex) {
            dispatch(showNotification({
                isSuccess: true,
                message: field.spellCheckWarning,
                timeout: 3000,
                localStorageKey: LocalStorageKeys.SPELL_CHECK,
            }));
        }
    }, []);
    useEffect(() => {
        const handleResize = () => {
            forceUpdate();
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        if (templates === null || templates === void 0 ? void 0 : templates.length) {
            dispatch(selectTemplate(templates[0]));
            dispatch(setLanguage(templates[0].displayName === Techforward ? Language.RU : Language.EN));
        }
    }, [templates]);
    useEffect(() => {
        const beforeUnloadListener = (event) => {
            if (isCVChanged && !forceHotReload) {
                event.preventDefault();
                event.returnValue = "";
                return event;
            }
        };
        window.addEventListener("beforeunload", beforeUnloadListener);
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadListener);
        };
    }, [isCVChanged]);
    const init = () => __awaiter(this, void 0, void 0, function* () {
        setIsLoading(true);
        const targetUserEmail = location.pathname.split("/").pop();
        const urlParams = new URLSearchParams(location.search);
        const revisionFromUrl = urlParams.get("revision");
        if (location.pathname === cvPath.pattern) {
            navigate(cvPath.getPath(currentUser === null || currentUser === void 0 ? void 0 : currentUser.email));
            return;
        }
        let targetUser;
        try {
            if (targetUserEmail === currentUser.email) {
                targetUser = currentUser;
                dispatch(setTargetUser(targetUser));
            }
            else {
                const { data: isAccessAllowed } = yield Api.get(`${CHECK_ACCESS}?userEmail=${currentUser.email}&cvEmail=${targetUserEmail}`);
                if (isAccessAllowed) {
                    // This will automatically register new target user if it isn't exist.
                    targetUser = yield dispatch(registerNewTargetUser(targetUserEmail)).unwrap();
                    dispatch(setTargetUser(targetUser));
                }
                else {
                    setIsLoading(false);
                    setIsProhibited(true);
                    return;
                }
            }
        }
        catch (error) {
            setIsLoading(false);
            setIsProhibited(true);
            return;
        }
        let revisionToUse = revisionFromUrl;
        try {
            const revisionsResult = yield dispatch(getRevisions(targetUser.email)).unwrap();
            const revision = revisionsResult.find(({ name }) => name === revisionFromUrl) ||
                revisionsResult.find(({ isDefault }) => isDefault);
            if (!revision) {
                revisionToUse = DEFAULT_CV_REVISION.name;
            }
            else {
                dispatch(setSelectedRevision(Object.assign({}, revision)));
                revisionToUse = revision.name;
            }
        }
        catch (error) {
            setIsLoading(false);
            return;
        }
        yield Promise.all([
            dispatch(getTemplates()),
            dispatch(getCV({ email: targetUser.email, revisionName: revisionToUse })).unwrap(),
            dispatch(getFeatureFlags()),
        ]);
        setIsLoading(false);
    });
    useEffect(() => {
        init();
    }, [location.pathname]);
    useEffect(() => {
        if (isViewOnly) {
            dispatch(showNotification({
                message: field.editRestrictionWarning,
                timeout: 3000,
            }));
        }
    }, [isViewOnly]);
    const isNavigationShouldScroll = (event) => {
        if (!navigationRef) {
            return false;
        }
        const isEventInNavigation = navigationRef.current && navigationRef.current.contains(event.target);
        const scrollingDown = event.deltaY > 0;
        const scrollingUp = event.deltaY < 0;
        const isAtTop = isEventInNavigation && scrollingUp && navigationRef.current.scrollTop === 0;
        const isAtBottom = isEventInNavigation &&
            scrollingDown &&
            navigationRef.current.scrollTop + navigationRef.current.clientHeight >=
                navigationRef.current.scrollHeight;
        const hasScrolling = isEventInNavigation &&
            navigationRef.current.scrollHeight > navigationRef.current.clientHeight &&
            !(isAtTop || isAtBottom);
        return hasScrolling;
    };
    const handleWheel = (event) => {
        const isEventInNavigation = navigationRef.current && navigationRef.current.contains(event.target);
        if (isCVScrollable &&
            !isNavigationShouldScroll(event) &&
            CVWrapperRef.current &&
            !CVWrapperRef.current.contains(event.target) &&
            !modalRoot.contains(event.target) &&
            !isEventInNavigation) {
            CVWrapperRef.current.scrollBy({
                top: event.deltaY,
                behavior: "auto",
            });
        }
    };
    const handleSectionAdd = (sectionType) => {
        const copy = cv.customSectionsDisplay.map((section) => (Object.assign(Object.assign({}, section), { isSelected: false })));
        dispatch(setCV(Object.assign(Object.assign({}, cv), { customSectionsDisplay: [...copy, createCustomSection(sectionType)] })));
        dispatch(showNotification({ message: field.customSectionAddedNotification, isSuccess: true }));
    };
    const memoizedCV = useMemo(() => !isProhibited && React.createElement(CV, { isLoading: isLoading, onSectionAdd: handleSectionAdd }), [isLoading, isProhibited, handleSectionAdd]);
    if (isProhibited) {
        return React.createElement(Navigate, { to: notFoundPagePath.pattern });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CvHeader, { setIsCVScrollable: setIsCVScrollable, toggleNavigationVisibility: () => setIsNavigationVisible(!isNavigationVisible) }),
        React.createElement("main", { className: styles.cvContent, onWheel: handleWheel },
            React.createElement("div", { className: styles.wrapper },
                (!isTabletDuo || isNavigationVisible) && (React.createElement(Navigation, { wrapperRef: CVWrapperRef, navigationRef: navigationRef, onSectionAdd: handleSectionAdd, hasCloseBtn: isNavigationVisible, hideNavigation: () => setIsNavigationVisible(false) })),
                React.createElement("div", { className: styles.container },
                    React.createElement("div", { className: styles.sections, ref: CVWrapperRef }, memoizedCV)))),
        React.createElement(Notifications, null),
        React.createElement(FeedbackIsland, null),
        React.createElement(Subordinates, null)));
}
