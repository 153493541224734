import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        title: ["Education", "Образование"][index],
        addEducation: ["Add Education", "Добавить Образование"][index],
        placeholder: [
            "Educational Institution, Specialization, Degree",
            "Учебное заведение, специальность, степень",
        ][index],
        validationMessage: ["Graduation year might be omitted", "Год выпуска может быть опущен"][index],
    };
};
