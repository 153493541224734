var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { authPagePath, cvPath } from "@utils";
import { PageLoader, ProtectedRoute } from "@components";
import { breakToken, configResponseInterceptor, signOut } from "@services";
import { CvPage } from "@pages";
import { resetUserSlice } from "@store/users/usersSlice";
import { isDevelopmentMode } from "@constants";
const Auth = React.lazy(() => import("./pages/Auth").then((module) => ({ default: module.Auth })));
const NotFound = React.lazy(() => import("./pages/NotFound").then((module) => ({ default: module.NotFound })));
export function App() {
    const dispatch = useDispatch();
    const logOut = () => __awaiter(this, void 0, void 0, function* () {
        dispatch(resetUserSlice());
        yield signOut();
    });
    useEffect(() => {
        configResponseInterceptor();
        if (isDevelopmentMode) {
            window.logout = logOut;
            window.break_token = breakToken;
        }
    }, []);
    return (React.createElement(BrowserRouter, null,
        React.createElement(React.Suspense, { fallback: React.createElement(PageLoader, null) },
            React.createElement(Routes, null,
                React.createElement(Route, { path: authPagePath.pattern, element: React.createElement(Auth, null) }),
                React.createElement(Route, { element: React.createElement(ProtectedRoute, null) },
                    React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: cvPath.pattern }) }),
                    React.createElement(Route, { path: cvPath.pattern, element: React.createElement(CvPage, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(NotFound, null) }))))));
}
