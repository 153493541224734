var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import clsx from "clsx";
import { InputWithSelectionTool, TextField, SectionTitle } from "@components";
import { useAppDispatch, useAppSelector } from "@hooks";
import { setCV, translateSubstring } from "@store";
import { HeadingIds } from "@constants";
import { fields } from "./localization";
import commonStyles from "../../CV.common.scss";
import styles from "./GeneralInfo.module.scss";
export const DEFAULT_GENERAL_INFO = {
    firstName: "",
    lastName: "",
    anonymize: false,
    hideImage: false,
    hideCustomers: false,
    hidePersonalStrengths: false,
    hideTrainings: false,
    hideCertifications: false,
    removePageBreakAfterOverview: false,
    removeAllPageBreaks: false,
    hideProjectNumber: false,
    anonymizeCustomers: false,
};
export function GeneralInfo() {
    const { cv } = useAppSelector((state) => state.cv);
    const { language, isViewOnly } = useAppSelector((state) => state.ui);
    const { firstName, lastName, anonymize } = cv || DEFAULT_GENERAL_INFO;
    const dispatch = useAppDispatch();
    const field = fields(language);
    const handleFirstNameEdit = (value) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { firstName: value })));
    };
    const handleLastNameEdit = (value) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { lastName: value })));
    };
    const handleJobTitleEdit = (value) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { jobTitle: value })));
    };
    const translateSelectedPart = (text, start, end) => __awaiter(this, void 0, void 0, function* () {
        const translatedResult = yield dispatch(translateSubstring({ text, start, end })).unwrap();
        handleJobTitleEdit(translatedResult);
    });
    return (React.createElement("section", { id: HeadingIds.GeneralInfo, className: clsx(commonStyles.section) },
        React.createElement(SectionTitle, { title: field.heading }),
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.col },
                React.createElement(TextField, { placeholder: field.firstName, maxLength: 50, value: anonymize ? "" : firstName, onChange: ({ target: { value } }) => handleFirstNameEdit(value), disabled: anonymize || isViewOnly, label: field.firstName }),
                React.createElement(TextField, { placeholder: field.lastName, maxLength: 50, value: anonymize ? "" : lastName, onChange: ({ target: { value } }) => handleLastNameEdit(value), disabled: anonymize || isViewOnly, label: field.lastName })),
            React.createElement("div", { className: styles.col },
                React.createElement(InputWithSelectionTool, { label: field.jobTitle, placeholder: field.jobTitle, readOnly: isViewOnly, value: (cv === null || cv === void 0 ? void 0 : cv.jobTitle) || "", handleValueChange: handleJobTitleEdit, handleSelectionAction: translateSelectedPart })))));
}
