/* eslint-disable prefer-destructuring */
export const DOC_PROCESSING_URL = process.env.DOC_PROCESSING_URL;
export const USERS_FULL_URL = "/user-profiles";
export const USERS_FULL_URL_EMAIL = `${USERS_FULL_URL}/email`;
export const CV_URL = "/cv";
export const CV_DOWNLOAD_URL = "/CVDownload";
export const FILE_LINK_URL = "/link";
export const DRIVE_LINK_URL = "/link/drive";
export const CV_UPLOAD_URL = `${CV_URL}/parse`;
export const CV_TEMPLATES_URL = `${CV_URL}/templates`;
export const CV_TRANSLATE_URL = `${CV_URL}/translate`;
export const CV_STATUS_URL = "/revisionList";
export const CV_UPDATE_DEFAULT_URL = "/UpdateDefaultRevision";
export const TOKEN_AUTH = "/Authorization";
export const PASSWORD_AUTH = `${TOKEN_AUTH}/SignInWithPassword`;
export const CHECK_ACCESS = "/access";
export const FEEDBACK = "/FeedbackNotification";
export const FEATURE_FLAGS = "/feature-flags";
export const TRANSLATE_TOOL = "/AzureIntegration/translate";
export const BEAUTIFY_TOOL = "/AzureIntegration/beautify";
export const SUBORDINATES = "/integration/subordinates";
