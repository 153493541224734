import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        uploadActiveTitle: ["Upload .DOCX CV file", "Загрузить .DOCX файл CV"][index],
        uploadDisabledTitle: ["Upload disabled", "Загрузка заблокирована"][index],
        uploadCvError: [
            "Document formatting doesn't match required format",
            "Форматирование документа не соответствует ожидаемому формату",
        ][index],
        uploadCvSuccess: ["CV successfully uploaded", "CV успешно загружено"][index],
    };
};
