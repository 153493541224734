import clsx from "clsx";
import React, { useRef } from "react";
import { useOverlay, useOutsideEvent } from "@hooks";
import styles from "./Overlay.module.scss";
export function Overlay({ isOpen, anchorRef, children, hideOverlay, excludedRefs, primaryPositionX = "left", primaryPositionY, parentWidth = false, }) {
    const contentRef = useRef(null);
    const [isPositionCalculated, position, width] = useOverlay(isOpen, contentRef, anchorRef, primaryPositionX, primaryPositionY);
    useOutsideEvent(contentRef, isOpen, hideOverlay, anchorRef, excludedRefs);
    return (isOpen && (React.createElement("div", { className: clsx(styles.content, isPositionCalculated && styles.open), style: Object.assign(Object.assign({}, (parentWidth && { width })), { top: position.top, left: position.left }), ref: contentRef }, children)));
}
