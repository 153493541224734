export const findEqualLanguages = (language) => {
    const equalLangIds = [];
    language.forEach((a) => language.forEach((b) => {
        if (!!a.language &&
            !!a.level &&
            a.language === b.language &&
            a.level === b.level &&
            a.displayId !== b.displayId) {
            equalLangIds.push(a.displayId);
        }
    }));
    return equalLangIds;
};
export const hasEmptyLevel = (language) => {
    return language.some((lang) => lang.language.trim() !== "" && lang.level.trim() === "");
};
export const isValidLanguages = (language) => {
    return findEqualLanguages(language).length === 0;
};
