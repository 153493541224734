import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        selectRevision: [
            "Select revision to copy this professional experience item to:",
            "Выберите ревизию в которую нужно скопировать опыт:",
        ][index],
        modalTitle: ["Copy professional experience", "Копировать опыт"][index],
        successCopyMessage: ["Professional experience copied", "Опыт скопирован"][index],
        errorCopyMessage: ["Error when coping professional experience", "Ошибка копирования опыта"][index],
        noRevisions: [
            "You don't have revisions to copy professional experience to",
            "У вас нет ревизий в которые можно скопировать опыт",
        ][index],
    };
};
