import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        save: ["Save", "Сохранить"][index],
        share: ["Share & Download", "Поделиться & Скачать"][index],
        emptyDate: [
            "Date fields are empty or have an incorrect format",
            "Поля даты пусты или имеют неверный формат",
        ][index],
        emptyCustomTitle: [
            "Custom section must not be empty",
            "Поля пользовательских секций не должны быть пустыми",
        ][index],
        languageRepeat: ["Languages must be different", "Языки должны быть разлными"][index],
        languageLevelEmpty: ["Language level field is empty", "Поле 'Уровень языка' пустое"][index],
        wrongDate: [
            "Start date should be before end date",
            "Начальная дата должна быть раньше конечной",
        ][index],
        saveActiveTitle: ["Save changes", "Сохранить изменения"][index],
        saveDisabledTitle: ["There are no changes on the page", "На странице нет изменений"][index],
        getFileLink: ["Copied to clipboard", "Скопировано в буфер обмена"][index],
        getFileLinkError: ["Failed to get file link", "Не удалось получить ссылку на файл"][index],
        downloadFileLError: ["File download error", "Ошибка загрузки файла"][index],
        menu: ["Menu", "Меню"][index],
    };
};
