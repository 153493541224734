var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { signOut } from "./user";
import { DOC_PROCESSING_URL } from "./constants";
export const axiosInstance = axios.create({
    baseURL: DOC_PROCESSING_URL,
    headers: {
        "content-type": "application/json" /* MimeTypes.json */,
    },
    withCredentials: true,
});
export const updateAxiosToken = (token) => {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};
export const configResponseInterceptor = () => {
    axiosInstance.interceptors.response.use((response) => response, (error) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            yield signOut();
        }
        return Promise.reject(error);
    }));
};
