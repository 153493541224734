import React, { useState } from "react";
import { MenuItem } from "@components";
import { useTablet } from "@hooks";
import IconArrowDownRounded from "@assets/icon_arrow_down_rounded.svg";
import IconSort from "@assets/icon-sort.svg";
import { DropDownWrap } from "../DropDownWrap";
import styles from "./SimpleSelector.module.scss";
export function SimpleSelector({ options, selectedOption, onOptionSelect, showIcon, label, }) {
    const [isOpen, setIsOpen] = useState(false);
    const isTablet = useTablet();
    return (React.createElement(DropDownWrap, { isOpen: isOpen, toggleOpen: () => {
            setIsOpen(!isOpen);
        }, color: isTablet ? "secondary" : "tertiary", title: isTablet ? label : null, anchor: isTablet ? (React.createElement(IconSort, { className: styles.icon })) : (React.createElement(React.Fragment, null,
            React.createElement("span", { className: styles.anchor },
                React.createElement("span", { className: styles.label }, label && `${label}: `),
                React.createElement("span", { className: styles.variable }, options.find(({ displayId }) => displayId === selectedOption).value)),
            showIcon && React.createElement(IconArrowDownRounded, { className: styles.icon }))), variant: "text", iconOnly: isTablet }, options.map((option) => (React.createElement(MenuItem, { onClick: () => {
            setIsOpen(false);
            onOptionSelect(option);
        }, key: option.displayId, isSelected: option.displayId === selectedOption }, option.value)))));
}
