import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        title: ["Overview", "Общие сведения"][index],
        description: [
            "Write about your years of experience, industry, or skills. You can also talk about your achievements or previous job experiences",
            "Напишите о своем опыте работы, отрасли или навыках. Вы также можете рассказать о своих достижениях или предыдущем опыте работы",
        ][index],
        skills: ["Skills", "Основные навыки"][index],
        skill: ["Skill", "Основной навык"][index],
        addSkill: ["Add Skill", "Добавить Основной навык"][index],
    };
};
