import { languageIndex } from "@utils";
const ENGLISH_EN = "English";
const POLISH_EN = "Polish";
const GERMAN_EN = "German";
const ITALIAN_EN = "Italian";
const SPANISH_EN = "Spanish";
const FRENCH_EN = "French";
const LITHUANIAN_EN = "Lithuanian";
const RUSSIAN_EN = "Russian";
const CHINESE_EN = "Chinese";
const UZBEK_EN = "Uzbek";
const KAZAKH_EN = "Kazakh";
const BULGARIAN_EN = "Bulgarian";
const ARABIC_EN = "Arabic";
const BELARUSIAN_EN = "Belarusian";
const AZERBAIJANI_EN = "Azerbaijani";
const TURKISH_EN = "Turkish";
const UKRAINIAN_EN = "Ukrainian";
const ENGLISH_RU = "Английский";
const POLISH_RU = "Польский";
const GERMAN_RU = "Немецкий";
const ITALIAN_RU = "Итальянский";
const SPANISH_RU = "Испанский";
const FRENCH_RU = "Французский";
const LITHUANIAN_RU = "Литовский";
const RUSSIAN_RU = "Русский";
const CHINESE_RU = "Китайский";
const UZBEK_RU = "Узбекский";
const KAZAKH_RU = "Казахский";
const BULGARIAN_RU = "Болгарский";
const ARABIC_RU = "Арабский";
const BELARUSIAN_RU = "Белорусский";
const AZERBAIJANI_RU = "Азербайжанский";
const TURKISH_RU = "Турецкий";
const UKRAINIAN_RU = "Украинский";
export const BEGINNER_EN = "Beginner";
const PRE_INTERMEDIATE_EN = "Pre-intermediate";
const INTERMEDIATE_EN = "Intermediate";
const UPPER_INTERMEDIATE_EN = "Upper-intermediate";
const ADVANCED_EN = "Advanced";
const MASTERY_EN = "Mastery";
const NATIVE_EN = "Native";
export const BEGINNER_RU = "Начальный";
const PRE_INTERMEDIATE_RU = "Чуть выше начального";
const INTERMEDIATE_RU = "Средний";
const UPPER_INTERMEDIATE_RU = "Выше среднего";
const ADVANCED_RU = "Продвинутый";
const MASTERY_RU = "В совершенстве";
const NATIVE_RU = "Родной";
export const levelMapEn = {
    [BEGINNER_EN]: ["a1"],
    [PRE_INTERMEDIATE_EN]: ["a2"],
    [INTERMEDIATE_EN]: ["b1"],
    [UPPER_INTERMEDIATE_EN]: ["b2"],
    [ADVANCED_EN]: ["c1"],
    [MASTERY_EN]: ["c2"],
};
export const levelMapRu = {
    [BEGINNER_RU]: ["a1"],
    [PRE_INTERMEDIATE_RU]: ["a2"],
    [INTERMEDIATE_RU]: ["b1"],
    [UPPER_INTERMEDIATE_RU]: ["b2"],
    [ADVANCED_RU]: ["c1"],
    [MASTERY_RU]: ["c2"],
};
export const knownLanguagesEn = [
    ENGLISH_EN,
    POLISH_EN,
    GERMAN_EN,
    ITALIAN_EN,
    SPANISH_EN,
    FRENCH_EN,
    LITHUANIAN_EN,
    RUSSIAN_EN,
    CHINESE_EN,
    UZBEK_EN,
    KAZAKH_EN,
    BULGARIAN_EN,
    ARABIC_EN,
    BELARUSIAN_EN,
    AZERBAIJANI_EN,
    TURKISH_EN,
    UKRAINIAN_EN,
].map((lang) => lang.toLowerCase());
export const knownLanguagesRu = [
    ENGLISH_RU,
    POLISH_RU,
    GERMAN_RU,
    ITALIAN_RU,
    SPANISH_RU,
    FRENCH_RU,
    LITHUANIAN_RU,
    RUSSIAN_RU,
    CHINESE_RU,
    UZBEK_RU,
    KAZAKH_RU,
    BULGARIAN_RU,
    ARABIC_RU,
    BELARUSIAN_RU,
    AZERBAIJANI_RU,
    TURKISH_RU,
    UKRAINIAN_RU,
].map((lang) => lang.toLowerCase());
export const knownLevelsEn = [
    BEGINNER_EN,
    PRE_INTERMEDIATE_EN,
    INTERMEDIATE_EN,
    UPPER_INTERMEDIATE_EN,
    ADVANCED_EN,
    MASTERY_EN,
    NATIVE_EN,
].map((level) => level.toLowerCase());
export const knownLevelsRu = [
    BEGINNER_RU,
    PRE_INTERMEDIATE_RU,
    INTERMEDIATE_RU,
    UPPER_INTERMEDIATE_RU,
    ADVANCED_RU,
    MASTERY_RU,
    NATIVE_RU,
].map((level) => level.toLowerCase());
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        title: ["Language skills", "Языки"][index],
        placeholderLang: ["Language", "Язык"][index],
        placeholderLevel: ["Level", "Уровень"][index],
        add: ["Add Language", "Добавить Язык"][index],
        emptyLevelField: ["Language level field is empty", "Поле 'Уровень языка' пустое"][index],
        englishLanguage: [ENGLISH_EN, ENGLISH_RU][index],
        polishLanguage: [POLISH_EN, POLISH_RU][index],
        germanLanguage: [GERMAN_EN, GERMAN_RU][index],
        italianLanguage: [ITALIAN_EN, ITALIAN_RU][index],
        spanishLanguage: [SPANISH_EN, SPANISH_RU][index],
        frenchLanguage: [FRENCH_EN, FRENCH_RU][index],
        lithuanianLanguage: [LITHUANIAN_EN, LITHUANIAN_RU][index],
        russianLanguage: [RUSSIAN_EN, RUSSIAN_RU][index],
        chineseLanguage: [CHINESE_EN, CHINESE_RU][index],
        uzbekLanguage: [UZBEK_EN, UZBEK_RU][index],
        kazakhLanguage: [KAZAKH_EN, KAZAKH_RU][index],
        bulgarianLanguage: [BULGARIAN_EN, BULGARIAN_RU][index],
        arabicLanguage: [ARABIC_EN, ARABIC_RU][index],
        belarusianLanguage: [BELARUSIAN_EN, BELARUSIAN_RU][index],
        azerbaijaniLanguage: [AZERBAIJANI_EN, AZERBAIJANI_RU][index],
        turkishLanguage: [TURKISH_EN, TURKISH_RU][index],
        ukrainianLanguage: [UKRAINIAN_EN, UKRAINIAN_RU][index],
        beginnerLevel: [BEGINNER_EN, BEGINNER_RU][index],
        preIntermediateLevel: [PRE_INTERMEDIATE_EN, PRE_INTERMEDIATE_RU][index],
        intermediateLevel: [INTERMEDIATE_EN, INTERMEDIATE_RU][index],
        upperIntermediateLevel: [UPPER_INTERMEDIATE_EN, UPPER_INTERMEDIATE_RU][index],
        advancedLevel: [ADVANCED_EN, ADVANCED_RU][index],
        masteryLevel: [MASTERY_EN, MASTERY_RU][index],
        nativeLevel: [NATIVE_EN, NATIVE_RU][index],
    };
};
