export var LocalStorageKeys;
(function (LocalStorageKeys) {
    LocalStorageKeys["authToken"] = "auth_token";
    LocalStorageKeys["PROFESSIONAL_EXPERIENCE_DROPPABLE_TYPE"] = "professional_experience";
    LocalStorageKeys["DATES_INTERSECTION_WARNING"] = "dates-intersection-warning-hidden";
    LocalStorageKeys["SPELL_CHECK"] = "localStorageClosedKey";
})(LocalStorageKeys || (LocalStorageKeys = {}));
export const safeLocalStorageGet = (key) => {
    try {
        return localStorage.getItem(key);
    }
    catch (error) {
        // The user probably blocks cookies.
        return "";
    }
};
export const safeLocalStorageSet = (key, value) => {
    try {
        localStorage.setItem(key, value);
    }
    catch (error) {
        // Do nothing. The user probably blocks cookies.
    }
};
export const safeLocalStorageRemove = (key) => {
    try {
        localStorage.removeItem(key);
    }
    catch (error) {
        // Do nothing. The user probably blocks cookies.
    }
};
