import React from "react";
import styles from "./CircularProgress.module.scss";
export function CircularProgress({ size = 100 }) {
    return (React.createElement("div", { className: styles.loader, style: {
            width: `${size}px`,
            height: `${size}px`,
        } },
        React.createElement("svg", { className: styles.circularLoader, viewBox: "25 25 50 50" },
            React.createElement("circle", { className: styles.loaderPath, cx: "50", cy: "50", r: "20", fill: "none" }))));
}
