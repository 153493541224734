import React from "react";
import { PageLoader } from "@components";
import { Certifications, CustomSections, Education, GeneralInfo, Language, OverviewComponent, PersonalStrengths, ProfessionalExperience, Trainings, } from "./sections";
export function CV({ isLoading, onSectionAdd }) {
    return isLoading ? (React.createElement(PageLoader, null)) : (React.createElement(React.Fragment, null,
        React.createElement(GeneralInfo, null),
        React.createElement(OverviewComponent, null),
        React.createElement(ProfessionalExperience, null),
        React.createElement(Education, null),
        React.createElement(Language, null),
        React.createElement(Certifications, null),
        React.createElement(Trainings, null),
        React.createElement(PersonalStrengths, null),
        React.createElement(CustomSections, { onSectionAdd: onSectionAdd })));
}
