import { isUILanguage, Language } from "@types";
export const addIndex = (array, index) => {
    if (!array.includes(index)) {
        array.push(index);
    }
    return [...array];
};
export const removeIndex = (array, index) => array.filter((a) => a !== index);
export const languageIndex = (language) => {
    if (isUILanguage(language)) {
        return Object.keys(Language).indexOf(language);
    }
    return 0;
};
export const clearStringArray = (field) => {
    if (field) {
        return field.map((f) => f.trim()).filter((str) => str !== "");
    }
    return [];
};
/**
 * Filter PointsDisplay array to filter out points with empty values (strings)
 */
export const clearPoints = (points) => {
    if (points) {
        return points.map((p) => (Object.assign(Object.assign({}, p), { value: p.value.trim() }))).filter(({ value }) => value !== "");
    }
    return [];
};
/**
 * Checks if two arrays have at least one equal value
 */
export const hasEqualValue = (a, b) => {
    if (a && b) {
        for (let i = 0; i < a.length; i += 1) {
            for (let j = 0; j < b.length; j += 1) {
                if (a[i] === b[j])
                    return true;
            }
        }
    }
    return false;
};
