import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        spellCheckWarning: [
            "Please, enable browser spell check, to avoid typos. To open browser settings open link: chrome://settings/languages",
            "Пожалуйста, включите проверку орфографии в настройках браузера, чтобы избежать опечаток. Для этого перейдите по ссылке: chrome://settings/languages",
        ][index],
        editRestrictionWarning: [
            "You can not edit CV of this user because are not manager of his department, and not HR!",
            "Вы не можете редактировать CV текущего пользователя так как не являетесь менеджером его департамента и не являетесь HR!",
        ][index],
        customSectionAddedNotification: [
            "Custom section successfully added",
            "Кастомная секция уcпешно добавлена",
        ][index],
    };
};
