const SCROLL_PADDING = 20;
export const handleScroll = (container, currentActiveHeading, menuItems) => {
    const atTop = container.scrollTop <= SCROLL_PADDING;
    const atBottom = container.scrollTop + container.clientHeight >= container.scrollHeight - SCROLL_PADDING;
    if (atTop) {
        return menuItems[0].id;
    }
    if (atBottom) {
        return menuItems[menuItems.length - 1].id;
    }
    const scrollPosition = container.scrollTop + (container.getBoundingClientRect().top + container.clientHeight / 2);
    return menuItems
        .map(({ id, submenu }) => ({
        element: document.getElementById(id),
        hasSubmenu: !!submenu,
    }))
        .filter(({ element }) => !!element)
        .reduce((activeHeadingId, { element, hasSubmenu }) => {
        let marginTop = 0;
        let marginBottom = 0;
        if (element instanceof Element) {
            const style = window.getComputedStyle(element);
            marginTop = parseInt(style.marginTop, 10);
            marginBottom = parseInt(style.marginBottom, 10);
        }
        const elementContainer = element.getBoundingClientRect();
        const topHeadingContainer = elementContainer.top + container.scrollTop - marginTop;
        let bottomHeadingContainer = topHeadingContainer + element.clientHeight + marginBottom;
        if (hasSubmenu && element.children.length) {
            // NOTE: if element has nested sections (i.e. "Professional Experience" section)
            // bottom of section header will be used as bottom of anchor
            const [headerElement] = element.children;
            const { marginBottom: headerMarginBottom, height } = window.getComputedStyle(headerElement);
            const { paddingTop } = window.getComputedStyle(element);
            const headerHeight = parseInt(height, 10) + parseInt(paddingTop, 10) + parseInt(headerMarginBottom, 10);
            bottomHeadingContainer = topHeadingContainer + headerHeight;
        }
        const isElementInView = scrollPosition >= topHeadingContainer && scrollPosition <= bottomHeadingContainer;
        return isElementInView ? element.id : activeHeadingId;
    }, currentActiveHeading);
};
