import { useCallback, useEffect, useState } from "react";
const OFFSET_Y_PX = 3;
const MIN_VIEWPORT_OFFSET_PX = 4;
const calculatePosition = (anchorRect, element, primaryPositionX, primaryPositionY, offsetConfig = {
    minViewPortOffset: MIN_VIEWPORT_OFFSET_PX,
    offsetY: OFFSET_Y_PX,
}) => {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    const { width: contentWidth, height: contentHeight } = element;
    const { minViewPortOffset, offsetY } = offsetConfig;
    let left;
    let top;
    const isEnoughSpaceOnRight = anchorRect.left + contentWidth <= windowWidth - minViewPortOffset;
    const isEnoughSpaceOnLeft = anchorRect.right - contentWidth >= minViewPortOffset;
    const isEnoughSpaceCenter = isEnoughSpaceOnRight && isEnoughSpaceOnLeft;
    const isPrimaryXLeft = primaryPositionX === "left";
    const isPrimaryXRight = primaryPositionX === "right";
    const isPrimaryXCenter = primaryPositionX === "center";
    if ((isPrimaryXLeft && isEnoughSpaceOnLeft) ||
        (isPrimaryXRight && isEnoughSpaceOnLeft && !isEnoughSpaceOnRight) ||
        (isPrimaryXCenter && isEnoughSpaceOnLeft && !isEnoughSpaceCenter)) {
        left = anchorRect.right - contentWidth;
    }
    else if ((isPrimaryXRight && isEnoughSpaceOnRight) ||
        (isPrimaryXLeft && !isEnoughSpaceOnLeft && isEnoughSpaceOnRight) ||
        (isPrimaryXCenter && isEnoughSpaceOnRight && !isEnoughSpaceCenter)) {
        left = anchorRect.left;
    }
    else if (isPrimaryXCenter && isEnoughSpaceCenter) {
        left = anchorRect.left + (anchorRect.width - contentWidth) / 2;
    }
    else {
        left = (windowWidth - contentWidth) / 2;
    }
    const isEnoughSpaceOnTop = anchorRect.top - contentHeight - offsetY >= minViewPortOffset;
    const isEnoughSpaceOnBottom = anchorRect.bottom + contentHeight + offsetY <= windowHeight;
    const isPrimaryYTop = primaryPositionY === "top";
    const isPrimaryYBottom = primaryPositionY === "bottom";
    if ((isPrimaryYTop && isEnoughSpaceOnTop) ||
        (isPrimaryYBottom && !isEnoughSpaceOnBottom && isEnoughSpaceOnTop)) {
        top = anchorRect.top - contentHeight - offsetY;
    }
    else {
        top = anchorRect.bottom + offsetY;
    }
    return { top, left };
};
export const useOverlay = (isOpen, contentRef, anchorRef, primaryPositionX, primaryPositionY = "bottom", offsetConfig) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [parentWidth, setParentWidth] = useState(0);
    const [positionCalculated, setPositionCalculated] = useState(false);
    const updatePosition = useCallback(() => {
        if (anchorRef.current && contentRef.current) {
            const anchorRect = anchorRef.current.getBoundingClientRect();
            const contentRect = contentRef.current.getBoundingClientRect();
            const newPosition = calculatePosition(anchorRect, contentRect, primaryPositionX, primaryPositionY, offsetConfig);
            setParentWidth(anchorRect.width);
            setPosition(newPosition);
            setPositionCalculated(true);
        }
    }, [contentRef, anchorRef, primaryPositionX]);
    useEffect(() => {
        const handleResize = () => {
            if (isOpen) {
                updatePosition();
            }
            else {
                setPositionCalculated(false);
            }
        };
        if (isOpen) {
            updatePosition();
        }
        else {
            setPositionCalculated(false);
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isOpen, updatePosition]);
    return [positionCalculated, position, parentWidth];
};
