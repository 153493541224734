var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState, Suspense } from "react";
import { SectionTitle, ListField } from "@components";
import { useAppDispatch, useAppSelector } from "@hooks";
import { setCV, translateSubstring } from "@store";
import { createCertificatePoint } from "@utils";
import { HeadingIds } from "@constants";
import { LinkMenu } from "./components/LinkMenu/LinkMenu";
import { fields } from "./localization";
import commonStyles from "../../CV.common.scss";
const CertificateOptionsLazy = React.lazy(() => import("./certificateOptions").then((module) => ({ default: module.CertificateOptions })));
export function Certifications() {
    var _a;
    const dispatch = useAppDispatch();
    const { cv } = useAppSelector((state) => state.cv);
    const { language } = useAppSelector((state) => state.ui);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [loadCertificateOptions, setLoadCertificateOptions] = useState(false);
    const { title, placeholder, addLabel } = fields(language);
    const certifications = ((_a = cv === null || cv === void 0 ? void 0 : cv.certificatesDisplay) === null || _a === void 0 ? void 0 : _a.length)
        ? cv.certificatesDisplay
        : [createCertificatePoint()];
    const hideCertifications = (cv === null || cv === void 0 ? void 0 : cv.hideCertifications) || false;
    const setCertifications = (certificatesDisplay) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { certificatesDisplay })));
    };
    const onKeyDownHandler = () => {
        setLoadCertificateOptions(true);
    };
    const handleAdd = () => {
        if (certifications.length === 0) {
            setCertifications([createCertificatePoint(), createCertificatePoint()]);
        }
        else {
            setCertifications([...certifications, createCertificatePoint()]);
        }
    };
    const handleItemTranslation = (itemIndex, text, start, end) => __awaiter(this, void 0, void 0, function* () {
        const translatedResult = yield dispatch(translateSubstring({ text, start, end })).unwrap();
        setCertifications(certifications.map((point, i) => {
            if (i === itemIndex) {
                return Object.assign(Object.assign({}, point), { value: translatedResult });
            }
            return point;
        }));
    });
    const certificateLinkMenuComponent = useCallback((props) => (React.createElement(LinkMenu, { key: certifications[props.rowIndex].displayId, value: certifications[props.rowIndex].linkToCertificate || "", withBadge: !!certifications[props.rowIndex].linkToCertificate, disabled: hideCertifications, handleMenuToggle: setIsMenuOpen, onConfirm: (linkToCertificate) => {
            setCertifications(certifications.map((point, i) => {
                if (i === props.rowIndex) {
                    return Object.assign(Object.assign({}, point), { linkToCertificate });
                }
                return point;
            }));
        } })), [hideCertifications, certifications]);
    const autocompleteCertificateOptions = (id) => (React.createElement(Suspense, { fallback: React.createElement("div", null) },
        React.createElement(CertificateOptionsLazy, { index: id })));
    return (React.createElement("section", { id: HeadingIds.Certifications, className: commonStyles.section },
        React.createElement(SectionTitle, { title: title, handleAdd: handleAdd, isSectionHidden: hideCertifications, addButtonLabel: addLabel, handleSectionHide: () => {
                dispatch(setCV(Object.assign(Object.assign({}, cv), { hideCertifications: !hideCertifications })));
            } }),
        React.createElement(ListField, { title: title, itemTitle: placeholder, points: certifications, onEdit: setCertifications, onKeyDownHandler: onKeyDownHandler, readOnly: hideCertifications, showAddBtn: false, onItemSelection: handleItemTranslation, additionalComponent: certificateLinkMenuComponent, isDragDisabled: isMenuOpen, autocompleteComponent: loadCertificateOptions ? autocompleteCertificateOptions : null })));
}
