import { knownLanguagesEn, knownLanguagesRu, knownLevelsEn, knownLevelsRu, levelMapEn, levelMapRu, } from "@pages/CvPage/components/CV/sections/Language/localization";
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const isRussian = (str) => /[а-яё]/i.test(str);
export const parseLanguageString = (inputString) => {
    const isInputRussian = isRussian(inputString);
    const knownLanguages = isInputRussian ? knownLanguagesRu : knownLanguagesEn;
    const knownLevels = isInputRussian ? knownLevelsRu : knownLevelsEn;
    const levelMap = isInputRussian ? levelMapRu : levelMapEn;
    // Convert the input string to lowercase for comparison
    const lowerInputString = inputString.toLowerCase();
    // Remove additional details such as (B2) if present
    let cleanInputString = lowerInputString.replace(/\s*\(.*?\)\s*/g, " ").trim();
    let language;
    let level;
    // Find the level from the knownLevelsEn
    let levelFound;
    Object.entries(levelMap).forEach(([key, values]) => {
        if (!levelFound && values.some((val) => cleanInputString.includes(val.toLowerCase()))) {
            levelFound = key;
            if (key && levelMap[key]) {
                cleanInputString = cleanInputString.replace(levelMap[key][0], key);
            }
        }
    });
    if (!levelFound) {
        // Check if the string contains a known level
        [levelFound] = knownLevels
            .filter((lvl) => cleanInputString.endsWith(lvl))
            .sort((a, b) => b.localeCompare(a));
    }
    if (levelFound) {
        const languagePart = cleanInputString
            .substring(0, cleanInputString.lastIndexOf(levelFound))
            .trim();
        const languageFound = knownLanguages.find((lang) => languagePart.startsWith(lang));
        language = languageFound ? capitalizeFirstLetter(languageFound) : "";
        level = capitalizeFirstLetter(levelFound);
    }
    else if (cleanInputString.includes(":")) {
        // Split the string into language and level
        [language, level] = inputString.split(":").map((part) => part.trim());
    }
    else {
        // Otherwise, consider the entire string as the language
        language = inputString;
        level = "";
    }
    return { language, level };
};
