import { createSlice } from "@reduxjs/toolkit";
import { getTemplates, selectTemplate } from "@store/templates/templatesAction";
const initialState = {
    templates: [],
    isGetTemplatesLoading: false,
    getTemplatesError: "",
    selectedTemplate: null,
};
export const templatesSlice = createSlice({
    name: "templates",
    initialState,
    reducers: {},
    extraReducers: {
        [getTemplates.pending.type]: (state) => {
            state.isGetTemplatesLoading = true;
        },
        [getTemplates.fulfilled.type]: (state, action) => {
            state.templates = action.payload;
            state.isGetTemplatesLoading = false;
        },
        [getTemplates.rejected.type]: (state, action) => {
            state.getTemplatesError = action.payload;
            state.isGetTemplatesLoading = false;
        },
        [selectTemplate.type]: (state, action) => {
            state.selectedTemplate = action.payload;
        },
    },
});
export default templatesSlice.reducer;
