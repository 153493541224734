import { useState } from "react";
const BACKUP_SIZE = 10;
export const useRevert = (restoreCallback) => {
    const [backup, setBackup] = useState([]);
    const restoreBackup = () => {
        const updatedBackup = [...backup];
        const lastSaved = updatedBackup.pop();
        setBackup(updatedBackup);
        restoreCallback(lastSaved);
    };
    const updateBackup = (value) => {
        setBackup((backups) => {
            const updatedBackup = [...backups, value];
            if (updatedBackup.length > BACKUP_SIZE) {
                updatedBackup.shift();
            }
            return updatedBackup;
        });
    };
    return [restoreBackup, updateBackup, !backup.length];
};
