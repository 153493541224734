import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { DropDownWrap, MenuItem } from "@components";
import { Language } from "@types";
import ArrowDown from "@assets/icon_arrow_down_rounded.svg";
import { useAppDispatch, useAppSelector } from "@hooks";
import { selectTemplate, setLanguage, translateHeaders } from "@store";
import { Techforward } from "@constants";
import { fields } from "./localization";
import styles from "./TemplateSelector.module.scss";
export function TemplateSelector({ setIsCVScrollable }) {
    const [isCvFileSelectOpen, setIsCvFileSelectOpen] = useState(false);
    const { templates, selectedTemplate } = useAppSelector((state) => state.templates);
    const { language } = useAppSelector((state) => state.ui);
    const { targetUser } = useAppSelector((state) => state.users);
    const { selectedRevision } = useAppSelector((state) => state.revisions);
    const dispatch = useAppDispatch();
    const field = fields(language);
    useEffect(() => {
        setIsCVScrollable(!isCvFileSelectOpen);
    }, [isCvFileSelectOpen]);
    return (!!templates.length && (React.createElement(DropDownWrap, { isOpen: isCvFileSelectOpen, toggleOpen: () => setIsCvFileSelectOpen(!isCvFileSelectOpen), anchor: React.createElement(React.Fragment, null,
            selectedTemplate && (React.createElement("p", { className: styles.selectedCvName },
                React.createElement("span", { className: styles.templateLabel },
                    field.template,
                    ":"),
                React.createElement("span", { className: styles.templateName }, selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.displayName),
                React.createElement("span", { className: styles.selectCvLanguage },
                    "[", selectedTemplate === null || selectedTemplate === void 0 ? void 0 :
                    selectedTemplate.language,
                    "]"))),
            React.createElement(ArrowDown, { className: clsx(styles.dropdownButtonIcon, isCvFileSelectOpen && styles.dropdownButtonClicked) })) }, templates.map((conf) => (React.createElement(MenuItem, { onClick: () => {
            dispatch(setLanguage(conf.displayName === Techforward ? Language.RU : Language.EN));
            dispatch(selectTemplate(conf));
            dispatch(translateHeaders({
                email: targetUser.email,
                revisionName: selectedRevision.name,
                to: conf.language,
                from: language,
            }));
            setIsCvFileSelectOpen(false);
        }, key: conf.filePath, isSelected: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.formatName) === conf.formatName },
        conf.displayName,
        React.createElement("span", { className: styles.selectCvLanguage },
            "[",
            conf.language,
            "]")))))));
}
