var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import { AnalyticsId, dictionary } from "@constants";
import { Button, IconButton, ConfirmModal } from "@components";
import UploadIcon from "@assets/icon_upload.svg";
import { addRevision, setCV, setSelectedRevision, showNotification, uploadCV, } from "@store";
import { useAppDispatch, useAppSelector } from "@hooks";
import { fields } from "./localization";
import styles from "./CvFileUploader.module.scss";
const TEN_MEGABYTES = 10 * 1024 * 1024;
export function CvFileUploader({ setSelectedRevisionUrlParam, isFloatingMode = false, onSaveChanges, onDiscardChanges, }) {
    const [isUploadButtonActive, setIsUploadButtonActive] = useState(true);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const dispatch = useAppDispatch();
    const { revisions, cvPreview, isUploadCvLoading, selectedRevision } = useAppSelector((state) => state.revisions);
    const { isViewOnly, language } = useAppSelector((state) => state.ui);
    const { isCVChanged } = useAppSelector((state) => state.cv);
    const { targetUser } = useAppSelector((state) => state.users);
    const fileInput = useRef(null);
    useEffect(() => {
        setIsUploadButtonActive(cvPreview === null);
    }, [cvPreview]);
    const handleSelectFile = () => fileInput.current.click();
    const handleButtonClick = () => {
        if (isCVChanged) {
            setShowConfirmModal(true);
        }
        else {
            handleSelectFile();
        }
    };
    const generateTemplateRevision = () => {
        const templateRevisionSignature = `${targetUser.firstName}_${targetUser.lastName}_`;
        const templateRevisionsNumbers = revisions
            .filter((revision) => revision.name.includes(templateRevisionSignature))
            .map((revision) => {
            const matches = /\((\d*)\)/.exec(revision.name);
            return matches ? +matches[1] : 0;
        });
        const maxTemplateRevision = templateRevisionsNumbers.length > 0 ? Math.max(...templateRevisionsNumbers) : 0;
        const now = new Date();
        const postfix = templateRevisionsNumbers.length > 0 ? ` (${maxTemplateRevision + 1})` : "";
        const res = `${targetUser.firstName}_${targetUser.lastName}_${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}${postfix}`;
        return {
            name: res,
            isPreview: true,
        };
    };
    const field = fields(language);
    const { save, discardChanges, saveChanges, saveChangesSubtitle } = dictionary(language);
    const handleUploadFile = (files) => __awaiter(this, void 0, void 0, function* () {
        const selectedFile = files[0];
        if (selectedFile.size > TEN_MEGABYTES)
            return;
        fileInput.current.value = null;
        try {
            const uploadedCvData = yield dispatch(uploadCV({ email: targetUser.email, file: selectedFile })).unwrap();
            const newRevision = generateTemplateRevision();
            setSelectedRevisionUrlParam(newRevision.name);
            yield dispatch(addRevision(newRevision));
            dispatch(setCV(uploadedCvData));
            dispatch(setSelectedRevision(newRevision));
            dispatch(showNotification({
                message: field.uploadCvSuccess,
                isSuccess: true,
            }));
        }
        catch (_a) {
            dispatch(showNotification({
                message: field.uploadCvError,
                isSuccess: false,
            }));
        }
    });
    return (React.createElement("div", { className: styles.container },
        React.createElement("input", { accept: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" /* MimeTypes.docx */, type: "file", style: { display: "none" }, ref: fileInput, onChange: ({ target: { files } }) => handleUploadFile(files) }),
        isFloatingMode ? (React.createElement(Button, { color: "secondary", variant: "contained", onClick: handleButtonClick, disabled: !isUploadButtonActive || isViewOnly, id: AnalyticsId.upload, isLoading: isUploadCvLoading, leftIcon: React.createElement(UploadIcon, null) }, field.uploadActiveTitle)) : (React.createElement(IconButton, { icon: React.createElement(UploadIcon, null), color: "primary", variant: "outlined", onClick: handleButtonClick, title: isUploadButtonActive ? field.uploadActiveTitle : field.uploadDisabledTitle, disabled: !isUploadButtonActive || isViewOnly, id: AnalyticsId.upload, isLoading: isUploadCvLoading })),
        showConfirmModal && (React.createElement(ConfirmModal, { isOpen: true, title: saveChanges, message: saveChangesSubtitle.replace("$REVISION", selectedRevision.name), saveBtnText: save, cancelBtnText: discardChanges, onSave: () => __awaiter(this, void 0, void 0, function* () {
                yield onSaveChanges();
                setShowConfirmModal(false);
                handleSelectFile();
            }), onClose: () => {
                setShowConfirmModal(false);
            }, onCancel: () => {
                onDiscardChanges();
                setShowConfirmModal(false);
                handleSelectFile();
            } }))));
}
