var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { SectionTitle, ListField } from "@components";
import { useAppDispatch, useAppSelector } from "@hooks";
import { setCV, translateSubstring } from "@store";
import { HeadingIds } from "@constants";
import { createDisplayPoint } from "@utils";
import { isValidEducationField } from "@pages/CvPage/components/CV/components";
import { fields } from "./localization";
import commonStyles from "../../CV.common.scss";
export function Education() {
    var _a;
    const dispatch = useAppDispatch();
    const { cv } = useAppSelector((state) => state.cv);
    const { language } = useAppSelector((state) => state.ui);
    const field = fields(language);
    const education = ((_a = cv === null || cv === void 0 ? void 0 : cv.educationDisplay) === null || _a === void 0 ? void 0 : _a.length)
        ? cv.educationDisplay
        : [createDisplayPoint()];
    const setEducation = (educationDisplay) => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { educationDisplay })));
    };
    const handleAdd = () => {
        if (education.length === 0) {
            setEducation([createDisplayPoint(), createDisplayPoint()]);
        }
        else {
            setEducation([...education, createDisplayPoint()]);
        }
    };
    const handleSelectionAction = (itemIndex, text, selectionStart, selectionEnd) => __awaiter(this, void 0, void 0, function* () {
        const result = yield dispatch(translateSubstring({ text, start: selectionStart, end: selectionEnd })).unwrap();
        setEducation(education.map((point, i) => {
            if (i === itemIndex) {
                return Object.assign(Object.assign({}, point), { value: result });
            }
            return point;
        }));
    });
    return (React.createElement("section", { id: HeadingIds.Education, className: commonStyles.section },
        React.createElement(SectionTitle, { title: field.title, handleAdd: handleAdd, addButtonLabel: field.addEducation }),
        React.createElement(ListField, { itemTitle: field.placeholder, showAddBtn: false, points: education, onEdit: setEducation, onItemSelection: handleSelectionAction, isValidFunction: isValidEducationField, validationMessage: field.validationMessage, maxLength: 300, inputType: "textarea", isAutoGrowing: true })));
}
