var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import clsx from "clsx";
import { SelectionToolProvider } from "../SelectionToolProvider";
import { TextArea } from "../TextArea";
import commonStyles from "@pages/CvPage/components/CV/CV.common.scss";
export function TextAreaWithSelectionTool(_a) {
    var { hasError, wrapClassName, className, forwardedRef, disabled, maxLength = 1400, rows = 7, readOnly, 
    // SelectionToolProvider props
    value, icon, tooltip, handleValueChange, handleSelectionAction, handleSelectionActionError, preset, isSelectionFeatureEnabled, isAutoGrowing } = _a, props = __rest(_a, ["hasError", "wrapClassName", "className", "forwardedRef", "disabled", "maxLength", "rows", "readOnly", "value", "icon", "tooltip", "handleValueChange", "handleSelectionAction", "handleSelectionActionError", "preset", "isSelectionFeatureEnabled", "isAutoGrowing"]);
    return (React.createElement(SelectionToolProvider, { type: "textarea", value: value, icon: icon, tooltip: tooltip, handleValueChange: handleValueChange, handleSelectionAction: handleSelectionAction, handleSelectionActionError: handleSelectionActionError, preset: preset, disabled: disabled || readOnly, isSelectionFeatureEnabled: isSelectionFeatureEnabled }, ({ containerRef, value: innerValue, assistButton, onSelect, onBlur, onMouseDown }) => (React.createElement(TextArea, Object.assign({}, props, { hasError: hasError, forwardedRef: forwardedRef, wrapClassName: clsx(commonStyles.input, wrapClassName, className), rows: rows, maxLength: maxLength, disabled: disabled, readOnly: readOnly, containerRef: containerRef, assistButton: assistButton, value: innerValue, onChange: ({ target: { value: val } }) => handleValueChange(val), onSelect: onSelect, onBlur: onBlur, onMouseDown: onMouseDown, isAutoGrowing: isAutoGrowing })))));
}
