import React from "react";
import { Link } from "react-router-dom";
import { cvPath } from "@utils";
import { useAppSelector } from "@hooks";
import { Button } from "@components";
import NotFoundIcon from "@assets/not_found.svg";
import styles from "./NotFound.module.scss";
export function NotFound() {
    const { currentUser } = useAppSelector((state) => state.users);
    return (React.createElement("div", { className: styles.wrap },
        React.createElement(NotFoundIcon, { className: styles.icon }),
        React.createElement("div", { className: styles.warning }, "404 Page not found"),
        React.createElement("div", { className: styles.description }, "Sorry, we can\u2019t find that page! Maybe you do not have access rights."),
        currentUser && (React.createElement(Link, { to: cvPath.getPath(currentUser.email), className: styles.homeButton },
            React.createElement(Button, null, "GO TO HOME PAGE")))));
}
