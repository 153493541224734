var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { AssistButton, DropDown, DropDownWrap, InputWithSelectionTool, Switch, TextAreaWithSelectionTool, IconButton, MenuItem, TextField, ListField, } from "@components";
import ArrowDownIcon from "@assets/icon_arrow_down.svg";
import ArrowDownIconThin from "@assets/icon_arrow_down_thin.svg";
import IconCopy from "@assets/icon_copy.svg";
import IconCopyShare from "@assets/icon_copy_plus.svg";
import IconTrash from "@assets/icon_trash.svg";
import { addId, uniqueId } from "@utils";
import { useAppDispatch, useAppSelector } from "@hooks";
import { beautifyString, translateSubstring } from "@store";
import { useAsyncAction } from "@hooks/useAsyncAction";
import UndoIcon from "@assets/undo.svg";
import { useRevert } from "@hooks/useRevert";
import { dictionary } from "@constants";
import { isValidCustomerField, isValidRoleField } from "../../index";
import { fields } from "./localization";
import commonStyles from "../../../../CV.common.scss";
import styles from "./ProfessionalExperienceItem.module.scss";
const DatePicker = React.lazy(() => import("@components/DatePicker").then((module) => ({ default: module.DatePicker })));
export const ALLOWED_CUSTOMER = "Software Development Companies";
export const EMPTY_PROF_EXP = {
    startDate: null,
    endDate: null,
    isPresent: true,
    customer: ALLOWED_CUSTOMER,
    role: "",
    tools: "",
    projectDescription: "",
    responsibilitiesDisplay: [addId("")],
    displayId: uniqueId(),
    isSelected: false,
};
const IS_PROJECT_SELECTOR_FEATURE_ACTIVE = false;
const MOCK_PROJECTS = [
    { id: "1", name: "Recon" },
    { id: "2", name: "SPQ" },
    { id: "3", name: "PeerManager" },
    { id: "4", name: "DataLark" },
    { id: "5", name: "SAP" },
    { id: "6", name: "ECO" },
];
const ALLOWED_ROLES = [
    "Software Engineer",
    "Business Analyst",
    "Quality Assurance Engineer",
    "Team Lead",
    "Tech Lead",
];
export function ProfessionalExperienceItem({ prof, index, dateWarningIndexes, onEdit, onEditArray, onCopy, onCopyToRevision, onDelete, moveUp, moveDown, readOnly, totalLength, }) {
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [projects] = useState(MOCK_PROJECTS);
    const [selectedProject, setSelectedProject] = useState();
    const [isPresent, setIsPresent] = useState(prof.endDate === null);
    const [isActionsOpen, setIsActionsOpen] = useState(false);
    const [storedEndDate, setStoredEndDate] = useState(null);
    const dispatch = useAppDispatch();
    const focusRef = useRef(null);
    const { language, isDatesIntersectionWarningHidden } = useAppSelector((state) => state.ui);
    const { isTriedToSave } = useAppSelector((state) => state.cv);
    const translateField = (field) => (text, start, end) => __awaiter(this, void 0, void 0, function* () {
        const result = yield dispatch(translateSubstring({ text, start, end })).unwrap();
        onEdit(index, field, result);
    });
    const [popFromBackup, saveToBackup, isBackUpEmpty] = useRevert((value) => onEdit(index, "projectDescription", value));
    const [performDescriptionAction, isDescriptionActionInProgress] = useAsyncAction(() => __awaiter(this, void 0, void 0, function* () {
        const result = yield dispatch(beautifyString({ text: prof.projectDescription })).unwrap();
        saveToBackup(prof.projectDescription);
        onEdit(index, "projectDescription", result);
    }));
    const handleResponsibilitySelectAction = (itemIndex, text, start, end) => __awaiter(this, void 0, void 0, function* () {
        const result = yield dispatch(translateSubstring({ text, start, end })).unwrap();
        onEdit(index, "responsibilitiesDisplay", prof.responsibilitiesDisplay.map((point, i) => {
            if (i === itemIndex) {
                return Object.assign(Object.assign({}, point), { value: result });
            }
            return point;
        }));
    });
    useEffect(() => {
        if (prof.endDate === null) {
            setIsPresent(true);
        }
    }, [prof.endDate, index, onEdit]);
    const field = fields(language);
    const { undo } = dictionary(language);
    const handleProjectSelect = (id) => {
        setSelectedProject(projects.find((cat) => cat.id === id));
    };
    useEffect(() => {
        if (focusRef.current && prof.isSelected) {
            focusRef.current.scrollIntoView({ behavior: "smooth" });
            focusRef.current.focus();
            focusRef.current.animate([{ borderColor: "#e9ecf2" }, { borderColor: "#3f75ff" }, { borderColor: "#e9ecf2" }], {
                duration: 800,
                easing: "ease-in-out",
                iterations: 3,
            });
        }
    }, [prof.isSelected, index]);
    return (React.createElement("div", { className: clsx(styles.container), id: prof.displayId, ref: focusRef },
        React.createElement("header", { className: styles.header },
            React.createElement("p", { className: "title-medium" }, field.projectInfo),
            React.createElement("div", { className: styles.controls },
                React.createElement("span", { className: styles.serialNumber },
                    index + 1,
                    "/",
                    totalLength),
                React.createElement(IconButton, { onClick: () => moveUp(prof.displayId), icon: React.createElement(ArrowDownIconThin, null), color: "secondary", title: field.buttonUp, disabled: index === 0 }),
                React.createElement(IconButton, { onClick: () => moveDown(prof.displayId), icon: React.createElement(ArrowDownIconThin, { className: styles.rotate }), color: "secondary", title: field.buttonDown, disabled: index === totalLength - 1 }),
                React.createElement(DropDownWrap, { isOpen: isActionsOpen, disabled: readOnly, iconOnly: true, toggleOpen: () => setIsActionsOpen(!isActionsOpen), color: "secondary", variant: "contained", title: field.copy, anchor: React.createElement(IconCopyShare, null) },
                    React.createElement(MenuItem, { selectable: false, onClick: () => {
                            onCopy(index);
                            setIsActionsOpen(false);
                        }, leftIcon: React.createElement(IconCopy, null) }, field.duplicateProject),
                    React.createElement(MenuItem, { selectable: false, onClick: () => {
                            onCopyToRevision(prof);
                            setIsActionsOpen(false);
                        }, leftIcon: React.createElement(IconCopyShare, null) }, field.duplicateProjectToRevision)),
                totalLength > 1 && (React.createElement(IconButton, { onClick: () => onDelete(index), icon: React.createElement(IconTrash, null), color: "secondary", disabled: readOnly, title: field.deleteProject })))),
        React.createElement("section", { className: styles.inputs },
            React.createElement("div", { className: styles.inputContainer },
                React.createElement("p", { className: styles.title }, field.customer),
                React.createElement(TextField, { placeholder: field.customer, maxLength: 50, readOnly: readOnly, value: prof.customer, onChange: ({ target: { value } }) => onEdit(index, "customer", value) }),
                React.createElement("p", { className: clsx(commonStyles.warningMessage, isValidCustomerField(prof.customer, ALLOWED_CUSTOMER) &&
                        commonStyles.warningMessageVisible) }, field.companyWarning)),
            React.createElement("div", { className: styles.inputContainer },
                React.createElement("p", { className: styles.title }, field.role),
                React.createElement(InputWithSelectionTool, { placeholder: field.role, maxLength: 50, readOnly: readOnly, value: prof.role, handleValueChange: (value) => onEdit(index, "role", value), handleSelectionAction: translateField("role"), autocompleteList: ALLOWED_ROLES }),
                React.createElement("p", { className: clsx(commonStyles.warningMessage, isValidRoleField(prof.role, ALLOWED_ROLES) && commonStyles.warningMessageVisible) },
                    field.positionWarning,
                    " ",
                    ALLOWED_ROLES.join(", "),
                    "."))),
        React.createElement("section", { className: styles.inputs },
            React.createElement("div", { className: styles.inputContainer },
                React.createElement("div", { className: styles.datePickers },
                    React.createElement("div", { className: styles.dateSelectors },
                        React.createElement("p", { className: clsx(styles.dateSelectorsTitle, styles.required) }, field.start),
                        React.createElement(React.Suspense, { fallback: React.createElement("div", { className: styles.datePickerFallback }) },
                            React.createElement(DatePicker, { className: clsx(commonStyles.input, commonStyles.small), placeholderText: field.placeholder, value: (() => {
                                    if (prof.startDate === null) {
                                        return null;
                                    }
                                    if (prof.startDate === undefined) {
                                        return undefined;
                                    }
                                    return new Date(prof.startDate);
                                })(), onChange: (value) => {
                                    onEdit(index, "startDate", value ? value.toISOString() : value);
                                }, disabled: readOnly, showMonthYearPicker: true, hasError: (prof.startDate === null || prof.startDate === undefined) && isTriedToSave }))),
                    React.createElement("div", { className: styles.dateSelectors },
                        React.createElement("p", { className: clsx(styles.dateSelectorsTitle, styles.required, (readOnly || isPresent) && styles.disabled) }, field.end),
                        React.createElement(React.Suspense, { fallback: React.createElement("div", { className: styles.datePickerFallback }) },
                            React.createElement(DatePicker, { className: clsx(commonStyles.input, commonStyles.small), placeholderText: field.placeholder, value: (() => {
                                    const currentDate = new Date();
                                    if (prof.endDate === undefined) {
                                        return undefined;
                                    }
                                    if (isPresent || prof.endDate === null) {
                                        return currentDate;
                                    }
                                    return new Date(prof.endDate);
                                })(), onChange: (value) => {
                                    onEdit(index, "endDate", value ? value.toISOString() : value);
                                }, disabled: readOnly || isPresent, showMonthYearPicker: true, hasError: prof.endDate !== null &&
                                    new Date(prof.endDate) < new Date(prof.startDate) &&
                                    isTriedToSave })))),
                React.createElement("p", { className: clsx(commonStyles.warningMessage, !isDatesIntersectionWarningHidden &&
                        dateWarningIndexes.includes(index) &&
                        commonStyles.warningMessageVisible) }, field.datesWarning)),
            React.createElement(Switch, { isChecked: isPresent, onSwitch: () => {
                    if (!isPresent) {
                        setIsPresent(true);
                        onEditArray(index, [
                            { field: "endDate", payload: null },
                            { field: "isPresent", payload: true },
                        ]);
                        setStoredEndDate(prof.endDate);
                    }
                    else {
                        setIsPresent(false);
                        onEditArray(index, [
                            { field: "endDate", payload: storedEndDate || new Date().toISOString() },
                            { field: "isPresent", payload: false },
                        ]);
                        setStoredEndDate(null);
                    }
                }, text: field.isPresentTime, className: clsx(styles.presentSwitch, styles.inputContainer) })),
        IS_PROJECT_SELECTOR_FEATURE_ACTIVE && (React.createElement("section", { className: styles.inputs },
            React.createElement("div", { className: styles.inputContainer },
                React.createElement("p", { className: styles.title }, field.projectName),
                React.createElement(DropDown, { isOpen: isDropDownOpen, selectedOptions: [], options: projects, toggleOpen: () => setIsDropDownOpen(!isDropDownOpen), onSelect: handleProjectSelect, dropdownClassName: styles.dropdownBody },
                    React.createElement("div", { className: clsx(styles.projectSelect, isDropDownOpen && styles.activeProjectSelect) },
                        React.createElement("p", { className: styles.projectSelectText }, (selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.name) || "Project"),
                        React.createElement(ArrowDownIcon, { className: styles.arrowDown })))),
            React.createElement("div", { className: styles.inputContainer }))),
        React.createElement("section", null,
            React.createElement("header", { className: styles.sectionHeader },
                React.createElement("p", { className: styles.title }, field.projectDescription),
                !isBackUpEmpty && (React.createElement(IconButton, { icon: React.createElement(UndoIcon, null), title: undo, className: styles.undo, color: "secondary", onClick: popFromBackup })),
                React.createElement(AssistButton, { disabled: !prof.projectDescription, performAction: performDescriptionAction, isActionInProgress: isDescriptionActionInProgress })),
            React.createElement(TextAreaWithSelectionTool, { wrapClassName: commonStyles.input, placeholder: field.projectDescription, rows: 4, maxLength: 700, readOnly: readOnly, value: prof.projectDescription, handleValueChange: (value) => onEdit(index, "projectDescription", value), handleSelectionAction: translateField("projectDescription"), isAutoGrowing: true })),
        React.createElement("section", null,
            React.createElement(ListField, { title: field.responsibilities, itemTitle: field.responsibility, points: prof.responsibilitiesDisplay, onEdit: (value) => onEdit(index, "responsibilitiesDisplay", value), readOnly: readOnly, maxLength: 150, onItemSelection: handleResponsibilitySelectAction, addButtonLabel: field.addResp })),
        React.createElement("section", { className: styles.toolsWrap },
            React.createElement("header", { className: commonStyles.sectionHeading }, field.tools),
            React.createElement(TextAreaWithSelectionTool, { wrapClassName: commonStyles.input, placeholder: field.tools, rows: 4, maxLength: 300, readOnly: readOnly, value: prof.tools, onChange: ({ target: { value } }) => onEdit(index, "tools", value), handleValueChange: (value) => onEdit(index, "tools", value), handleSelectionAction: translateField("tools") }))));
}
