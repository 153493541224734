import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, DropDownWrap, Input } from "@components";
import IconGroup from "@assets/group.svg";
import Lens from "@assets/icon_search.svg";
import Cross from "@assets/icon_cross_small.svg";
import { addIdArray } from "@utils";
import { Api, SUBORDINATES } from "@services";
import { useAppSelector } from "@hooks";
import styles from "./Subordinates.module.scss";
export function Subordinates() {
    const [isOpen, setIsOpen] = React.useState(false);
    const [subordinates, setSubordinates] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const { currentUser } = useAppSelector((state) => state.users);
    useEffect(() => {
        Api.get(`${SUBORDINATES}/${currentUser.email}`)
            .then((res) => {
            setSubordinates(addIdArray(res.data));
        })
            .catch(() => {
            setSubordinates([]);
        });
    }, [currentUser]);
    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };
    const handleClear = () => {
        setSearchValue("");
    };
    return (subordinates.length > 0 && (React.createElement("div", { className: styles.wrap },
        React.createElement(DropDownWrap, { isOpen: isOpen, toggleOpen: () => {
                setIsOpen(!isOpen);
            }, iconOnly: true, anchor: React.createElement(IconGroup, null), title: "My team" },
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.searchBox },
                    React.createElement(Input, { leftIcon: React.createElement(Lens, { width: "18px" }), leftIconClassName: styles.searchIcon, rightIcon: React.createElement(Cross, { onClick: handleClear }), rightIconClassName: styles.crossIcon, placeholder: "Name", onChange: handleInputChange, value: searchValue })),
                subordinates
                    .filter((subordinate) => subordinate.value.fullName.toUpperCase().includes(searchValue.toUpperCase()))
                    .sort((a, b) => a.value.fullName.toUpperCase().localeCompare(b.value.fullName.toUpperCase()))
                    .map((subordinate) => {
                    return (React.createElement(Link, { key: subordinate.displayId, to: `/cv/${subordinate.value.email}`, target: "_blank" },
                        React.createElement(Button, { className: styles.button, variant: "text" }, subordinate.value.fullName)));
                }))))));
}
