/* eslint-disable  @typescript-eslint/ban-ts-comment */
import { createSlice } from "@reduxjs/toolkit";
import { clearArrays } from "@store/cv/utils";
import { getCV, getFileLink, saveCV, setCV, setIsTriedToSave, translateHeaders } from "@store/cv";
import { FileTypeMap } from "@types";
const initialState = {
    cv: null,
    isGetCVLoading: false,
    getCVError: "",
    savedCV: null,
    isCVChanged: false,
    isSaveCvLoading: false,
    saveCVError: "",
    isTriedToSave: false,
    isCvFileAvailable: false,
    isGetStatusLoading: false,
    getStatusError: "",
    fileLinkDOC: "",
    fileLinkPDF: "",
    fileLinkDrive: "",
    isFileLinkPDFLoading: false,
    isFileLinkDOCXLoading: false,
    isFileLinkDriveLoading: false,
    getFileLinkError: "",
    fileLinkPDFExpirationTime: 0,
    fileLinkDOCXExpirationTime: 0,
    fileLinkDriveExpirationTime: 0,
};
export const cvSlice = createSlice({
    name: "cv",
    initialState,
    reducers: {},
    extraReducers: {
        [getCV.pending.type]: (state) => {
            state.isGetCVLoading = true;
        },
        [getCV.fulfilled.type]: (state, action) => {
            state.cv = action.payload;
            state.savedCV = action.payload;
            state.getCVError = "";
            state.isGetCVLoading = false;
        },
        [getCV.rejected.type]: (state, action) => {
            state.getCVError = action.payload;
            state.isGetCVLoading = false;
        },
        [saveCV.pending.type]: (state) => {
            state.isSaveCvLoading = true;
        },
        [saveCV.fulfilled.type]: (state, action) => {
            const { isSilent, isCurrent, data } = action.payload;
            if (isCurrent) {
                state.cv = data;
                state.savedCV = data;
            }
            else {
                state.savedCV = state.cv;
            }
            state.saveCVError = "";
            state.isSaveCvLoading = false;
            if (!isSilent) {
                state.isCVChanged = false;
            }
        },
        [saveCV.rejected.type]: (state, action) => {
            state.saveCVError = action.payload;
            state.isSaveCvLoading = false;
        },
        [getFileLink.pending.type]: (state, action) => {
            const format = action.meta.arg.fileFormat;
            const loadingType = FileTypeMap[format].loading;
            // @ts-ignore
            state[loadingType] = true;
        },
        [getFileLink.fulfilled.type]: (state, action) => {
            const format = action.meta.arg.fileFormat;
            const linkType = FileTypeMap[format].link;
            const loadingType = FileTypeMap[format].loading;
            // @ts-ignore
            state[linkType] = action.payload.link;
            // @ts-ignore
            state[loadingType] = false;
            const expirationType = FileTypeMap[format].expiration;
            // @ts-ignore
            state[expirationType] = action.payload.expiration;
        },
        [getFileLink.rejected.type]: (state, action) => {
            state.getFileLinkError = action.payload;
            const format = action.meta.arg.fileFormat;
            const loadingType = FileTypeMap[format].loading;
            // @ts-ignore
            state[loadingType] = false;
            const expirationType = FileTypeMap[format].expiration;
            // @ts-ignore
            state[expirationType] = 0;
        },
        [setIsTriedToSave.type]: (state, action) => {
            state.isTriedToSave = action.payload;
        },
        [translateHeaders.fulfilled.type]: (state, action) => {
            state.cv = action.payload;
            state.isTriedToSave = false;
            const clearPayload = clearArrays(action.payload);
            const clearedSave = clearArrays(state.savedCV);
            state.isCVChanged = JSON.stringify(clearPayload) !== JSON.stringify(clearedSave);
        },
        [setCV.type]: (state, action) => {
            state.cv = action.payload;
            state.isTriedToSave = false;
            const clearPayload = clearArrays(action.payload);
            const clearedSave = clearArrays(state.savedCV);
            state.isCVChanged = JSON.stringify(clearPayload) !== JSON.stringify(clearedSave);
        },
    },
});
export default cvSlice.reducer;
