import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        notification: [
            "Section limit reached: Maximum $LIMIT$ entries achieved",
            "Лимит в разделе достигнут: максимум $LIMIT$ записей",
        ][index],
        title: ["Personal Strengths", "Сильные стороны"][index],
        placeholder: ["Personal Strength", "Сильная сторона"][index],
        addItem: ["Add Personal Strength", "Добавить Сильную сторону"][index],
    };
};
