var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Language } from "@types";
import { useAppDispatch, useAppSelector, useTablet } from "@hooks";
import { addRevision, removeRevision, saveCV, setCV, setIsTriedToSave, setSelectedRevision, showNotification, } from "@store";
import IconBurgerMenu from "@assets/icon_menu_burger.svg";
import LogoText from "@assets/icon-logo-text.svg";
import LogoWithoutMargin from "@assets/logo-no-margin.svg";
import { ShareDownloadModal } from "@components/ShareDownloadModal/ShareDownloadModal";
import { hasEmptyLevel, isValidLanguages } from "@pages/CvPage/components/CV/sections";
import { Button, IconButton } from "@components";
import { cvPath } from "@utils";
import { dictionary } from "@constants";
import { isCustomSectionEmpty, isEmptyDate, isWrongDate, } from "@pages/CvPage/components/CvHeader/validators";
import { CvFileUploader, FloatingActionButton, RevisionSelector, RevisionTranslate, TemplateSelector, } from "./components";
import { fields } from "./localization";
import styles from "./CvHeader.module.scss";
export function CvHeader({ setIsCVScrollable, toggleNavigationVisibility }) {
    const [showSharedDownloadModal, setShowSharedDownloadModal] = useState(false);
    const { templates } = useAppSelector((state) => state.templates);
    const { selectedRevision, revisions } = useAppSelector((state) => state.revisions);
    const { language, isViewOnly } = useAppSelector((state) => state.ui);
    const { currentUser, targetUser } = useAppSelector((state) => state.users);
    const { cv, isCVChanged, isTriedToSave, savedCV } = useAppSelector((state) => state.cv);
    const isTablet = useTablet();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const field = fields(language);
    const { discardChanges, saved } = dictionary(language);
    const showTemplateSelector = templates.some(({ language: templateLanguage }) => Language.EN !== templateLanguage);
    const handleDiscardChanges = () => {
        if (selectedRevision.isPreview) {
            dispatch(removeRevision(selectedRevision));
            dispatch(setSelectedRevision(revisions.find((revision) => revision.isDefault)));
        }
        dispatch(setCV(savedCV));
    };
    const handleSave = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        const isSaveDisabled = isViewOnly || !isCVChanged || isTriedToSave;
        if (isSaveDisabled)
            return;
        const skills = cv.overviewDisplay.bulletpointsDisplay.filter((point) => point.value.trim() !== "");
        const clearOverview = Object.assign(Object.assign({}, cv.overviewDisplay), { bulletpointsDisplay: skills });
        const clearProfExp = cv.professionalExperienceDisplay.map((exp) => (Object.assign(Object.assign({}, exp), { responsibilitiesDisplay: exp.responsibilitiesDisplay.filter((r) => r.value.trim() !== "") })));
        const clearData = Object.assign(Object.assign({}, cv), { overviewDisplay: clearOverview, professionalExperienceDisplay: clearProfExp, imageUrl: targetUser.imageUrl });
        dispatch(setIsTriedToSave(true));
        // TODO move this huge ifs to validator functions
        if (isEmptyDate(clearData)) {
            dispatch(showNotification({ message: field.emptyDate, isSuccess: false }));
        }
        else if (isWrongDate(clearData)) {
            dispatch(showNotification({ message: field.wrongDate, isSuccess: false }));
        }
        else if (isCustomSectionEmpty(cv)) {
            dispatch(showNotification({ message: field.emptyCustomTitle, isSuccess: false }));
        }
        else if (!isValidLanguages(clearData.languageDisplay)) {
            dispatch(showNotification({ message: field.languageRepeat, isSuccess: false }));
        }
        else if (hasEmptyLevel(clearData.languageDisplay)) {
            dispatch(showNotification({ message: field.languageLevelEmpty, isSuccess: false }));
        }
        else {
            try {
                if (selectedRevision.isPreview) {
                    dispatch(removeRevision(selectedRevision));
                    dispatch(setSelectedRevision({ name: selectedRevision.name, isPreview: false }));
                }
                yield dispatch(saveCV({
                    email: targetUser.email,
                    revisionName: selectedRevision.name,
                    data: clearData,
                })).unwrap();
                if (selectedRevision.isPreview) {
                    dispatch(addRevision({ name: selectedRevision.name, isPreview: false }));
                }
                dispatch(showNotification({ message: saved, isSuccess: true }));
                dispatch(setIsTriedToSave(false));
            }
            catch (rejectedValueOrSerializedError) {
                return null;
            }
        }
    }), [targetUser, isViewOnly, isCVChanged, isTriedToSave, cv, selectedRevision]);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.code === "KeyS") {
                event.preventDefault();
                handleSave();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleSave]);
    const setSelectedRevisionUrlParam = (revisionName) => {
        if (revisionName) {
            const currentPath = location.pathname;
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("revision", revisionName);
            navigate(`${currentPath}?${searchParams}`, { replace: true });
        }
    };
    const handleNavigate = () => {
        if (targetUser.email !== currentUser.email) {
            navigate(cvPath.getPath(currentUser === null || currentUser === void 0 ? void 0 : currentUser.email));
        }
    };
    return (React.createElement("header", { className: styles.header },
        React.createElement("div", { className: styles.container },
            React.createElement("button", { type: "button", className: styles.logo, onClick: handleNavigate },
                React.createElement("div", { className: styles.icon },
                    React.createElement(LogoWithoutMargin, { className: styles.icon })),
                React.createElement(LogoText, null)),
            React.createElement("div", { className: styles.dividerContainer },
                React.createElement(RevisionSelector, { setIsCVScrollable: setIsCVScrollable, setSelectedRevisionUrlParam: setSelectedRevisionUrlParam, onSaveChanges: handleSave, onDiscardChanges: handleDiscardChanges })),
            React.createElement(RevisionTranslate, { setIsCVScrollable: setIsCVScrollable, setSelectedRevisionUrlParam: setSelectedRevisionUrlParam, onSaveChanges: handleSave, onDiscardChanges: handleDiscardChanges }),
            isTablet ? (React.createElement(FloatingActionButton, { handleSave: handleSave, setIsCVScrollable: setIsCVScrollable, onShowDownloadSettingsClick: () => setShowSharedDownloadModal(true), onDiscardChangesClick: handleDiscardChanges, setSelectedRevisionUrlParam: setSelectedRevisionUrlParam })) : (React.createElement(React.Fragment, null,
                React.createElement(CvFileUploader, { onSaveChanges: handleSave, onDiscardChanges: handleDiscardChanges, setSelectedRevisionUrlParam: setSelectedRevisionUrlParam }),
                showTemplateSelector && (React.createElement("div", { className: styles.templateSelectorContainer },
                    React.createElement(TemplateSelector, { setIsCVScrollable: setIsCVScrollable }))),
                React.createElement("div", { className: clsx(styles.dividerContainer) },
                    React.createElement(Button, { onClick: () => setShowSharedDownloadModal(true), disabled: !showTemplateSelector || isCVChanged, color: "secondary" }, field.share)),
                React.createElement("div", { className: styles.buttonContainer },
                    React.createElement(Button, { onClick: handleDiscardChanges, disabled: isViewOnly || !isCVChanged || isTriedToSave || selectedRevision.isPreview, color: "tertiary" }, discardChanges),
                    React.createElement(Button, { onClick: handleSave, disabled: isViewOnly || !isCVChanged || isTriedToSave, title: isViewOnly || !isCVChanged ? field.saveDisabledTitle : field.saveActiveTitle }, field.save)))),
            isTablet && (React.createElement(IconButton, { icon: React.createElement(IconBurgerMenu, { className: styles.burgerIcon }), onClick: toggleNavigationVisibility, variant: "outlined", title: field.menu }))),
        showSharedDownloadModal && (React.createElement(ShareDownloadModal, { handleSave: handleSave, onClose: () => setShowSharedDownloadModal(false) }))));
}
