import React from "react";
import clsx from "clsx";
import InfoIcon from "@assets/info-icon.svg";
import styles from "./Footer.module.scss";
export function Footer({ className, children, supportingText }) {
    return (React.createElement("footer", { className: clsx(styles.footer, className) },
        supportingText && (React.createElement("span", { className: clsx("body-small", styles.supportingText) },
            React.createElement(InfoIcon, null),
            " ",
            supportingText)),
        React.createElement("div", { className: styles.content }, children)));
}
