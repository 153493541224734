var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { handleSignInWithPassword, initializeDevAuth, initializeGoogleAuth } from "@services";
import { PageLoader, Modal } from "@components";
import SignInImageMobile from "@assets/signIn_image_mobile.svg";
import SignInImage from "@assets/signIn_image.svg";
import { cvPath } from "@utils";
import { useAppSelector, useMobile } from "@hooks";
import { isDevelopmentMode } from "@constants";
import styles from "./Auth.module.scss";
const prodButtonConfig = {
    type: "standard",
    theme: "filled_blue",
    size: "large",
};
export function Auth() {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMobile();
    const prodButtonParent = useRef(null);
    const { currentUser, authToken } = useAppSelector((state) => state.users);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isOnSubmit, setIsOnSubmit] = useState(false);
    const checkAndRedirect = (ignoreHistory) => {
        var _a;
        if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) && authToken) {
            const from = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from;
            const path = (from === null || from === void 0 ? void 0 : from.pathname) || "/";
            const search = (from === null || from === void 0 ? void 0 : from.search) || "";
            if (ignoreHistory) {
                navigate(cvPath.getPath(currentUser.email), { replace: true });
            }
            else {
                navigate(`${path}${search}`, { replace: true });
            }
        }
    };
    useEffect(() => {
        checkAndRedirect(isOnSubmit);
    }, [currentUser, authToken, isOnSubmit]);
    useEffect(() => {
        setIsLoading(true);
        if (isDevelopmentMode) {
            initializeDevAuth().then(() => {
                setIsLoading(false);
            });
        }
        else {
            const initGoogleAuth = () => {
                initializeGoogleAuth().then((initialized) => {
                    if (initialized) {
                        window.google.accounts.id.renderButton(prodButtonParent.current, prodButtonConfig);
                        window.google.accounts.id.prompt();
                    }
                    setIsLoading(false);
                });
            };
            // onload used to make sure that gsi/client library loaded
            if (window.google) {
                initGoogleAuth();
            }
            else {
                window.onload = () => {
                    initGoogleAuth();
                };
            }
        }
    }, [prodButtonParent]);
    const handleGoogleLoginDev = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        setIsOnSubmit(true);
        setIsLoading(true);
        try {
            yield handleSignInWithPassword(email, password);
            setIsLoading(false);
        }
        catch (_a) {
            setIsLoading(false);
        }
    });
    if (isDevelopmentMode) {
        return isLoading ? (React.createElement(PageLoader, null)) : (React.createElement("div", { className: styles.devWrap },
            React.createElement("form", { className: styles.devLoginForm, onSubmit: handleGoogleLoginDev },
                React.createElement("div", null,
                    React.createElement("div", { className: styles.devFormTitle }, "email"),
                    React.createElement("input", { value: email, onChange: (e) => setEmail(e.target.value), type: "email" })),
                React.createElement("div", null,
                    React.createElement("div", { className: styles.devFormTitle }, "password"),
                    React.createElement("input", { value: password, onChange: (e) => setPassword(e.target.value), type: "password" })),
                React.createElement("button", { type: "submit" }, "Login"))));
    }
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(PageLoader, null),
        React.createElement("div", { className: clsx(styles.wrap, isLoading && styles.hidden) },
            React.createElement(Modal, { open: isModalOpen, onRequestClose: () => setIsModalOpen(false), content: React.createElement("div", { className: styles.warningContent }, "Sorry, you can`t access the app. Please contact your administrator."), title: "Something went wrong...", className: styles.warningModal }),
            React.createElement("div", { className: styles.card },
                React.createElement("div", { className: styles.header },
                    isMobile ? React.createElement(SignInImageMobile, { className: styles.svg }) : React.createElement(SignInImage, null),
                    React.createElement("div", { className: styles.headerText },
                        React.createElement("p", { className: styles.lxGroup }, "Leverx group"),
                        React.createElement("p", { className: styles.productName }, "CV Builder"))),
                React.createElement("div", { className: styles.content },
                    React.createElement("h1", { className: styles.contentHeader },
                        "Welcome!",
                        React.createElement("br", null),
                        "Just one simple step left"),
                    React.createElement("p", { className: styles.subheader },
                        "Please sign in with LeverX Google",
                        React.createElement("br", null),
                        "account to access the system"),
                    React.createElement("div", { ref: prodButtonParent, className: styles.googleButtonBox }))))));
}
