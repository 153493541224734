/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { convertTime } from "@utils";
import { DropDownWrap, IconButton } from "@components";
import IconPDF from "@assets/icon_pdf.svg";
import IconDOC from "@assets/icon_doc.svg";
import { useAppSelector } from "@hooks";
import { fields } from "./localization";
import styles from "./ShareLinkDropDown.module.scss";
export function ShareLinkDropDown({ isShareLinkOpen, setIsShareLinkOpen, shareLinkRef, isSharePDFLinkActive, isShareDOCXLinkActive, isShareDriveLinkActive, handleGetFileLinkClick, setIsCVScrollable, anchor, toggleOpen, disabled, title, }) {
    const [shareLinkMessage, setShareLinkMessage] = useState(null);
    const { fileLinkPDFExpirationTime, fileLinkDOCXExpirationTime, fileLinkDriveExpirationTime, isFileLinkPDFLoading, isFileLinkDOCXLoading, isFileLinkDriveLoading, } = useAppSelector((state) => state.cv);
    const { language } = useAppSelector((state) => state.ui);
    const field = fields(language);
    useEffect(() => {
        if (!isShareLinkOpen) {
            setShareLinkMessage("");
        }
        setIsCVScrollable(!isShareLinkOpen);
    }, [isShareLinkOpen]);
    useEffect(() => {
        if (fileLinkPDFExpirationTime > 0) {
            setShareLinkMessage(`*${field.linkExpires} ${convertTime(fileLinkPDFExpirationTime, language)}`);
        }
    }, [fileLinkPDFExpirationTime]);
    useEffect(() => {
        if (fileLinkDOCXExpirationTime > 0) {
            setShareLinkMessage(`*${field.linkExpires} ${convertTime(fileLinkDOCXExpirationTime, language)}`);
        }
    }, [fileLinkDOCXExpirationTime]);
    useEffect(() => {
        if (fileLinkDriveExpirationTime > 0) {
            setShareLinkMessage("");
        }
    }, [fileLinkDriveExpirationTime]);
    return (React.createElement(DropDownWrap, { isOpen: isShareLinkOpen, toggleOpen: () => {
            setIsShareLinkOpen(!isShareLinkOpen);
            toggleOpen();
        }, externalAnchorRef: shareLinkRef, anchor: anchor, disabled: disabled, iconOnly: true, title: title },
        React.createElement("p", { className: "body-small text-center" }, field.selectLink),
        React.createElement("div", { className: styles.getLinkButtons },
            React.createElement(IconButton, { icon: React.createElement(IconPDF, null), iconCustomColor: true, variant: "outlined", onClick: () => handleGetFileLinkClick("pdf" /* CVFileTypes.pdf */), title: isSharePDFLinkActive ? field.copyPDFLink : "", isLoading: isFileLinkPDFLoading && !isSharePDFLinkActive }),
            React.createElement(IconButton, { icon: React.createElement(IconDOC, null), iconCustomColor: true, variant: "outlined", onClick: () => handleGetFileLinkClick("docx" /* CVFileTypes.docx */), title: isShareDOCXLinkActive ? field.copyDOCXLink : "", isLoading: isFileLinkDOCXLoading && !isShareDOCXLinkActive })),
        React.createElement("p", { className: clsx("body-small text-center no-wrap", styles.notification) }, shareLinkMessage || field.linkLifetime)));
}
