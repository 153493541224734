import { axiosInstance } from "./axios";
export class Api {
    static get(url, params = "") {
        return axiosInstance.get(`${url}${params}`);
    }
    static post(url, data = {}, headers) {
        return axiosInstance.post(url, data, {
            headers,
        });
    }
    static postBody(url, body, headers) {
        return axiosInstance.post(url, body, {
            headers,
            transformRequest: (data) => JSON.stringify(data),
        });
    }
    static postFile(url, file, headers) {
        const formData = new FormData();
        formData.append("file", file);
        return Api.post(url, formData, Object.assign(Object.assign({}, headers), { "Content-Type": "multipart/form-data" /* MimeTypes.form */ }));
    }
    static put(url, data = {}) {
        return axiosInstance.put(url, data);
    }
    static patch(url, data = {}) {
        return axiosInstance.patch(url, data);
    }
    static delete(url, data = {}) {
        return axiosInstance.delete(url, data);
    }
}
