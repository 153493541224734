import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        modalTitle: ["Delete Revision?", "Удалить ревизию?"][index],
        cancel: ["Cancel", "Отменить"][index],
        remove: ["Delete", "Удалить"][index],
        placeholder: ["Select Revision", "Выберите ревизию"][index],
        subtitle: [
            "Revision “$REVISION” will be permanently deleted.",
            "Ревизия “$REVISION” будет навсегда удалена.",
        ][index],
        chooseDefault: [
            "Please, choose another revision to mark it as default.",
            "Пожалуйста, отметьте другую ревизию основной",
        ][index],
    };
};
