import React, { useEffect, useState } from "react";
import styles from "./DropDownOption.module.scss";
export function DropDownOption({ id, icon, name, checked, multiple, onChange }) {
    const [isChecked, setIsChecked] = useState(checked);
    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);
    const handleClick = () => {
        setIsChecked(!isChecked);
        onChange(id);
    };
    return (React.createElement("div", { className: styles.option, tabIndex: 0, onClick: handleClick, role: "checkbox", "aria-checked": isChecked, onKeyUp: (e) => e.key === "Enter" && handleClick(), title: name },
        React.createElement("div", { className: styles.avatarAndName },
            icon && React.createElement("img", { src: icon, alt: "user avatar", className: styles.icon }),
            React.createElement("div", { className: styles.name }, name)),
        React.createElement("div", null, multiple && (React.createElement("div", { className: styles.checkbox, role: "switch", "aria-checked": isChecked }, isChecked && React.createElement("div", { className: styles.checkboxCheckedInner }))))));
}
