var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CustomSectionContentType, CustomSectionContentTypeDTO, SortOrder, } from "@types";
import { addIdArray, clearDate, clearPoints, createCertificatePoint, createDisplayPoint, parseLanguageArray, uniqueId, } from "@utils";
import { Api, CV_URL } from "@services";
import { EMPTY_PROF_EXP } from "@pages/CvPage/components/CV/sections/ProfessionalExperience/components";
export const extractCommonFields = (cv) => {
    const { anonymize, hideImage, hideCustomers, firstName, lastName, jobTitle, hideWorkExperience, imageUrl, hidePersonalStrengths, hideTrainings, hideCertifications, removePageBreakAfterOverview, removeAllPageBreaks, hideProjectNumber, anonymizeCustomers, } = cv;
    return {
        anonymize,
        hideImage,
        hideCustomers,
        firstName,
        lastName,
        jobTitle,
        hideWorkExperience,
        imageUrl,
        hidePersonalStrengths,
        hideTrainings,
        hideCertifications,
        removePageBreakAfterOverview,
        removeAllPageBreaks,
        hideProjectNumber,
        anonymizeCustomers,
    };
};
export const convertToCVDisplay = (cv, addFirstProfExp) => {
    const overviewDisplay = {
        text: cv.overview.text,
        bulletpointsDisplay: cv.overview.bulletpoints.length === 0
            ? [createDisplayPoint()]
            : addIdArray(cv.overview.bulletpoints),
    };
    const professionalExperienceDisplay = addFirstProfExp && (cv === null || cv === void 0 ? void 0 : cv.professionalExperience.length) === 0
        ? [EMPTY_PROF_EXP]
        : cv.professionalExperience.map((experience) => (Object.assign(Object.assign({}, experience), { responsibilitiesDisplay: experience.responsibilities.length === 0
                ? [createDisplayPoint()]
                : addIdArray(experience.responsibilities), displayId: uniqueId(), isSelected: false })));
    const workExperienceDisplay = [
        ...cv.workExperience.map((exp) => (Object.assign(Object.assign({}, exp), { responsibilitiesDisplay: addIdArray(exp.responsibilities), displayId: uniqueId() }))),
    ];
    const customSectionsDisplay = [
        ...cv.customSections.map((section) => {
            var _a, _b, _c;
            return (Object.assign(Object.assign({}, section), { displayId: uniqueId(), isSelected: false, content: {
                    text: ((_a = section.content) === null || _a === void 0 ? void 0 : _a.text) || "",
                    bulletPoints: ((_c = (_b = section.content) === null || _b === void 0 ? void 0 : _b.bulletPoints) === null || _c === void 0 ? void 0 : _c.length)
                        ? addIdArray(section.content.bulletPoints)
                        : [createDisplayPoint()],
                }, bodyContentType: section.bodyContentType === CustomSectionContentTypeDTO.Text
                    ? CustomSectionContentType.Text
                    : CustomSectionContentType.BulletPoints }));
        }),
    ];
    const certificatesDisplay = cv.certificates.length
        ? cv.certificates.map(({ id, linkToCertificate, name }) => ({
            displayId: id,
            value: name,
            linkToCertificate,
        }))
        : [createCertificatePoint()];
    const personalStrengthsDisplay = addIdArray(cv.personalStrengths);
    const trainingsDisplay = addIdArray(cv.trainings);
    const educationDisplay = addIdArray(cv.education);
    const languageDisplay = parseLanguageArray(cv.language);
    const sortOrder = Object.values(SortOrder)[cv.sortState] || SortOrder.Custom;
    return Object.assign(Object.assign({}, extractCommonFields(cv)), { overviewDisplay,
        professionalExperienceDisplay,
        workExperienceDisplay,
        customSectionsDisplay,
        certificatesDisplay,
        personalStrengthsDisplay,
        trainingsDisplay,
        languageDisplay,
        sortOrder,
        educationDisplay });
};
export const convertToCVDTO = (cv) => {
    return Object.assign(Object.assign({}, extractCommonFields(cv)), { overview: Object.assign(Object.assign({}, cv.overviewDisplay), { bulletpoints: cv.overviewDisplay.bulletpointsDisplay.map((b) => b.value) }), professionalExperience: cv.professionalExperienceDisplay.map((p) => (Object.assign(Object.assign({}, p), { responsibilities: p.responsibilitiesDisplay.map((r) => r.value) }))), workExperience: cv.workExperienceDisplay.map((work) => (Object.assign(Object.assign({}, work), { responsibilities: work.responsibilitiesDisplay.map((r) => r.value) }))), customSections: cv.customSectionsDisplay.map((section) => {
            var _a;
            return ({
                header: section.header,
                isHidden: section.isHidden,
                content: {
                    text: section.content.text,
                    bulletPoints: (_a = section.content.bulletPoints) === null || _a === void 0 ? void 0 : _a.map(({ value }) => value),
                },
                bodyContentType: section.bodyContentType === "text" ? 0 : 1,
            });
        }), certificates: cv.certificatesDisplay.map(({ displayId, linkToCertificate, value }) => ({
            id: displayId,
            name: value,
            linkToCertificate,
        })), personalStrengths: cv.personalStrengthsDisplay.map(({ value }) => value), trainings: cv.trainingsDisplay.map(({ value }) => value), education: cv.educationDisplay.map(({ value }) => value), language: cv.languageDisplay.map(({ language, level }) => `${language}: ${level}`), sortState: Object.values(SortOrder).indexOf(cv.sortOrder) });
};
export const clearProfExpDates = (cv) => {
    const newExperience = cv.professionalExperience.map((experience) => (Object.assign(Object.assign({}, experience), { startDate: clearDate(experience.startDate), endDate: clearDate(experience.endDate) })));
    return Object.assign(Object.assign({}, cv), { professionalExperience: newExperience });
};
export const clearArrays = (cv) => {
    return Object.assign(Object.assign({}, cv), { firstName: cv.firstName.trim(), lastName: cv.lastName.trim(), overviewDisplay: {
            text: cv.overviewDisplay.text.trim(),
            bulletpointsDisplay: clearPoints(cv.overviewDisplay.bulletpointsDisplay),
        }, jobTitle: cv.jobTitle.trim(), professionalExperienceDisplay: cv.professionalExperienceDisplay.map((p) => (Object.assign(Object.assign({}, p), { customer: p.customer.trim(), role: p.role.trim(), projectDescription: p.projectDescription.trim(), responsibilitiesDisplay: clearPoints(p.responsibilitiesDisplay), tools: p.tools.trim() }))), customSectionsDisplay: cv.customSectionsDisplay
            .map(({ header, displayId, content, bodyContentType, isHidden = false, isSelected = false, }) => {
            var _a, _b;
            return ({
                displayId,
                bodyContentType,
                isHidden,
                isSelected,
                header: header === null || header === void 0 ? void 0 : header.trim(),
                content: {
                    text: (_a = content.text) === null || _a === void 0 ? void 0 : _a.trim(),
                    bulletPoints: (_b = content.bulletPoints) === null || _b === void 0 ? void 0 : _b.filter(({ value }) => value.trim()),
                },
            });
        })
            .filter(({ content, header }) => { var _a; return Boolean(content.text) || Boolean((_a = content.bulletPoints) === null || _a === void 0 ? void 0 : _a.length) || Boolean(header); }), educationDisplay: cv.educationDisplay.filter(({ value }) => value.trim()), languageDisplay: cv.languageDisplay.filter(({ language }) => language.trim()), certificatesDisplay: cv.certificatesDisplay.filter(({ value }) => value.trim()), personalStrengthsDisplay: cv.personalStrengthsDisplay.filter(({ value }) => value.trim()), trainingsDisplay: cv.trainingsDisplay.filter(({ value }) => value.trim()) });
};
export const fetchCV = (email, revisionName, addFirstProfExp) => __awaiter(void 0, void 0, void 0, function* () {
    const { data: response } = yield Api.get(`${CV_URL}?userId=${email}&revisionName=${revisionName}`);
    return convertToCVDisplay(clearProfExpDates(response), addFirstProfExp);
});
