export const cvPath = {
    pattern: "/cv/:email",
    getPath: (email) => `/cv/${email}`,
};
export const authPagePath = {
    pattern: "/auth",
};
export const notFoundPagePath = {
    pattern: "/not-found",
};
