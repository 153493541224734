import clsx from "clsx";
import React from "react";
import IconCrossSmall from "@assets/icon_cross_small.svg";
import { dictionary } from "@constants";
import { useAppSelector } from "@hooks";
import { IconButton } from "@components";
import styles from "./Header.module.scss";
export function Header({ title, headerContent, onCloseClick }) {
    const { language } = useAppSelector((state) => state.ui);
    const { close } = dictionary(language);
    return (React.createElement("header", { className: styles.header },
        headerContent,
        React.createElement("p", { className: clsx(styles.title, "title-medium") }, title),
        React.createElement(IconButton, { color: "secondary", icon: React.createElement(IconCrossSmall, null), className: clsx(styles.closeButton), onClick: onCloseClick, title: close })));
}
