import { CustomSectionContentType } from "@types";
import { languageIndex } from "@utils";
import IconPerson from "@assets/icon-person.svg";
import IconCheckbook from "@assets/icon-checkbook.svg";
import IconBusiness from "@assets/icon-business.svg";
import IconSchool from "@assets/icon-school.svg";
import IconGlobal from "@assets/icon-global.svg";
import IconCert from "@assets/icon-cert.svg";
import IconTrainings from "@assets/icon-trainings.svg";
import IconBolt from "@assets/icon-bolt.svg";
import IconBullet from "@assets/icon-bullet.svg";
import IconText from "@assets/icon-text.svg";
export var HeadingIds;
(function (HeadingIds) {
    HeadingIds["GeneralInfo"] = "general-info";
    HeadingIds["Overview"] = "overview";
    HeadingIds["ProfessionalExperience"] = "professional-experience";
    HeadingIds["Education"] = "education";
    HeadingIds["LanguageSkills"] = "language-skills";
    HeadingIds["Certifications"] = "certifications";
    HeadingIds["PersonalStrengths"] = "personal-strengths";
    HeadingIds["Trainings"] = "trainings";
})(HeadingIds || (HeadingIds = {}));
export const getHeadingsDictionary = (language) => {
    const index = languageIndex(language);
    return {
        [HeadingIds.GeneralInfo]: ["General info", "Общая информация"][index],
        [HeadingIds.Overview]: ["Overview", "Общие сведения"][index],
        [HeadingIds.ProfessionalExperience]: ["Professional Experience", "Профессиональный опыт"][index],
        [HeadingIds.Education]: ["Education", "Образование"][index],
        [HeadingIds.LanguageSkills]: ["Language skills", "Языки"][index],
        [HeadingIds.Certifications]: ["Certifications", "Сертификаты"][index],
        [HeadingIds.Trainings]: ["Trainings", "Тренинги"][index],
        [HeadingIds.PersonalStrengths]: ["Personal Strengths", "Сильные стороны"][index],
    };
};
export const sectionHeadings = [
    HeadingIds.GeneralInfo,
    HeadingIds.Overview,
    HeadingIds.ProfessionalExperience,
    HeadingIds.Education,
    HeadingIds.LanguageSkills,
    HeadingIds.Certifications,
    HeadingIds.Trainings,
    HeadingIds.PersonalStrengths,
];
export const IconMap = new Map([
    [CustomSectionContentType.BulletPoints, IconBullet],
    [CustomSectionContentType.Text, IconText],
    [HeadingIds.GeneralInfo, IconPerson],
    [HeadingIds.Overview, IconCheckbook],
    [HeadingIds.ProfessionalExperience, IconBusiness],
    [HeadingIds.Education, IconSchool],
    [HeadingIds.LanguageSkills, IconGlobal],
    [HeadingIds.Certifications, IconCert],
    [HeadingIds.Trainings, IconTrainings],
    [HeadingIds.PersonalStrengths, IconBolt],
]);
