import { languageIndex } from "@utils";
export const dictionary = (language) => {
    const index = languageIndex(language);
    // TODO extract all replicated text to this dictionary
    return {
        translateSuccess: ["Text translated successfully", "Текст успешно переведен"][index],
        translateError: ["Translation failed", "Ошибка при переводе"][index],
        beautifySuccess: ["Text beautified successfully", "Текст успешно улучшен"][index],
        beautifyError: ["Beautifying failed", "Ошибка при улучшении текста"][index],
        beautifyContentError: [
            "Sorry, not enough context for beautification",
            "Извините, недостаточно контекста для улучшения текста",
        ][index],
        translate: ["Translate", "Перевести"][index],
        beautify: ["Beautify", "Улучшить"][index],
        remove: ["Delete", "Удалить"][index],
        undo: ["Undo", "Отменить"][index],
        close: ["Close", "Закрыть"][index],
        cancel: ["Cancel", "Отменить"][index],
        addBulletSection: ["Add Bullet Section", "Добавить раздел со списком"][index],
        addTextSection: ["Add Text Section", "Добавить текстовый раздел"][index],
        notAllowedSymbols: [
            "Cyrillic characters and symbols #, =, +, ’, ”, `, /, |, \\, ?, № are not allowed",
            "Кириллические буквы и символы #, =, +, ’, ”, `, /, |, \\, ?, № недопустимы",
        ][index],
        save: ["Save", "Сохранить"][index],
        discardChanges: ["Discard Changes", "Отменить изменения"][index],
        saveChanges: ["Save changes?", "Сохранить изменения?"][index],
        saveChangesSubtitle: [
            "Revision “$REVISION” has unsaved changes.",
            "Ревизия “$REVISION” имеет несохраненные изменения",
        ][index],
        saved: ["Changes saved successfully", "Изменения успешно сохранены"][index],
    };
};
