import { languageIndex } from "@utils";
export const fields = (language) => {
    const index = languageIndex(language);
    return {
        attach: ["Attach certification link", "Приложите ссылку на сертификат"][index],
        title: ["Confirm certification authenticity", "Подтвердите подлинность сертификата"][index],
        confirm: ["Confirm", "Подтвердить"][index],
        cancel: ["Cancel", "Отменить"][index],
    };
};
