import React, { useEffect, useRef, useState } from "react";
import IconMenuMobile from "@assets/icon_menu_mobile.svg";
import { useAppSelector } from "@hooks";
import { Button, Overlay } from "@components";
import IconLink from "@assets/icon_link.svg";
import IconSave from "@assets/save.svg";
import IconUndo from "@assets/undo.svg";
import { CvFileUploader } from "@pages/CvPage/components/CvHeader/components";
import { dictionary } from "@constants";
import { fields } from "./localization";
import styles from "./FloatingActionButton.module.scss";
export function FloatingActionButton({ handleSave, setIsCVScrollable, onShowDownloadSettingsClick, onDiscardChangesClick, setSelectedRevisionUrlParam, }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const ref = useRef(null);
    const { selectedRevision } = useAppSelector((state) => state.revisions);
    const { language, isViewOnly } = useAppSelector((state) => state.ui);
    const { isCVChanged, isTriedToSave } = useAppSelector((state) => state.cv);
    useEffect(() => {
        setIsCVScrollable(!isMenuOpen);
    }, [isMenuOpen]);
    const field = fields(language);
    const { discardChanges } = dictionary(language);
    const isCvReady = isViewOnly || !isCVChanged || isTriedToSave;
    return (React.createElement("div", { className: styles.container },
        React.createElement("button", { ref: ref, type: "button", className: styles.toggle, onClick: () => setIsMenuOpen(!isMenuOpen) },
            React.createElement(IconMenuMobile, null)),
        React.createElement(Overlay, { primaryPositionX: "left", primaryPositionY: "top", isOpen: isMenuOpen, hideOverlay: () => setIsMenuOpen(false), anchorRef: ref },
            React.createElement("div", { className: styles.list },
                React.createElement(CvFileUploader, { isFloatingMode: true, setSelectedRevisionUrlParam: setSelectedRevisionUrlParam }),
                React.createElement(Button, { color: "secondary", onClick: onShowDownloadSettingsClick, disabled: !isCvReady, leftIcon: React.createElement(IconLink, null) }, field.share),
                React.createElement(Button, { variant: "contained", color: "secondary", onClick: onDiscardChangesClick, disabled: isCvReady || selectedRevision.isPreview, leftIcon: React.createElement(IconUndo, null) }, discardChanges),
                React.createElement(Button, { color: "primary", variant: "contained", onClick: handleSave, disabled: isCvReady, leftIcon: React.createElement(IconSave, null) }, field.save)))));
}
