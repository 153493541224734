import React from "react";
import clsx from "clsx";
import { Switch } from "@components";
import { setCV, setIsDatesWarningHidden } from "@store";
import { useAppDispatch, useAppSelector } from "@hooks";
import { DEFAULT_GENERAL_INFO } from "@pages/CvPage/components/CV/sections/GeneralInfo";
import { LocalStorageKeys, safeLocalStorageSet } from "@utils";
import { AnalyticsId } from "@constants";
import { fields } from "./localization";
import styles from "./SettingsContent.module.scss";
export function SettingsContent() {
    const { cv } = useAppSelector((state) => state.cv);
    const { language, isViewOnly, isDatesIntersectionWarningHidden, featureFlags } = useAppSelector((state) => state.ui);
    const dispatch = useAppDispatch();
    const { anonymize, hideImage, hideCustomers, hidePersonalStrengths, hideTrainings, removePageBreakAfterOverview, } = cv || DEFAULT_GENERAL_INFO;
    const handleAnonymize = () => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { anonymize: !anonymize, hideImage: anonymize ? hideImage : true })));
    };
    const handleImageHide = () => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { hideImage: !hideImage })));
    };
    const handleCustomersHide = () => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { hideCustomers: !hideCustomers })));
    };
    const handleRemovePageBreakAfterOverview = () => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { removePageBreakAfterOverview: !removePageBreakAfterOverview })));
    };
    const handlePersonalStrengthsHide = () => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { hidePersonalStrengths: !hidePersonalStrengths })));
    };
    const handleTrainingsHide = () => {
        dispatch(setCV(Object.assign(Object.assign({}, cv), { hideTrainings: !hideTrainings })));
    };
    const handleDatesIntersectionWarningHide = () => {
        dispatch(setIsDatesWarningHidden(!isDatesIntersectionWarningHidden));
        safeLocalStorageSet(LocalStorageKeys.DATES_INTERSECTION_WARNING, (!isDatesIntersectionWarningHidden).toString());
    };
    const field = fields(language);
    return (React.createElement("div", { className: styles.row, id: AnalyticsId.settingsContainer },
        React.createElement("div", { className: clsx(styles.section, styles.leftSection) },
            React.createElement(Switch, { onSwitch: handleAnonymize, isChecked: anonymize, disabled: isViewOnly, text: field.Anonymize }),
            React.createElement(Switch, { onSwitch: handleImageHide, isChecked: !hideImage, disabled: anonymize || isViewOnly, text: field.photo }),
            React.createElement(Switch, { onSwitch: handleCustomersHide, isChecked: !hideCustomers, text: field.customers })),
        React.createElement("div", { className: styles.section },
            React.createElement(Switch, { onSwitch: handlePersonalStrengthsHide, isChecked: !hidePersonalStrengths, text: field.strengths }),
            React.createElement(Switch, { onSwitch: handleTrainingsHide, isChecked: !hideTrainings, text: field.trainings }),
            React.createElement(Switch, { onSwitch: handleDatesIntersectionWarningHide, isChecked: !isDatesIntersectionWarningHidden, text: field.datesIntersection }),
            (featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.insertPageBreakAfterOverview) && (React.createElement(Switch, { onSwitch: handleRemovePageBreakAfterOverview, isChecked: removePageBreakAfterOverview, text: field.RemovePageBreakAfterOverview })))));
}
