var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import clsx from "clsx";
import React, { useEffect } from "react";
import styles from "./TextArea.module.scss";
export function TextArea(_a) {
    var { hasError, wrapClassName, className, maxLength, value, forwardedRef, disabled, containerRef, onSelect, onBlur, onMouseDown, assistButton, description, isAutoGrowing } = _a, props = __rest(_a, ["hasError", "wrapClassName", "className", "maxLength", "value", "forwardedRef", "disabled", "containerRef", "onSelect", "onBlur", "onMouseDown", "assistButton", "description", "isAutoGrowing"]);
    const isAccessoryPanelVisible = !!maxLength;
    const handleInput = () => {
        if (isAutoGrowing && (containerRef === null || containerRef === void 0 ? void 0 : containerRef.current)) {
            // TODO replace this hack with native property as soon as it become stable.
            //  Current solution doesn't work with no-space text
            // https://til.simonwillison.net/css/resizing-textarea
            // https://chromestatus.com/feature/5176596826161152
            containerRef.current.dataset.replicatedValue = value;
        }
    };
    useEffect(() => {
        handleInput();
    }, [value]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: clsx(styles.wrap, wrapClassName, hasError && styles.error, disabled && styles.disabled, isAccessoryPanelVisible && styles.withPanel), ref: containerRef || null },
            React.createElement("textarea", Object.assign({}, props, { className: clsx(styles.textArea, className), ref: forwardedRef, value: value || "", maxLength: maxLength, spellCheck: true, disabled: disabled, onSelect: onSelect, onBlur: onBlur, onMouseDown: onMouseDown, autoComplete: "off" })),
            isAccessoryPanelVisible && (React.createElement("div", { className: styles.accessoryPanel },
                React.createElement("span", { className: clsx("body-small", styles.counter) },
                    (value === null || value === void 0 ? void 0 : value.length) || 0,
                    "/",
                    maxLength))),
            assistButton),
        description && React.createElement("p", { className: styles.description }, description)));
}
