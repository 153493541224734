var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import IconChecked from "@assets/icon_checked.svg";
import IconCross from "@assets/icon_cross_small.svg";
import IconTrash from "@assets/icon_trash.svg";
import IconPen from "@assets/pen.svg";
import IconFlag from "@assets/icon-flag.svg";
import IconFlagActive from "@assets/icon-flag-active.svg";
import { IconButton, TextField } from "@components";
import { useAppSelector } from "@hooks";
import { fields } from "./localization";
import styles from "./RevisionListItem.module.scss";
export const MAX_REVISION_NAME_LENGTH = 80;
export function RevisionItem({ isEditMode, revision, isSelected, isEditForbidden, setRenamingRevision, isRemoveDisabled, handleClose, handleRevisionSelect, handleRevisionDelete, handleRename, switchInProgressRevisionName, handleMarkAsDefault, getRevisionNameValidationError, }) {
    const { language } = useAppSelector((state) => state.ui);
    const [newName, setNewName] = useState(revision.name || "");
    const [isRenamingInProgress, setIsRenamingInProgress] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const field = fields(language);
    const handleSave = () => __awaiter(this, void 0, void 0, function* () {
        const error = getRevisionNameValidationError(newName, revision);
        setErrorMessage(error);
        if (error || isRenamingInProgress) {
            return;
        }
        setIsRenamingInProgress(true);
        yield handleRename(revision.name, newName);
        handleClose();
    });
    useEffect(() => {
        return () => {
            if (isEditMode) {
                setIsRenamingInProgress(false);
            }
        };
    }, [isEditMode]);
    const isDefaultSwitchInProgress = switchInProgressRevisionName === revision.name;
    return (React.createElement("div", { className: styles.container },
        React.createElement(IconButton, { onClick: () => {
                handleMarkAsDefault(revision);
            }, iconCustomColor: true, size: "small", variant: "outlined", isLoading: isDefaultSwitchInProgress, icon: !revision.isDefault || isDefaultSwitchInProgress ? React.createElement(IconFlag, null) : React.createElement(IconFlagActive, null), title: field.markAsDefault, disabled: revision.isPreview || !!switchInProgressRevisionName }),
        isEditMode ? (React.createElement(TextField, { placeholder: field.revisionName, onChange: ({ target: { value } }) => {
                setErrorMessage(null);
                setNewName(value);
            }, value: newName, hasError: !!errorMessage, errorMessage: errorMessage, onKeyDown: ({ key }) => key === "Enter" && handleSave(), innerRef: (input) => {
                if (input) {
                    input.focus();
                }
            }, isCompact: true, isLimitVisible: true, maxLength: MAX_REVISION_NAME_LENGTH })) : (React.createElement("button", { className: clsx(styles.revisionSelectOption, isSelected && styles.selected), type: "button", onClick: handleRevisionSelect },
            React.createElement("p", { className: styles.revisionName },
                revision.name,
                revision.isPreview && React.createElement("span", { className: styles.revisionNameIcon }, " *")))),
        React.createElement("div", { className: styles.buttons }, isEditMode ? (React.createElement(React.Fragment, null,
            React.createElement(IconButton, { icon: React.createElement(IconChecked, null), onClick: handleSave, size: "small", color: "secondary", variant: "outlined", isLoading: isRenamingInProgress, title: field.save }),
            React.createElement(IconButton, { icon: React.createElement(IconCross, null), onClick: () => {
                    setNewName(revision.name);
                    handleClose();
                }, size: "small", color: "secondary", variant: "outlined", title: field.cancel }))) : (!isEditForbidden && (React.createElement(React.Fragment, null,
            React.createElement(IconButton, { icon: React.createElement(IconPen, null), onClick: setRenamingRevision, size: "small", color: "secondary", variant: "outlined", title: field.rename }),
            React.createElement(IconButton, { icon: React.createElement(IconTrash, null), onClick: handleRevisionDelete, size: "small", color: "secondary", variant: "outlined", disabled: isRemoveDisabled, title: field.remove })))))));
}
