import React, { useEffect, useState } from "react";
import { Button, DropDownWrap, IconButton, TextField } from "@components";
import { useAppSelector } from "@hooks";
import OpenInNewIcon from "@assets/open-in-new.svg";
import IconLink from "@assets/icon_link.svg";
import { openLinkInNewTab } from "@utils";
import { fields } from "./localization";
import styles from "./LinkMenu.module.scss";
const prefix = "http://";
const prefixRegex = /^http(s)?:\/\//i;
// NOTE: source https://github.com/jquery-validation/jquery-validation/blob/c1db10a34c0847c28a5bd30e3ee1117e137ca834/src/core.js#L1349
const urlRegex = 
// eslint-disable-next-line no-useless-escape
/^(https?:\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
const isUrlValid = (value) => {
    return !value.trim() || urlRegex.test(value);
};
export function LinkMenu({ disabled, withBadge, onConfirm, value, handleMenuToggle }) {
    const { language } = useAppSelector((state) => state.ui);
    const [isLinkMenuVisible, setIsLinkMenuVisible] = useState(false);
    const [inputValue, setInputValue] = useState(value || "");
    const [isValid, setIsValid] = useState(true);
    useEffect(() => {
        setInputValue(value);
        setIsValid(isUrlValid(value));
    }, [value]);
    const { attach, title, cancel, confirm } = fields(language);
    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setIsValid(isUrlValid(newValue));
        setInputValue(newValue);
    };
    const handleBlur = () => {
        if (!prefixRegex.test(inputValue) && inputValue.trim()) {
            setInputValue(`${prefix}${inputValue}`);
        }
    };
    const toggleMenu = (isOpen) => {
        setIsLinkMenuVisible(isOpen);
        handleMenuToggle(isOpen);
    };
    return (React.createElement(DropDownWrap, { isOpen: isLinkMenuVisible, anchor: React.createElement(IconLink, null), disabled: disabled, iconOnly: true, color: "secondary", variant: "contained", withBadge: withBadge, toggleOpen: () => toggleMenu(!isLinkMenuVisible), title: attach },
        React.createElement("div", { className: styles.container },
            React.createElement("header", null,
                React.createElement("p", { className: "no-wrap title-small" }, title)),
            React.createElement("section", { className: styles.content },
                React.createElement(TextField, { value: inputValue, onChange: handleInputChange, onBlur: handleBlur, placeholder: "URL", maxLength: 150, hasError: !isValid }),
                React.createElement(IconButton, { icon: React.createElement(OpenInNewIcon, null), variant: "contained", color: "secondary", disabled: !isValid || !(inputValue === null || inputValue === void 0 ? void 0 : inputValue.trim()), onClick: () => {
                        openLinkInNewTab(inputValue);
                    } })),
            React.createElement("footer", { className: styles.buttons },
                React.createElement(Button, { color: "tertiary", onClick: () => {
                        setInputValue(value);
                        setIsValid(isUrlValid(value));
                        toggleMenu(false);
                    } }, cancel),
                React.createElement(Button, { onClick: () => {
                        toggleMenu(false);
                        onConfirm(inputValue);
                    }, disabled: !isValid }, confirm)))));
}
