var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { Button, Modal, Select, TextField } from "@components";
import { useAppSelector } from "@hooks";
import { dictionary } from "@constants";
import { MAX_REVISION_NAME_LENGTH } from "../RevisionListItem/RevisionListItem";
import { fields } from "./localization";
import styles from "./CreateModal.module.scss";
export function CreateModal({ onClose, selectedRevision, handleRevisionCreate, getRevisionNameValidationError, }) {
    const [sourceRevision, setSourceRevision] = useState(selectedRevision);
    const [name, setName] = useState("");
    const [isCreationInProgress, setIsCreationInProgress] = useState(false);
    const { revisions } = useAppSelector((state) => state.revisions);
    const { language } = useAppSelector((state) => state.ui);
    const { modalTitle, label, selectRevision, create, cancel } = fields(language);
    const { notAllowedSymbols } = dictionary(language);
    const errorMessage = !isCreationInProgress && getRevisionNameValidationError(name);
    const isCreateAllowed = sourceRevision && !errorMessage && !isCreationInProgress;
    const handleRevisionCreateClick = () => __awaiter(this, void 0, void 0, function* () {
        if (isCreateAllowed) {
            try {
                setIsCreationInProgress(true);
                yield handleRevisionCreate(name, sourceRevision);
            }
            finally {
                setIsCreationInProgress(false);
            }
        }
    });
    useEffect(() => {
        const handleKeyDown = ({ key }) => {
            if (key === "Enter") {
                handleRevisionCreateClick();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleRevisionCreateClick]);
    return (React.createElement(Modal, { open: true, onRequestClose: onClose, title: modalTitle, content: React.createElement("div", { className: styles.content },
            React.createElement(TextField, { label: label, required: true, value: name, onChange: ({ target: { value } }) => setName(value), maxLength: MAX_REVISION_NAME_LENGTH, errorMessage: errorMessage, hasError: !!errorMessage, description: notAllowedSymbols, placeholder: label }),
            React.createElement(Select, { label: selectRevision, value: (sourceRevision === null || sourceRevision === void 0 ? void 0 : sourceRevision.name) || "", onOptionClick: (_, option) => {
                    setSourceRevision(revisions.find((revision) => revision.name === option.label));
                }, options: revisions.map(({ name: revisionName }) => ({
                    label: revisionName,
                    id: revisionName,
                })) })), footerContent: React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: onClose, color: "tertiary" }, cancel),
            React.createElement(Button, { onClick: handleRevisionCreateClick, disabled: !sourceRevision || !!errorMessage, isLoading: isCreationInProgress }, create)) }));
}
