import React, { useState } from "react";
import clsx from "clsx";
import { IconButton, Modal } from "@components";
import IconGear from "@assets/icon_gear.svg";
import { AnalyticsId } from "@constants";
import { useAppSelector } from "@hooks";
import { fields } from "./localization";
import { SettingsContent } from "../../../CV/components";
import styles from "./Settings.module.scss";
export function Settings({ forwardedRef, isSettingsShown, setIsSettingsShown }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isRotating, setIsRotating] = useState(false);
    const { language, featureFlags } = useAppSelector((state) => state.ui);
    const field = fields(language);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { onClick: () => {
                setIsRotating(true);
                if (setIsSettingsShown && !(featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.showSettingsAsModal)) {
                    setIsSettingsShown(!isSettingsShown);
                }
                else {
                    setIsOpen(true);
                }
                setTimeout(() => {
                    setIsRotating(false);
                }, 500);
            }, id: AnalyticsId.settingsButton, color: "primary", icon: React.createElement(IconGear, { className: clsx({ [styles.rotate]: isRotating }) }), title: field.title }),
        React.createElement(Modal, { title: field.title, open: isOpen, onRequestClose: () => {
                setIsOpen(false);
            }, content: React.createElement("div", { className: styles.content },
                React.createElement(SettingsContent, null)), className: styles.modal, forwardedRef: forwardedRef })));
}
